import TaskBreadcrumbs from 'components/breadcrumbs/TaskBreadcrumbs';
import StickyBannerLayout from 'components/layout/StickyBannerLayout';
import TaskBasedon from 'components/task/window/TaskBasedon';
import TaskForm from 'components/task/window/TaskForm';
import { PaneProps, WindowManager, WindowManagerConfig } from 'components/wm';
import { PaneContentProps } from 'components/wm/types';
import { FormInfo } from 'contexts/FormInfoContext';
import { useFormInfo } from 'hooks/form';
import useStartProcess from 'hooks/process';
import { FormikFormInfo, useSubmitHandler } from 'hooks/submit';
import { useTranslator } from 'hooks/translator';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import Types from 'types/types';
import { isEmptyValue } from 'utils/utils';

const TaskWindow = () => { 
  const panes = usePaneConfig()

  // create the window manager config
  const config: WindowManagerConfig = {
    maxHorizontalPages: 2,
    maxVerticalPages: 2,
    windows: [
      {
        layout: {
          split: "horizontal",
          panes
        }
      }
    ]
  }

  return (
    <StickyBannerLayout
      banner={<TaskBreadcrumbs />}
      bodySx={{ maxHeight: "100%", overflow: "auto" }}
    >
      <WindowManager {...config} />
    </StickyBannerLayout>
  )
}

const usePaneConfig = () => {
  const params           = useParams();
  const id               = params.id!
  const { t }            = useTranslator()
  const formInfo         = useFormInfo()
  const { handleSubmit } = useSubmitHandler(id, formInfo.isStartForm)
  const description      = formInfo.description
  const title            = formInfo.title
 
  return useMemo(() => {
    // get the basedon pane
    const basedOn    = formInfo.basedOn
    const hasBasedOn = basedOn != undefined && basedOn != null

    const basedOnPanes: PaneContentProps[] = hasBasedOn && !embeddedBasedOn(formInfo, basedOn) 
      ? [{ id: 'basedon', title: t('basedon'), content: <TaskBasedon /> }] 
      : []

    // get the task pane
    const taskPanes: PaneContentProps[] = [{
      id: "task",
      title: title,
      subtitle: description,
      content: <TaskForm onSubmit={(values: any, formInfo: FormikFormInfo) => { handleSubmit(values, formInfo) }} />
    }]

    const contentPanes: PaneContentProps[] = [...basedOnPanes, ...taskPanes]
    const panes: PaneProps[] = contentPanes.map(props => ({ ...props, type: "content" }))

    return panes
  }, [formInfo])

}
  
export function embeddedBasedOn(formInfo: FormInfo, basedOn: any): boolean { 
  const fields = formInfo.form.fields
  if (fields.length == 1 && Types.isSubmitField(formInfo, fields[0]))
    return true

  return Types.isSimpleType(basedOn) 
    || Types.isEntity(basedOn)
    || isEmptyValue(basedOn) 
    || isSimpleTuple(basedOn)
}

function isSimpleTuple(basedOn: any): boolean { 
  return Types.isTuple(basedOn) && Object.values(basedOn).every(Types.isSimpleType) 
}


export default TaskWindow
