import { useAuthNavigation, useCurrentUser } from 'authentication/main';
import ProcesseAppsItem from 'components/process/ProcessApps';
import UserSettingsProvider from 'contexts/UserSettings';
import { useConfig } from 'hooks/config';
import { useUserSettings } from 'hooks/userSettings';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types/graphql';
import { Anchor, SetState } from 'types/react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Avatar from './Avatar';

const DashboardMenu = () => {
  const {addProcessIcons} = useConfig()

  return (
    <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', gap: "8px"}}>
      { addProcessIcons ? <ProcesseAppsItem/> : null }
      <AccountItem/>
    </Box>
  );
}

const Greeting = ({user}: {user: User}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const greetingStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
    fontSize: "16px"
  }

  if (user) {
    return (
      <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center'}}>
        <Typography sx={{paddingBottom: 0, ...greetingStyle, fontSize: "14px", fontWeight: 'bold'}}>{t('auth.hello')},</Typography>
        <Typography style={greetingStyle}>{user?.name}</Typography>
      </Box> 
    )
  } else {
    return (
      <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center'}}>
        <Typography style={greetingStyle}>{t('auth.notloggedin')}</Typography>
      </Box>
    )
  }
}
const AccountItem = () => {
  const { user } = useCurrentUser()
  const [anchorEl, setAnchorEl] = React.useState<Anchor>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <AccountButton user={user} handleClick={handleClick} />
      <AccountMenu user={user} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </React.Fragment>
  )
}

const AccountButton = ({user, handleClick}: {user: User, handleClick: (e: any) => void}) => {
  const theme     = useTheme()
  // @ts-ignore
  const color     = theme.components.banner.color

  return (
    <Tooltip disableInteractive title="Account">
      <IconButton id='account-button' onClick={handleClick} size="small" sx={{ color }} >
        <Avatar user={user} />
      </IconButton>
    </Tooltip>
  )
}

export const AccountMenu = ({user, anchorEl, setAnchorEl}: {user: User, anchorEl: Anchor, setAnchorEl: SetState<Anchor>}) => {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UserSettingsProvider>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{display: "flex", minWidth: "180px", justifyContent: "center", padding: "5px"}}>
          <Greeting user={user} />
        </Box>
        <Divider sx={{marginTop: "10px", marginBottom: "10px"}}/>

        <AccountMenuItems />
      </Menu>
    </UserSettingsProvider>
  )
}

export const AccountMenuItems = () => {
  return (
    <React.Fragment>
      <SettingsItem />
      <LogInOutItem/>
    </React.Fragment>
  )
}

const SettingsItem = () => {
  const {setOpen } = useUserSettings()
  const {t }       = useTranslation()

  return (
    <MenuItem 
      onClick={(e) => {
        setOpen(true)
      }}
    >
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      {t('settings')}
    </MenuItem>
  )

}

const LogInOutItem = () => {
  const { t }    = useTranslation()
  const authNav  = useAuthNavigation()
  const { user } = useCurrentUser()

  if (user) {
    return (
      <MenuItem onClick={authNav.onClickLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('auth.logout')}
      </MenuItem>
    )
  } else {
    return (
      <MenuItem onClick={authNav.onClickLogin}>
        <ListItemIcon>
          <Login fontSize="small" />
        </ListItemIcon>
        {t('auth.login')}
      </MenuItem>
    )
  }
}

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 18,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

export default DashboardMenu