import { useSearchVariable } from 'hooks/search';
import { useEffect, useState } from 'react';
import { InputChangeEvent, InputChanger, SetState } from 'types/react';
import { focusOnInputByClassName } from 'utils/field-utils';
import { getEventValue } from 'utils/validate-utils';

import { Search as SearchIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, InputAdornment, SvgIcon, TextField, TextFieldProps } from '@mui/material';

import Loader from './Loader';

export type SearchFieldProps = {
  id?: string
  value: string
  setValue: SetState<string>
  setCurrentValue: SetState<string>
  autoFocus: boolean
  placeholder: string
  className?: string
  props?: TextFieldProps
  loading?: boolean
  onChange?: InputChanger 
}

// This is a search field with clear icon.
// use together with 'useDebouncedState', e.g.:
// const [value, setValue, setCurrentValue] = useDebouncedState('', 500)

const SearchField = ({
  id,
  props,
  value: filter, 
  setValue: setFilter, 
  setCurrentValue: setCurrentFilter, 
  placeholder, 
  className = 'search',
  loading,
  onChange = () => {},
  autoFocus = false 
}: SearchFieldProps) => {
  
  const [value, setValue] = useState(filter)
  const searchKey         = `${id ? `${id}.` : ""}${className}`
  const search            = useSearchVariable(searchKey)

  useEffect(() => { setValue(filter) }, [filter])
  useEffect(() => {
    const elements = document.querySelectorAll(`.${className}`)
    
    // TODO: implement search url variable support when more than one searchable input is rendered
    if (search && elements.length == 1) {
      setValue(search)
      setCurrentFilter(search)
    }
  },[search])

  useEffect(() => {
    if (autoFocus)
      focusOnInputByClassName(className)
  }, [])

  const handleChange = (e: InputChangeEvent) => {
    const input = getEventValue(e)
    onChange(e)
    setValue(input)
    setFilter(input)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Enter": // enter completion
        e.preventDefault()
        e.stopPropagation()
        setCurrentFilter(value)
        break;

      default:
    }
  };

  const handleClear = () => {
    setValue("")
    setCurrentFilter("")
  }

  return (
    <TextField
      fullWidth
      InputProps={{
        onKeyDown: handleKeyDown,
        startAdornment: <SearchAdornment/>,
        endAdornment: <ClearAdornment loading={loading} disabled={!value} onClick={handleClear} />
      }}
      variant="standard"
      {...props}

      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  )
}

const SearchAdornment = (): JSX.Element => (
  <InputAdornment position="start">
    <SvgIcon
      fontSize="small"
      color="action"
    >
      <SearchIcon />
    </SvgIcon>
  </InputAdornment>
)

type ClearAdornmentProps = {
  disabled: boolean
  onClick: (e: React.SyntheticEvent) => void
  loading?: boolean
}

const ClearAdornment = ({loading, disabled, onClick}: ClearAdornmentProps): JSX.Element => (
  <InputAdornment position="end">
    <IconButton size='small' disabled={disabled || loading} onClick={onClick} >
      <SvgIcon
        fontSize="small"
        color="action"
      >
        <ClearIcon style={{ visibility: disabled || loading ? 'hidden' : 'visible' }} />
      </SvgIcon>
      { loading 
        ? <Box sx={{marginTop: "-5px", position: "absolute"}}><Loader size='micro'/></Box>
        : null 
      }
    </IconButton>
  </InputAdornment>
)

export default SearchField