import {
    PanePageButton, usePagePanelContext, usePaneContext, useWindowPageContext
} from 'components/wm';
import { PagePanelButtonType } from 'components/wm/contexts/PagePanelContext';
import RenderContextProvider, { MultipleRenderType } from 'contexts/RenderContext';
import ValuesProvider from 'contexts/ValuesContext';
import { Form as FormikForm, Formik } from 'formik';
import { useTranslator } from 'hooks/translator';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { FieldInfo } from 'types/field';
import { focusOnField } from 'utils/field-utils';
import { getFocusPathFromFieldValues } from 'utils/input-utils';

import { Done as DoneIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import InputField from './InputField';

type MultiplePaneProps = {
  info: FieldInfo
}

const MultipleMultablePane = ({info}: MultiplePaneProps) => {
  const {handleClose} = useHandleClose()
  const formikRef     = useRef()
  const { ref }       = useWindowPageContext()
  const getMainFormik = () => ref.current.data.formikRef.current 
  
  const handleSubmit = () => { 
    const otherFormik = getMainFormik()
    const formik: any = formikRef.current
    const values      = _.get(formik?.values, info.rpath)

    console.log("Set multiple values: %o", values)
    otherFormik.setFieldValue(info.rpath, values)

    handleClose()
  }

  // auto focus on load
  useEffect(() => {
    const autoFocus = async () => {
      const formik:any = formikRef.current
      const values     = _.get(formik?.values, info.rpath)
      const focusPath  = getFocusPathFromFieldValues(info.field, values)

      if (focusPath) {
        const path = `${info.rpath}${focusPath}`
        focusOnField(path)
      }
    }
    autoFocus()
  }, [])

  // add the close button to the window pane
  const {setButtons} = usePagePanelContext()
  useEffect(() => { 
    setButtons(buttons => {
      if (!buttons.find(button => button.id == "close")){
        const button: PagePanelButtonType = {
          id: "close",
          component: <ClosePaneButton />
        } 

        return [...buttons, button]
      } else return buttons
    })
  }, [])

  const getValues = () => {
    const formik = getMainFormik()
    const values = {}
    return _.set(values, info.rpath, _.cloneDeep(_.get(formik?.values, info.rpath)))
  }

  const mutableType: MultipleRenderType = "mutable-flex"
  return (
    <>
      <ValuesProvider>
        <Formik
          // @ts-ignore
          innerRef={formikRef}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={getValues()}
          onSubmit={handleSubmit}
        >
          <FormikForm noValidate style={{
            display: "flex", flexDirection: "column", flexGrow: 1, maxWidth: "100%", overflow: "auto", maxHeight: "100%"
            , minHeight: 0, height: "100%"
          }}>
            <RenderContextProvider multipleType={mutableType}>
              <Box className='mutable-body' sx={{ display: "flex", flexGrow: 1, flexDirection: "column", width: "100%", padding: "20px", overflow: "auto" }}>
                <Box className='multable-body-fields' sx={{ padding: "2px", display: "flex", flexGrow: 1, flexDirection: "column", width: "100%", overflow: "auto" }}>
                  <InputField
                    field={info.field}
                    indices={info.indices}
                  />
                </Box>
                <MultipleSubmit />
              </Box>
            </RenderContextProvider>

          </FormikForm>
        </Formik>
      </ValuesProvider>
    </>
  )
}

const useHandleClose = () => {
  const {index}           = usePaneContext()
  const {setPages, pages} = useWindowPageContext()

  const handleClose = (e?: React.SyntheticEvent) => {
    e?.preventDefault()
    e?.stopPropagation()
    
    if (index != pages.length-1 || pages.length <= 1)
      return null
 
    setPages(pages => pages.slice(0, pages.length-1)) 
  }

  return {
    disable: index != pages.length-1 || pages.length <= 1, 
    handleClose
  }
}

const ClosePaneButton = () => {
  const {disable, handleClose} = useHandleClose()
  const { t }                  = useTranslator()

  if (disable)
    return null

  return <PanePageButton title={t("windowmanager.close")} onClick={handleClose} icon='Close' />
}


const MultipleSubmit = () => {
  const {t}           = useTranslator()

  return (
    <Box sx={{ flexShrink: 0, pt: 2, display: 'flex', flexFlow: 'row' }}>
      <Box style={{ flexGrow: 1 }}>
        <LoadingButton
          id='submit-form-button'
          endIcon={<DoneIcon />}
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          type='submit'
        >
          {t('done')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default MultipleMultablePane