import { useNotifier } from 'hooks/notification'
import ListSection     from 'components/view/ListSection'
import DetailSection    from 'components/view/DetailSection'
import RenderContextProvider from 'contexts/RenderContext'

const Section = ({schema, section}) => {
  const notifier = useNotifier()
  switch (section.type) {
    case 'group': 
      return (
        <RenderContextProvider parent="group">
          <DetailSection schema={schema} section={section} />
        </RenderContextProvider>
      )
    
    case 'list' : 
      return (
        <RenderContextProvider parent="list" props={{listKey: section.key, detailKey:section.detail}} >
          <ListSection schema={schema} section={section} />
        </RenderContextProvider>
      )

    default: 
      notifier.error("An unknown detail type is provided: %o", section.type) 
      return null
  }
}

export default Section
