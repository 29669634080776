import { RenderTableRowCells } from 'components/render';
import { useFieldInfo }        from 'hooks/field';
import PropTypes               from "prop-types"

const MultipleTableBasedOnCells = ({rowIndex, row}) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  const closedBasedOn = row?.values?.__basedOn 
  if (mode === "closed" && closedBasedOn != undefined)
    return <RenderTableRowCells rowIndex={rowIndex} basedOn={closedBasedOn} />
  else 
    return null
}

MultipleTableBasedOnCells.propTypes = {
  row        : PropTypes.shape({ 
    id: PropTypes.number.isRequired, 
    values: PropTypes.object.isRequired
  }),
}

export default MultipleTableBasedOnCells