import { useFormInfo } from 'hooks/form';
import { useNotifier } from 'hooks/notification';
import { useTranslator } from 'hooks/translator';
import React from 'react';
import Types from 'types/types';
import { renderHtml } from 'utils/html';

import { RenderFilename, RenderFiles, RenderObjectTable, RenderValue } from './';
import { RenderCurrency } from './RenderCurrency';

export const RenderGearsValue = ({ value }: {value: any}): JSX.Element => {
  const notifier       = useNotifier();
  const formInfo       = useFormInfo();
  const processKey     = formInfo?.processDefinition?.key
  const { translator } = useTranslator();

  switch (true) {
    case Types.isFileRef(value):
      return <RenderFiles attachments={[value]} />;

    case Types.isFileRefs(value):
      return <RenderFiles attachments={value} />;

    case Types.isCurrency(value):
      return <RenderCurrency value={value} />

    case Types.isFileName(value):
      return <RenderFilename value={value} />;

    case Types.isTuple(value):
      return <RenderObjectTable object={value} />;

    case Types.isEntity(value):
      //const onClick = () => { notifier.error("Entity links are not implemented yet.")}
      // const href = "entity/" + basedOn.__typeName + "/" + basedOn.__id
      //return <a onClick={onClick}>{tn(value.__text)}</a>
      const text = value.__text || 'unknown'
      return <RenderValue>{translator.toValue(text, text, processKey)}</RenderValue>;

    case Types.isHTML(value):
      return <React.Fragment>{renderHtml(value.__value)}</React.Fragment>

    default:
      notifier.error("No render method implemented for type: " + value.__kind);
      console.error("No render method for value: %o", value);
      return Types.null<JSX.Element>()
  }
};
