import { Helmet }         from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Explorer           from 'components/explorer/Explorer';
import { useConfig }      from 'hooks/config';
import DocumentTable from 'components/tables/DocumentsTable';

const Documents = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.documents')} | {project}</title>
      </Helmet>

      <DocumentTable/>
    </>
  );
}

export default Documents
