import PropTypes       from 'prop-types';
import { columnsType } from 'utils/type-utils';
import TableRow        from 'components/table/TableRowTooltip';
import { TableBody, }  from '@mui/material';
import LoadingTableRow from 'components/table/LoadingTableRow';

const TableRows = ({rows, columns, loading}) => (
  <TableBody>
    {rows.map((row, index) => <TableRow key={index} row={row} columns={columns} index={index}/>)} 
    { loading ? <LoadingTableRow columns={columns} /> : null }
  </TableBody>
)

const rowType = PropTypes.shape({
  onClick: PropTypes.func,
  cells  : PropTypes.object.isRequired
}).isRequired

const rowsType = PropTypes.arrayOf(rowType).isRequired

TableRows.propTypes = {
	rows    : rowsType,
  columns: columnsType
};

export default TableRows;