import { Divider, Box } from '@mui/material';
import { RenderValue } from 'components/render';
import { partitionType } from 'utils/type-utils';
import { useFormInfo } from 'hooks/form';
import Elevate from 'components/Elevate';
import { useLocalState } from 'hooks/state';
import StepCardContent from 'components/task/standard/StepCardContent';
import StepCardHeader from 'components/task/standard/StepCardHeader';

const StepHeader = ({partition}) => {
  if (!partition.header)
    return null

  return (
    <>
      <StepCardHeader />
      { partition.fields.length ? <Divider sx={{marginTop: "10px", marginBottom: "10px"}}/> : null }
    </>
  )
}

const StepFields = ({partition, ...props}) => {
  const showFields = partition.fields.length || partition.submit
  if (!showFields)
    return null

  return <StepCardContent
    partition={partition}
    {...props}
  />
}

const StepBasedOn = ({partition}) => {
  const formInfo = useFormInfo()
  const basedOn  = formInfo.form.values?.__basedOn
  if (!partition.basedOn || basedOn === null || basedOn === undefined)
    return null

  return (
    <>
      <Box sx={{margin: "20px"}}>
        <RenderValue>
          {basedOn} 
        </RenderValue>
      </Box>
    </>
  )
}

const StepCard = ({partition, ...props}) =>{
  const unlimited         = partition.type == "free"
  const [width, setWidth] = useLocalState("TASK.WIDTH", "1000px") 

  return (
    <Box className='form-section' 
      sx={{
        display: "flex", 
        width:    unlimited ? "fit-content" : undefined, 
        maxWidth: unlimited ? "100%" : width, 
        transition: "max-width 0.3s ease",
        marginLeft: "auto", 
        marginRight: "auto", 
        overflow: "auto", 
        padding: "20px", 
        paddingTop: 0 
      }}
    >
      <Elevate 
        sx={{
          flexGrow: 1,
          display: "flex", 
          flexDirection: "column", 
          marginLeft: "auto", 
          marginRight: "auto", 
          width: "min-content",
          overflow: "auto", 
          padding: "30px",
          border: 1,
          borderColor: theme => theme.palette.grey[200]
        }}
      >
        { unlimited 
          ? <Box className='min-width-container' sx={{display: "flex"}}> 
              <Box sx={{flexGrow: 1, width: `calc(${width} - 102px)`}}/>
            </Box> 
          : null
        }
        <StepHeader  partition={partition} />
        <StepBasedOn partition={partition} />
        <StepFields  partition={partition} {...props} />
      </Elevate>
    </Box>

  )
 //   <Elevate 
 //     sx={{
 //       display: "flex", 
 //       flexDirection: "column", 
 //       height: "fit-content", 
 //       maxWidth: "1000px", 
 //       marginLeft: "auto", 
 //       marginRight: "auto", 
 //       overflow: "auto", 
 //       width: "max-content", 
 //       padding: "30px"
 //     }}
 //   >
 //     <Box sx={{display: "flex"}}><Box sx={{flexGrow:1}}/></Box>
 //     <StepHeader  partition={partition} />
 //     <StepBasedOn partition={partition} />
 //     <StepFields  partition={partition} {...props} />
 //   </Elevate>
 // )
} 

StepCard.propTypes = {
  partition: partitionType
}

export default StepCard
