import {
  Navigate,
  useLocation }      from 'react-router-dom';
import {
  DashboardLayout,
  MainLayout }       from 'components/layout';
import {
  List,
  Tasks,
  AssignedTasks,
  Login,
  NotFound,
  Start,
  GroupTasks,
  MyActiveProcesses,
  AllActiveProcesses,
  Task,
  Documents,
  Deployments,
  Status }            from 'pages'
import { Helmet }     from 'react-helmet';
import { useConfig }  from 'hooks/config';
import Detail         from 'pages/Detail';
import Process        from 'pages/Process';
import Messages       from 'pages/Messages';
import Message        from 'pages/Message';
import SvelteSchedule from 'components/gantt/Schedule';


const routes = (user) => {
  const isLoggedIn = !user.loading && user.user

  if (isLoggedIn) {
    return [
      {
        path: 'gears',
        element: <DashboardLayout />,
        children: [
          { path: 'status/:processInstanceId/:taskId', element: <Status type='task' /> },
          { path: 'processes/start',                   element: <Start /> },
          { path: 'process/:key',                      element: <Process /> },
          { path: 'instances/mine',                    element: <MyActiveProcesses /> },
          { path: 'instances/all',                     element: <AllActiveProcesses /> },
          { path: 'tasks/assigned',                    element: <AssignedTasks /> },
          { path: 'tasks/all',                         element: <Tasks kind="all"/> },
          { path: 'tasks/transferable',                element: <Tasks kind="transferable"/> },
          { path: 'tasks/group',                       element: <GroupTasks /> },
          { path: 'tasks/:processKey/:id',             element: <Task isStartForm={false} /> },
          { path: 'schedule',                          element: <SvelteSchedule/> },
          { path: 'schedule/:resourceKey/:taskKey',    element: <SvelteSchedule/> },
          { path: 'messages',                          element: <Messages /> },
          { path: 'messages/:id',                      element: <Message /> },
          { path: 'lists/:id',                         element: <List /> },
          { path: 'details/:detail/:id',               element: <Detail /> },
          { path: 'start/form/:processKey/:id',        element: <Task isStartForm={true}  /> },
          { path: 'documents',                         element: <Documents /> },
          { path: 'deployments',                       element: <Deployments /> },
          { path: '',                                  element: <Home /> },
          { path: '*',                                 element: <NotFound /> }
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          { path: 'login',  element: <Home reason="Already logged in." /> },
          { path: 'blank',  element: <Blank /> },
          { path: '',       element: <Home /> },
          { path: '*',      element: <NotFound /> }
        ]
      },
    ];
  } else {
    return [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          { path: 'login',  element: <Login /> },
          { path: 'blank',  element: <Blank /> },
          { path: 'logout', element: <Status type='logout' /> },
          { path: '',       element: <Authenticate /> },
          { path: '*',      element: <Authenticate /> }
        ]
      },
    ];
  }
}

const Blank = () => {
  const { project } = useConfig()

  return (
    <Helmet>
      <title>/dev/null | {project}</title>
    </Helmet>
  )
}

const Authenticate = () => {
  const location = useLocation()
  console.log("Redirecting path %o to the login page.", location.pathname)
  return <Navigate to="/login" />
}

const Home = ({reason}) => {
  const location = useLocation()
  console.log("Redirecting path %o to the start page. %s", location.pathname, reason ? reason : '')
  return <Navigate to="/gears/processes/start" />
}

export default routes;

