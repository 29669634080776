import useStartProcess, { TranslatedProcessDefinition } from 'hooks/process';
import { useProcessRenderContext } from 'hooks/render';

import { TileCard, TileStyleProps } from './TileCard';

export type TileType =  "directory" | "process"
export type TileInputProps = {
  type :    TileType
  label?:   string
  path :    string
  process?: TranslatedProcessDefinition
  tileProps?: Partial<TileStyleProps>
  onClick?: (e?: React.SyntheticEvent) => void
} 

export interface TileProps extends TileInputProps {}

const Tile = ({onClick, tileProps, path, type, label, process}: TileProps) => {
  switch (type) {
    case 'directory':
      return <DirectoryTile path={path} label={label!} tileProps={tileProps}/>
    
    case 'process':
      return <ProcessTile onClick={onClick} label={label} process={process!} tileProps={tileProps}/>
  }
}

type ProcessTileProps = {
  process: TranslatedProcessDefinition
  label?: string
  tileProps?: Partial<TileStyleProps>
  onClick?: (e?: React.SyntheticEvent) => void
}

const ProcessTile = ({onClick, process, label, tileProps}: ProcessTileProps) => {
  const startProcess = useStartProcess()

  const handleStartProcess = (e: any) => {
    startProcess(e, process, "PROCESS_OPEN")
    onClick?.(e)
  }

  return <TileCard 
    tileProps={tileProps} 
    description={process.description} 
    path={process.key} 
    label={label || process.title} 
    onClick={handleStartProcess}
  />
}

type DirectoryTileProps = {
  path: string
  label: string
  tileProps?: Partial<TileStyleProps>
}

export const DirectoryTile = ({path, label, tileProps}: DirectoryTileProps) => {
  const {setPath} = useProcessRenderContext()

  const handleClick = (e?: React.SyntheticEvent) => {
    setPath(path)
  }

  return <TileCard 
    tileProps={tileProps} 
    icon="Folder" 
    path={path} 
    label={label} 
    onClick={handleClick} 
  />
}

export default Tile