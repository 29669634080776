import { detailRowToCrumb, listSectionToCrumb } from "utils/breadcrumb"
import Settings from "utils/settings-utils"
import { createDetailCrumbKey, createDetailUrl, getCurrentDetailCrumbs } from "utils/view"

export const toListRow = ({translator, notifier, row, columns, detail, section, currentDetail, navigate}) => {
  const cells   = columns.reduce((obj, column, index) => ({...obj, [column.field]: row[index+1]}), {})
  const id      = row[0] 
  const onClick = detail && (() => { 
    const detailUrl     = createDetailUrl(detail, id)
    const variable      = createDetailCrumbKey(detail, id)  
    const currentCrumbs = getCurrentDetailCrumbs(currentDetail?.detail, currentDetail?.id)
    const newCrumbs     = !currentDetail ? [listSectionToCrumb(translator, notifier, section), detailRowToCrumb(cells, detail, id)] : [detailRowToCrumb(cells, detail, id)]

    Settings.session.write(variable, [...currentCrumbs, ...newCrumbs]) 
    
    navigate(detailUrl) 
  })

  return { cells, id, onClick }
}

