import { TableCell }                      from '@mui/material';
import { CopyRowButton, DeleteRowButton } from 'components/fields/multiple';
import AddRowButton                       from 'components/fields/multiple/AddRowButton';
import { useFieldInfo }                   from 'hooks/field';
import PropTypes                          from "prop-types"

const MultipleTableRowActions = (props) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  if (mode === "open")
    return (
      <TableCell align={"right"} key={"actions"}  style={{width: "1%", whiteSpace: 'nowrap', padding: 0, paddingRight: "8px"}} >
        <AddRowButton    {...props} />
        <CopyRowButton   {...props} />
        <DeleteRowButton {...props} />
      </TableCell>
    )
  else return null
}

MultipleTableRowActions.propTypes = {
  rows       : PropTypes.array.isRequired,
  id         : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default MultipleTableRowActions
