import SearchField from 'components/SearchField';
import { useTranslator } from 'hooks/translator';
import { SetState } from 'types/react';

import { Box } from '@mui/material';

type MenuSearchProps = {
  filter: string
  setFilter: SetState<string>
  setCurrentFilter: SetState<string>
  disableFilter: boolean
  loading: boolean
  onChange: (e: React.SyntheticEvent) => void
}

const MenuBarSearch = ({filter, setFilter, setCurrentFilter, disableFilter, loading, onChange}: MenuSearchProps) => {
  const { t } = useTranslator()

  if (!disableFilter) {
    return (
      <Box style={{paddingLeft: "10px", paddingRight: "10px", width: "300px", alignSelf: "start"}} >
        <SearchField
          autoFocus={true}
          value={filter}
          setValue={setFilter}
          setCurrentValue={setCurrentFilter}
          placeholder={t('filter.search') || ""}
          loading={loading}
          onChange={onChange}
        />
      </Box>
    )
  } else return null
}

export default MenuBarSearch