import { DecimalSeparatorSetting } from 'contexts/ConfigContext';
import { useConfig } from 'hooks/config';
import { useTranslator } from 'hooks/translator';
import { useEffect, useState } from 'react';
import { Option } from 'types/option';
import { selectOption } from 'utils/option-utils';

import { Autocomplete, TextField } from '@mui/material';

const DecimalSeperatorSelector = () => {
  const {t} = useTranslator()
  const renderOptions: Option[] = [
    {value: "comma", label: t('decimals.comma')!}, 
    {value: "dot", label: t('decimals.dot')!},
    {value: "locale", label: t('decimals.locale')!}
  ]
  const {decimalSetting, setDecimalSetting} = useConfig()
  const [value, setValue] = useState<DecimalSeparatorSetting>(decimalSetting)

  useEffect(() => {
    setDecimalSetting(value)
  }, [value])

  return (
    <Autocomplete
      options={renderOptions}
      style={{ width: 300 }}
      value={selectOption(renderOptions, value)}
      autoHighlight
      disableClearable
      onChange={(event, newOption) => {
        event.preventDefault()
        event.stopPropagation()
        setValue(newOption.value)
      }}
      renderInput={(params) => (
        <TextField autoComplete='new-password' {...params} label={t('decimals.label')} fullWidth />
      )}
    />
  )
}

export default DecimalSeperatorSelector