import { validateField, workaroundFieldLabelOverlap } from "utils/validate-utils";
import { TextField } from "@mui/material";
import InputError from "components/fields/InputError";
import { useFieldInfo } from "hooks/field";
import { useFormInfo } from "hooks/form";
import { useAutoSubmitSignal } from "hooks/autosubmit";
import { TraitHelper } from "helpers/traits";
import { useConfig } from "hooks/config";
import { fieldMinWidthStyle } from "utils/field-utils";

const InputTextFieldContent = (props) => {
  const { setFocus, multiline }        = props
  const { augProps, fieldProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const {signal}                       = useAutoSubmitSignal()
  const {taskRendering}                = useConfig()


  function handleValidate(e) {
    const error = validateField("text", fieldProps.required, e)
    augProps.setError(error)
  }

  function handleChange (e) {
    fieldProps.onChange(e)
    handleValidate(e)
	}

  function handleBlur (e) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const workaround = workaroundFieldLabelOverlap(info.rpath)
  return (
    <TextField
      {...fieldProps}
      {...workaround}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      minRows={!info.inMultiple && multiline ? 4 : undefined}

      multiline={Boolean(multiline)}
      value={fieldProps.value === null ? "" : fieldProps.value}
      style={taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined}

	    fullWidth
      type={TraitHelper.hasTrait(info.field, 'masked') ? 'password' : undefined}
    />
  )
}

const InputTextField = (props) => (
  <InputError>
    <InputTextFieldContent {...props} />
  </InputError>
)


export default InputTextField
