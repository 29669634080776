import { getEventValue, validateField, workaroundFieldLabelOverlap } from "utils/validate-utils";
import { TextField, Box, FormControl, FormLabel, FormHelperText, IconButton, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import InputError from "components/fields/InputError";
import { useFieldInfo } from "hooks/field";
import { useTranslator } from "hooks/translator";
import { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  YEARS,
  MONTHS,
  WEEKS,
  DAYS,
  HOURS,
  MINUTES,
  SECONDS,
  MILLIS,
  periodToIso,
  isoToPeriod
} from "utils/period";

const DURATION = "duration"
const PERIOD   = "period"

const UNITS = {
  [DURATION]: [HOURS, MINUTES, SECONDS, MILLIS],
  [PERIOD]  : [YEARS, MONTHS, WEEKS, DAYS, HOURS, MINUTES, SECONDS, MILLIS],
}

const InputPeriodDurationField = (props) => (
  <InputError>
    <InputPeriodDurationFieldContent {...props} />
  </InputError>
)

const InputPeriodDurationFieldContent = (props) => {
  const { type }             = props
  const { augProps, fieldProps, info } = useFieldInfo()
  const [period, setPeriod]            = useState(() => isoToPeriod(fieldProps.value) || {})
  const [units, setUnits]              = useState(() => defaultUnits(type, period))

  useEffect(() => {
    const isoFormat = periodToIso(period)

    if (augProps.touched)
      handleValidate(null, isoFormat)

    augProps.setValue(isoFormat)
  }, [period])

  function handleValidate(e, value) {
    const error = validateField("period", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange (e, unit) {
    const eventValue = getEventValue(e, null)
    const num        = parseInt(eventValue, 10)
    const value      = Number.isNaN(num) ? null : num

    setPeriod(period => ({...period, [unit]: value}))
  }

  return (
    <FormControl id={fieldProps.id} sx={{width: "100%"}} component="fieldset" required={fieldProps.required} error={fieldProps.error}>
    {!info.inMultiple ? <FormLabel sx={{marginBottom: "5px"}} component="legend" >{fieldProps.label}</FormLabel> : null}

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <DurationFields type={type} onChange={handleChange} units={units} period={period} />
        <Box sx={{flexShrink: 0}}>
          <UnitSelector type={type} units={units} setUnits={setUnits} setPeriod={setPeriod}/>
        </Box>
      </Box>

      { !info.inMultiple ? <FormHelperText >{fieldProps.error ? fieldProps.helperText : " "}</FormHelperText> : null }
    </FormControl>
  )
}

const DurationFields = ({type, units, onChange, period}) => {
  const { info } = useFieldInfo()
  return (
    <Box sx={{display: "flex", flexDirection: "row", flexGrow: 1}}>
      { UNITS[type].map((unit, index) => units[unit]
          ? <InputField rpath={`${info.rpath}_${unit}`} key={index} unit={unit} units={units} onChange={onChange} period={period}/>
          : null
        )
      }
    </Box>
  )
}

const InputField = ({rpath, unit, onChange, period}) => {
  const { fieldProps } = useFieldInfo()
  const workaround     = workaroundFieldLabelOverlap(rpath)
  const {t}            = useTranslator()

  return (
    <TextField
      sx={{flexGrow: 1, minWidth: "70px"}}

      {...fieldProps}
      {...workaround}

      value={period[unit] == null ? "" : period[unit]}

      onWheel={e => e.currentTarget.blur()}

      id={rpath}
      name={rpath}
      label={t(unit)}
      onChange={(e) => onChange(e, unit)}
      helperText={undefined}
      placeholder={undefined}
      required={false}
      margin="none"
      type="number"
    />
  )
}

const UnitSelector = ({type, units, setUnits, setPeriod}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslator()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = (unit) => {
    setUnits(units => {
      const active = units[unit]
      if (active)
        setPeriod(period => ({...period, [unit]: null}))

      return {...units, [unit]: !units[unit] }
    })
  }

  const UnitCheckboxes = () => {
    return UNITS[type].map((unit, index) => (
      <FormControlLabel
        onClick={() => handleUpdate(unit)}
        key={index}
        label={t(unit)}
        control={<Checkbox checked={units[unit]}/>}
      />
    ))
  }

  return (
    <>
      <IconButton id="test" size={"medium"} tabIndex={-1} onClick={handleClick}  >
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FormGroup sx={{paddingLeft: "10px", paddingRight: "10px"}}>
          <UnitCheckboxes/>
        </FormGroup>
      </Menu>
    </>
  );
}

function defaultUnits (type, period) {
  const active = Object.fromEntries(UNITS[type].map(unit => [unit, false]))
  const activate = (unit) => active[unit] = true
  switch (type) {
    case DURATION:
      activate(SECONDS)
      activate(MINUTES)
      activate(HOURS)
      break;
    case PERIOD:
      activate(SECONDS)
      activate(MINUTES)
      activate(HOURS)
      break;
    default:
  }

  // also activate fields that are in the initial input
  Object.entries(period).forEach(([key, value]) => {
    if (value != undefined && value != null)
      activate(key)
  })

  return active
}

export default InputPeriodDurationField
