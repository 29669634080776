import {
  getProcessName,
  getTaskCreatedAt,
  getTaskName,
  getSubject
} from 'utils/form-utils';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import {
  CLAIM_TASK,
  GET_GROUP_TASKS
} from 'queries/task';
import { useNotifier } from 'hooks/notification';
import { useTranslation } from 'react-i18next';
import { useResponsiveColumns } from 'hooks/columns';
import { bannerQueries }        from 'utils/banner-utils';
import LargeElevatedTable           from 'components/tables/LargeElevatedTable';
import { useTranslator }        from 'hooks/translator';
import { getTaskTitle } from 'contexts/FormInfoContext';
import { claimTask } from 'hooks/process';

const GroupTasksTable = () => {
  const [doClaimTask]  = useMutation(CLAIM_TASK, {refetchQueries: bannerQueries})
  const navigate       = useNavigate()
  const notifier       = useNotifier()
  const columns        = useColumns()
  const { translator } = useTranslator()
  const options        = {translator, doClaimTask, navigate, notifier, register: true}

  const request = {
    variables: {},
    query:     GET_GROUP_TASKS,
    path:      'data.groupTasks',
    countPath: 'data.groupTasksCount',
  }

  return <LargeElevatedTable
    filterType='local'
    request={request}
    columns={columns}
    toTableRow={(row) => toTableRowData(options, row)}
  />
};

/* convert a tasks to row data structure */
function toTableRowData(options, task) {
  const {translator} = options
  const t = translator.translate.bind(translator)

  return {
    id: task.id,
    onClick: (e) => claimTask(options, e, task),
    cells: {
      "processName": task.processDefinition ? getProcessName(translator, task) : t('unknown'),
      "taskName": getTaskName(translator, task),
      //"taskDescription": getTaskDescription(translator, task),
      'taskTitle':       getTaskTitle(translator, {isStartForm: false, task}), 
      "taskSubject": getSubject(task),
      "allowedRoles": task.allowedRoles,
      'createdAt': getTaskCreatedAt(task)
    }
  }
}

const useColumns = () => {
  const { t } = useTranslation()

  const processName = {
    headerName: t('process_name'),
    field: "processName",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const subject = {
    headerName: t('subject'),
    field: "taskSubject",
    cellProps: {
      width: 200,
      align: "left"
    }
  }  

  const title = {
    headerName: t('title'),
    field: "taskTitle",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const description = {
    headerName: t('description'),
    field: "taskDescription",
    cellProps: {
      width: 350,
      align: "left"
    }
  }

  const createdAt = {
    headerName: t('createdAt'),
    field: "createdAt",
    cellProps: {
      width: 100,
      align: "left",
      sx: {whiteSpace: "nowrap"}
    }
  }

  const allowedRoles = {
    headerName: t('allowed_roles'),
    field: "allowedRoled",
    cellProps: {
      width: 200,
      align: "left"
    }
  }
  
  const columns = {
    xxs: [subject],
    md: [
      processName,
      subject 
    ],
    lg: [
      processName,
      subject,
      title,
    ],
    xl: [
      processName,
      subject,
      title,
      createdAt,
      allowedRoles
    ]
  }

  return useResponsiveColumns(columns, true)
}

export default GroupTasksTable;
