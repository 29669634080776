import { env } from "utils/utils"
import { download } from "utils/download"

const SERVER = env('SERVER')

export function createRemoteContentUrl(id) {
  return SERVER + 'content/'  + id
}

export function downloadAttachment(attachment) {
  download(createRemoteContentUrl(attachment.__id), attachment.filename)
}

