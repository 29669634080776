
import { useTranslation } from 'react-i18next';
import { getProcessName } from 'utils/form-utils';
import {
	Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow } from '@mui/material'
import { toDisplayValue }            from "utils/render";
import { useTranslator } from 'hooks/translator';

const ProcessTable = ({processInstance}) => {
	const { translator, t } = useTranslator()
	const cellStyle = {border: 'none'}

	return (
      <Table sx={{ minWidth: 300 }} size="small" aria-label="simple table">
        <TableBody>
					<TableRow  key="1" sx={{border:0}}>
              <TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('process_name')}:</TableCell>
              <TableCell align="left" sx={cellStyle}>{getProcessName(translator, processInstance)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('starter')}:</TableCell>
						<TableCell align="left" sx={cellStyle}>{processInstance.starter?.username}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="right" component="th" sx={{...cellStyle, fontWeight:"bold"}}>{t('started')}:</TableCell>
						<TableCell align="left" sx={cellStyle}>{toDisplayValue(processInstance.startTime)}</TableCell>
					</TableRow>
        </TableBody>
      </Table>
  );
}

const ConfirmDialog = ({processInstance, onClick, open, setOpen}) => {
	const {t} = useTranslation()
	const theme = useTheme()
	const handleClose = () => { setOpen(false) }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: theme.typography.h3.fontSize}}>
          {t('processes.killconfirmation')}
        </DialogTitle>
        <DialogContent>
					<Box sx={{mt: 3}} >
						<ProcessTable processInstance={processInstance}/>
					</Box>
          <DialogContentText id="alert-dialog-description" sx={{mt: 6}}>
						{t('processes.killwarning')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id={'process-' + processInstance.id + '-kill-button'} onClick={(e) => { handleClose(e); onClick(e); }}>{t('processes.kill')}</Button>
          <Button id={'process-' + processInstance.id + '-close-button'} onClick={handleClose} autoFocus>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
  );
}

export default ConfirmDialog;
