import React, {useEffect, useRef, useState} from "react";
import { Box }                              from "@mui/material";
import { FixedSizeList as List }            from "react-window";
import InfiniteLoader                       from 'react-window-infinite-loader'
import Loader                               from "components/Loader";

const Listbox = React.forwardRef((props, ref) => {
  const { children: items, hover, setHover, role, listboxProps, ...other } = props;
  const { highlightedIndex, isNextPageLoading, hasNextPage, loadNextPage } = listboxProps

  const actualCount         = Array.isArray(items) ? items.length : 0;
  const itemCount           = hasNextPage ? actualCount + 1 : actualCount;
  const loadMoreItems       = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded        = index => !hasNextPage || index < actualCount;
  const itemSize            = 36
  const height              = Math.min(8,itemCount) * itemSize

  const Option = ({option, style}) => {
    const index     = option.props['data-option-index']
    const className = index == highlightedIndex ? 'MuiAutocomplete-option Mui-focused' : 'MuiAutocomplete-option'

    return (
      <Box style={style} className={className} sx={{padding: '0 !important'}}  >
        {React.cloneElement(option, { style: {height: style.height, width: style.width}  } )}
      </Box>
    )
  }

  const Loading = ({style}) => (
    <Box sx={{...style, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
      <Loader size='tiny'/>
    </Box>
  )

  const Row = (props) => {
    if (!isItemLoaded(props.index))
      return <Loading style={props.style} />
    else
      return <Option style={props.style} option={items[props.index]} />
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={5}
    >
      {({ onItemsRendered, ref: infiniteRef }) => (
        <Box ref={ref} {...other} sx={{padding: '0 !important', maxHeight: height + 20 + 'px !important'}} >
          <List
            ref={infiniteRef}
            height={height}
            width='100%'
            itemSize={itemSize}
            onItemsRendered={onItemsRendered}
            overscanCount={5}
            itemCount={itemCount}
            role={role}
          >
            {Row}
          </List>
      </Box>
      )}
    </InfiniteLoader>
  )
})

export default Listbox
