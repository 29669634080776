import Elevate from 'components/Elevate';
import BasicTable from 'components/view/ViewTable';
import { useResponsiveColumns } from 'hooks/columns';
import useStartProcess from 'hooks/process';
import { useProcessRenderContext, useTiles } from 'hooks/render';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TileInputProps } from './Tile';
import { TileIcon } from './TileCard';

const ProcessTableView = () => {
  const tiles   = useTiles()
  const columns = useColumns()
  const rows    = useTableProcessRows(tiles)

  return (
    <Box sx={{display: "flex", width: "100%", flexGrow: 1, maxHeight: "100%", overflow: "auto"}}>
      <Elevate sx={{display: "flex", maxHeight: "100%", overflow: "auto", width: "100%", margin: "20px"}}>
        {/* @ts-ignore */}
        <BasicTable columns={columns} rows={rows} tableSx={{width: "100%", maxHeight: "100%"}} />
      </Elevate>
    </Box>
  )
}  

function useTableProcessRows(tiles: TileInputProps[]) {
  const startProcess = useStartProcess()
  const { setPath }  = useProcessRenderContext()

  const onClick = (tile: TileInputProps) => (e: any) => {
    switch (tile.type) {
      case 'directory': setPath(tile.path); break
      case 'process':   startProcess(e, tile.process!, "PROCESS_OPEN"); break
    }
  }

  return tiles.map(tile => ({
    id: tile.path,
    onClick: onClick(tile), 
    cells: {
      'icon': <TileIcon path={tile.path} size='30px' icon={tile.type == 'directory' ? "Folder" : undefined}/>,
      'tile' : tile.label || tile.process?.title,
    }
  }))
}

const useColumns = () => {
  const { t } = useTranslation()

  const process = {
    headerName: t('process_name'),
    field: "tile",
    cellProps: {
      sx: {
        flexGrow: 1,
        align: "left",
        paddingLeft: 0
      }
    }
  }

  const icon = {
    headerName: "",
    field: "icon",
    cellProps: {
      sx: {
        maxWidth: "60px",
        width: "1px",
        align: "left",
      }
    }
  }

  const columns = {
    xxs: [icon, process],
  }

  return useResponsiveColumns(columns, true)
}


export default ProcessTableView