import FirstPage    from 'components/table/page/FirstPage'
import LastPage     from 'components/table/page/LastPage'
import NextPage     from 'components/table/page/NextPage'
import PreviousPage from 'components/table/page/PreviousPage'

const MenuBarPaging = ({pageable, toolbarFunctions, loading}) => {
  if (pageable) {
    return (
      <>
        <FirstPage    onClick={toolbarFunctions.first}    loading={loading.first}    />
        <PreviousPage onClick={toolbarFunctions.previous} loading={loading.previous} />
        <NextPage     onClick={toolbarFunctions.next}     loading={loading.next}     />
        <LastPage     onClick={toolbarFunctions.last}     loading={loading.last}     />
      </>
    )
  } else return null
}

export default MenuBarPaging