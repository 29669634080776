import { gql } from '@apollo/client';

export const GET_PROCESS_DEFINITIONS = gql`
  query GetProcessDefinitions {
    processDefinitions {
      id
      key
      name
      traits
      description
      deploymentId
      hasStartForm
    }
  }`


export const GET_PROCESS_DEFINITION_BY_KEY = gql`
  query GetProcessDefinitionByKey($key: String!) {
    processDefinitionByKey(key: $key) {
      id
      key
      name
      traits
      hasStartForm
    }
  }`

export const START_PROCESS = gql`
  mutation StartProcessByKey($key: String!) {
    startProcessByKey(key: $key) {
      ... on ProcessInstance {
        id,
        tasks { 
          id,
          processDefinition { id, key, traits, hasStartForm }
        }
      }
    }
  }`

export const GET_PROCESS_INSTANCE_AND_TASK = gql`
  query GetProcessInstance($processInstanceId: ID!, $taskId: ID!) {
    processInstance(id: $processInstanceId) {
      id
      starter { id, username }
      startTime
      processDefinition { id, key, name, traits, hasStartForm }
      assignedTasks { 
        id,
        name,
        owner { id, username },
        processDefinition { id, key, traits, hasStartForm }
      }
    }
    task(id: $taskId) {
      id
      name
      owner { id, username }
      error
      processDefinition { id, key, traits, hasStartForm }
    }
  }`

export const GET_MY_PROCESS_INSTANCES = gql`
  query GetMyProcessInstances($start: Int!, $count: Int!, $filter: String) {
    myActiveProcessInstances(range: { start: $start, count: $count }, filter: $filter) {
      id
      starter { id, username }
      startTime
      processDefinition { id, key, name, traits, hasStartForm }
    }
    myActiveProcessInstancesCount(filter: $filter)
  }`

export const GET_ALL_PROCESS_INSTANCES = gql`
  query GetAllProcessInstances($start: Int!, $count: Int!, $filter: String) {
    allActiveProcessInstances(range: { start: $start, count: $count }, filter: $filter) {
      id
      starter { id, username }
      startTime
      processDefinition { id, key, name, traits, hasStartForm }
    }
    allActiveProcessInstancesCount(filter: $filter)
  }`

export const GET_PROCES_INSTANCES_COUNT = gql`
  query GetProcessInstancesCount { 
    myActiveProcessInstancesCount
  }`

export const STOP_PROCESS = gql`
  mutation StopProcess($id: ID!, $reason: String) {
    stopProcess(id: $id, reason: $reason)
  }
`

