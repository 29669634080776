import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { Box, Typography } from '@mui/material';

const BreadCrumbSeparator = () => <NavigateNextIcon  fontSize="small" />

const Breadcrumbs = ({crumbs, chipped= false}) => {
  return (
      <MuiBreadcrumbs
        separator={<BreadCrumbSeparator/>}
        maxItems={4}
        itemsAfterCollapse={2}
        itemsBeforeCollapse={2}
        sx={{
          ".MuiBreadcrumbs-ol": {gap: "5px"}, 
          ".MuiBreadcrumbs-separator": { margin: 0 }, 
        }}
      >
        <Typography></Typography> {/* this causes the leading separator*/}
        {crumbs.map( (crumb, index) => <Breadcrumb chipped={chipped} crumb={crumb} key={'' + index} id={index} clickable={index + 1 != crumbs.length} /> )}
      </MuiBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
	crumbs: PropTypes.arrayOf(PropTypes.shape({
		link: PropTypes.string,
		label: PropTypes.string
	}))
}

export default Breadcrumbs;