import TaskBreadcrumbs from 'components/breadcrumbs/TaskBreadcrumbs';
import Fallback from 'components/Fallback';
import HandleResult from 'components/HandleResult';
import StickyBannerLayout from 'components/layout/StickyBannerLayout';
import Step from 'components/task/standard/Step';
import TaskWindow from 'components/task/window/TaskWindow';
import { WindowPageProvider } from 'components/wm';
import AutoSubmitProvider from 'contexts/AutoSubmitContext';
import FormInfoProvider from 'contexts/FormInfoContext';
import SubmitProvider from 'contexts/SubmitContext';
import { useConfig } from 'hooks/config';
import { FormikFormInfo, useSubmitHandler } from 'hooks/submit';
import { NotFound } from 'pages';
import { GET_START_FORM } from 'queries/form';
import { GET_TASK } from 'queries/task';
import { useParams } from 'react-router';
import { HasIsStartForm, ProcessDefinition, Task as TaskType } from 'types/graphql';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';

/** Render a Task. A Task has a Form in its contents. */
const Task = (props: HasIsStartForm): JSX.Element => {
  const params = useParams();

  return (
    <Fallback type='page' name={"task"}>
      <SubmitProvider>
        <AutoSubmitProvider>
          <TaskContents {...props} id={params.id as string} />
        </AutoSubmitProvider>
      </SubmitProvider>
    </Fallback>
  )
}

interface ProcessProps extends HasIsStartForm {
  id: string
}

/** Render the contents of a task. */
const TaskContents = ({id, isStartForm}: ProcessProps) => {
  const query           = isStartForm ? GET_START_FORM : GET_TASK
  const formResult      = useQuery(query, { variables: { id: id } })

  // render the task
  return (
    <HandleResult result={formResult}>
      <FormInfoInjector data={formResult.data} id={id} isStartForm={isStartForm}/> 
    </HandleResult>
  )
}

const FormInfoInjector = ({data, id, isStartForm}: ProcessProps & {data: any}) => {
  const startFormData    = data as {processDefinition: ProcessDefinition}
  const taskFormData     = data as {task: TaskType}

  if (isStartForm) {
    if (!startFormData.processDefinition) {
      console.error("There is no process definition with id: %o", id)
      return <NotFound />
    }
  } else {
    if (!taskFormData.task) {
      console.error("There is no task with id: %o", id)
      return <NotFound />
    }
  }

  return (
    <FormInfoProvider isStartForm={isStartForm} {...startFormData} {...taskFormData}>
      <TaskRender isStartForm={isStartForm} id={id} />
    </FormInfoProvider>
  )
}

const TaskRender = ({id, isStartForm}: ProcessProps) => {
  const {taskRendering} = useConfig()

  return taskRendering == 'standard' 
    ? <StandardTaskForm id={id} isStartForm={isStartForm} />
    : <WindowTaskForm />
}

const StandardTaskForm = ({id, isStartForm}: ProcessProps) => {
  const { handleSubmit } = useSubmitHandler(id, isStartForm)

  return (
    <StickyBannerLayout
      banner={<TaskBreadcrumbs />}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <Step onSubmit={(values: any, formInfo: FormikFormInfo) => { handleSubmit(values, formInfo) }} />
      </Box>
    </StickyBannerLayout>
  )
}

const WindowTaskForm = () => {
  return (
    <WindowPageProvider>
      <SubmitProvider>
        <TaskWindow />
      </SubmitProvider>
    </WindowPageProvider>
  )
}

export default Task
