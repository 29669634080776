import UserSettings from 'components/layout/dashboard/UserSettings';
import React, { createContext, useState } from 'react';

export const UserSettingsContext = createContext();

const UserSettingsProvider = ({children}) => {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <UserSettingsContext.Provider value={{open, setOpen}}>
        {children}
      </UserSettingsContext.Provider>
      <UserSettings open={open} setOpen={setOpen}/>
    </React.Fragment>
  )
}
 
export default UserSettingsProvider