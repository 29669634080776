import { gql } from '@apollo/client'

export const GET_DETAIL_LINKS = gql`
  query GetDetailLinks($key: ID!) {
    detail(key: $key) {
      id
      links {
        id, key, ref, label, icon, enabled, kind, conditional, processKey
      }
    }
  }
`

export const GET_DETAIL_FORMVALUES = gql`
  query GetDetailFormValues($detailKey: ID!, $detailId: ID!, $linkKey: ID!) {
    detail(key: $detailKey) {
      id, 
      instance(id: $detailId) {
        id
        link(key: $linkKey) {
          id
          formValues
        }
      }
    }
  }
`

export const GET_DETAIL_VIEW = gql`
  query GetDetailSchema($detailKey: ID!, $detailId: ID!) {
    detail(key: $detailKey) {
      id
      schema
      instance(id: $detailId) {
        id
        links { 
          id, key, ref, label, icon, kind, conditional, processKey, enabled, conditional
        }
      }
    }
  }
`

export const GET_DETAIL_DATA = gql`
  query GetDetailData($key: ID!, $id: ID!) {
    detail(key: $key) {
      id
      instance(id: $id) {
        id
        data
      }
    }
  }
`

export const GET_DETAIL_LIST_DATA = gql`
  query GetDetailListData($detailKey: ID!, $parentListId: ID!, $listKey: ID!, $start: Int!, $count: Int!, $filter: String) {
    detail(key: $detailKey) {
      id
      instance(id: $parentListId) {
        id
        list(key: $listKey) {
          data(filter: $filter, range: { start: $start, count: $count })
          count(filter: $filter)
        }
      }
    }
  }
`
