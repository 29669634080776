import { currencyFormatter } from 'components/render/RenderCurrency';
import { DecimalSeparatorSetting } from 'contexts/ConfigContext';
// @ts-ignore
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import { Currency, GearsValue } from 'types/types';
import { t } from 'utils/i18n';

import { getInitialLocale } from './localization-utils';

export type DisplayProps = {
  language?: string
  locale?: string
  type?: string
  currency?: Currency
  decimalSetting?: DecimalSeparatorSetting
}

export const toDisplayValue = (value: any, props?: DisplayProps): string | number => {
  switch (true) {
    case typeof value == "boolean" /*|| value == "true" || value == "false"*/:
      return t(`values.${value}`) as string;

    case typeof value == 'string':
      return interpretString(value as string, props?.language)

    case typeof value == "number":
      if (Number.isSafeInteger(value)) {
        return value
      } else {
        const decimalLocale = getDecimalLocale(props)
        if (props?.type == 'currency') 
          return currencyFormatter(value, props?.currency, decimalLocale)
        else {
          return value.toLocaleString(decimalLocale, {minimumFractionDigits: 2})
        }
      }

    default:
      return JSON.stringify(value);
  }
}

export function augmentValueBasedOnLabel<T>(label: string, value: T): T | GearsValue {
  if (isCurrencyLabel(label))
    if (typeof value == 'number' && !Number.isSafeInteger(value))
      return {__kind: "currency", __currency: determineCurrency(label), __value: value}

  return value
}

export function isCurrencyLabel(label: string): boolean {
  if (typeof label == 'string') {
    const lower = label.toLowerCase()
    return lower.includes("price") || lower.includes('prijs') || lower.includes('€') || lower.includes('$')
  }
  return false
}

export function determineCurrency(label: string): Currency {
  const lower = label.toLowerCase() 
  switch(true) {
    case lower.includes('€'):      return "EUR"
    case lower.includes("$"):      return "USD"
    case lower.includes("dollar"): return "USD"
    case lower.includes("eur"):    return "USD"
    default:                       return "EUR"
  }
}

export function getDisplayPropsFromLabel(label: string): DisplayProps {
  if (isCurrencyLabel(label)) 
    return { type: 'currency', currency: determineCurrency(label) }

  return {}
}

export function getDecimalLocale(props?: DisplayProps) {
  switch (props?.decimalSetting) {
    case 'comma': 
      return "nl-NL" // yields comma
    case 'dot':
      return "en-US" // yields dot
    case 'locale':
    default:
      const locale = props?.locale || getInitialLocale(props?.locale)
      return locale?.replace("_", "-")
  }
}

export const interpretString = (value: string, language?: string) => {
  const m1 = moment(value, [ "YYYY-MM-DDTHH:mm:ss.SSSSSSSS", "YYYY-MM-DDTHH:mm:ss.SSSSSSSSZ","YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DDTHH:mm:ssZ" ], true)
  if (m1.isValid())
    return m1.format("LLL")
  
  const m3 = moment(value, [ "YYYY-MM-DD" ], true)
  if (m3.isValid()) 
    return m3.format("LL")

  const m4 = moment(value, [ "HH:mm:ss" ], true)
  if (m4.isValid())
    return m4.format("LTS")

  const m5 = moment(value, [ "HH:mm:ss.SSSSSSS" ], true)
  if (m5.isValid())
    return m5.format("LTS")

  if (/^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/.test(value)) 
    return durationToHumanReadable(value, language)

  return value
}

const shortLanguages = {
  languages: {
    enShort: {
      y: () => "y",
      mo: () => "mo",
      w: () => "wk",
      d: () => "d",
      h: () => "h",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
    nlShort: {
      y: () => "jr",
      mo: () => "mnd",
      w: () => "wk",
      d: () => "d",
      h: () => "u",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
  }
}

const shortHumanizer = humanizeDuration.humanizer(shortLanguages)

function humanize(millis: number, options?: any) {
  const lang     = options?.language
  const language = lang ? {language: `${lang}Short`, fallback: [lang, 'en']} : {}
  const props    = {
    decimals: lang ? lang == "en" ? "," : "." : ".",
    delimiter: " ",
    // conjustion: t("descriptions.task.and"),
    // serialComma: false
  }
  
  return shortHumanizer(millis, {...options, ...language, ...props})
}

function durationToHumanReadable(duration: string, language?: string) {
  const m = moment.duration(duration)
  return m.isValid() ? humanize(m.asMilliseconds(), {language}) : undefined
}
