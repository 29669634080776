import { RenderValue } from 'components/render';
import { useFieldInfo } from 'hooks/field';
import _ from 'lodash';
import { MultipleColumn, useMultipleColumns } from 'utils/multiple-utils';
import { fallback } from 'utils/utils';

import {
    alpha, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';

const MultipleEditTable = () => {
  const { fieldProps } = useFieldInfo()
  const rows           = fallback(fieldProps.value, [{}])
  const columns        = useMultipleColumns(rows)

  return (
    <Box sx={{width: "100%"}}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 120px)', minWidth: '100%', overflow: 'auto' }}>
        <Table size="small" id={fieldProps.id} stickyHeader >
          <MultipleEditTableHeader columns={columns} />
          <MultipleEditTableBody columns={columns} rows={rows} />
        </Table>
      </TableContainer>
    </Box>
  )
}

type Row = any

type TableRowType = {
  id: number
  row: Row
}

type TableBodyProps = {
  columns: MultipleColumn[]
  rows: TableRowType[]
}

const MultipleEditTableBody = ({columns, rows}: TableBodyProps) => {
  return (
    <TableBody>
      { rows.map((row, index) => 
          <MultipleEditTableRow key={index} id={index} row={row} columns={columns} />
        )
      } 
    </TableBody>
  )
}

interface TableRowProps extends TableRowType {
  columns: MultipleColumn[]
}

const MultipleEditTableRow = ({id, row, columns}: TableRowProps) => {
  return (
    <TableRow 
      hover
      key={id}
    >
      { columns
          .map((column, index) =>{
            const value =_.get(row, column.attr) 

          return (
            <TableCell key={index} sx={{background: column.type == 'basedon' ? theme => alpha(theme.palette.grey[100], 0.5): undefined}} >
              {/* @ts-ignore */}
              <RenderValue>
                {value == null && column.type == 'input' && column.required ? " " : value}
              </RenderValue>
            </TableCell>
          )}
          )
        }
    </TableRow>
  )
}


const MultipleEditTableHeader = ({columns}: {columns: MultipleColumn[]}) => {
  return (
    <TableHead>
      <TableRow>
        { columns
          .map((column, index) => (
            <TableCell key={index} >
              <MultipleHeaderTitle
                column={column}
              />
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

const MultipleHeaderTitle = ({column}:{ column: MultipleColumn}) => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Typography variant="body2">
      {column.name}{column.required ? "*" : ""}
    </Typography>
  )
}

export default MultipleEditTable