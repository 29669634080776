import { 
  Button, Dialog,
  DialogTitle, DialogContent,
  DialogActions,
  useTheme }                    from "@mui/material"
import PropTypes                from 'prop-types'
import { useTranslation }       from 'react-i18next'

const SettingsDialog = ({open, setOpen, children}) => {
  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  const handleOnClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const { t }  = useTranslation()
	const theme  = useTheme()

  return (
	  <Dialog open={open} onClick={handleOnClick} /* PaperProps={{ref:ref}} */ >
			<DialogTitle sx={{fontSize: theme.typography.h3.fontSize}}>{t('settings')}</DialogTitle>
			<DialogContent sx={{pt: 3}}>
        {children}
        <DialogActions sx={{padding: 0, mt: 3}}>
					<Button id='close-button' onClick={handleClose}>{t('close')}</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

SettingsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}

export default SettingsDialog;
