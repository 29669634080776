
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useApolloClient } from '@apollo/client'
import { useNotifier } from 'hooks/notification'
import { useTranslator } from 'hooks/translator'
import Report from 'utils/report'
import LoadingTable from 'components/table/LoadingTable'
import { GET_DETAIL_DATA } from 'queries/detail'
import { F } from 'helpers/formatter'
import { augmentValueBasedOnLabel } from 'utils/render'

const DetailTable = ({schema, section}) => {
  const {columns, rows: initialRows} = useGroupData(schema, section)
  const [rows, setRows]              = useState(initialRows)
  const [loading, setLoading]        = useState(false)
  const notifier                     = useNotifier()
  const client                       = useApolloClient()

  const params    = useParams()
  const detailKey = params.detail
  const id        = params.id

  useEffect(() => {
    setLoading(true)
    const variables  = { key: detailKey, id: id, start: 0, count: 5000 }
    const newRequest = client.query({ query: GET_DETAIL_DATA, variables })

    newRequest
      .then(
        result => {
          console.log("handle group result: data=%o", result)
          const path = "data.detail.instance.data"
          const data = _.get(result, path)
          const rows = initialRows.map(row => ({...row, cells: {...row.cells, value: augmentValueBasedOnLabel(row.cells.label + row.cells.key, data[row.cells.key])}})) 
          setRows(rows)
        },
        error => {
          console.error("failed to get group data: %o", error.message)
          const report = Report.from(error, { category: Report.backend })
          report.addToNotifier(notifier)
        }
      )
      .catch(reason => {
        notifier.error("Could not retrieve more options")
        console.error("The frontend has an issue with retrieving options: " + reason)
      })
      .finally(() => {
        setLoading(false)
      })
  },[])

  return <LoadingTable columns={columns} rows={rows} loading={loading} />
}

const useGroupData = (schema, section) => {
  const { translator }  = useTranslator()

  const sectionTitle = translator.translate(
    `${schema.translationBaseKey}.sections.${section.key}`,
    { user: true, fallback: section.label, format: F.toTitleCase }
  )

  const columns = [{ headerName: sectionTitle, field: 'label' }, { headerName: '', field: 'value' }]
    .map(col => ({...col, cellProps: { sx: {whiteSpace: 'nowrap'}}}))

  const toDetailLabel = (rowKey, rowLabel) => {
    return translator.translate(
      `${schema.translationBaseKey}.labels.${rowKey}`,
      { user: true, fallback: rowLabel, format: F.toTitleCase }
    )
  }

  const toRowData = (row) => {
    const label = toDetailLabel(row.key, row.label)
    return {
      cells: { 
        key: row.key, 
        label: label, 
        value: augmentValueBasedOnLabel(label + row.key + row.label, row.value)
      }
    }
  }

  const rows = section.fields.map(toRowData)

  // adjust left column width
  // columns[0].cellProps.width = '40%'

  return { columns, rows }
}

export default DetailTable
