import { 
  Box, Autocomplete,
  TextField }                   from "@mui/material"
import { useTranslation }       from 'react-i18next'
import { normalizeLocale }      from "utils/localization-utils";
import { enabledLocaleOptions } from "config/locale";
import { getRegionAndLanguage } from "utils/language";
import { selectOption }         from "utils/option-utils";
import { useLocale }            from "hooks/locale"
import { useEffect, useState } from "react";

const LanguageSelector = () => {

  const { locale, setLocale } = useLocale()
  const [value, setValue] = useState(locale)
  const { t } = useTranslation()

  useEffect(() => { setLocale(value) },[value])

  return (
    <Autocomplete sx={{ mt: 2 }}
      options={enabledLocaleOptions}
      style={{ width: 300 }}
      value={selectOption(enabledLocaleOptions, normalizeLocale(value))}
      autoHighlight
      disableClearable
      onChange={(event, newOption) => {
        event.preventDefault()
        event.stopPropagation()
        setValue(newOption.value);
      }}
      renderOption={(props, option) => {
        const rl = getRegionAndLanguage(option.value)

        return (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${rl.region.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${rl.region.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label}
          </Box>
        )
      }
      }
      renderInput={(params) => (
        <TextField autoComplete='new-password' {...params} label={t('language')} fullWidth />
      )}
    />
  )
}

export default LanguageSelector