import Table           from '@mui/material/Table';
import TableContainer  from '@mui/material/TableContainer';
import FileTableHeader from 'components/explorer/FileTableHeader';
import FileTableBody   from 'components/explorer/FileTableBody';
import { getMinWidth } from 'utils/tables';

const FileTable = ({rows, columns, loading, setPath}) => {
  const minWidth = getMinWidth(columns)
  return (
    <TableContainer sx={{overflow: 'auto', flexGrow: 1,  minWidth: minWidth}}>
      <Table stickyHeader size='small' loading={Boolean(loading).toString()} >
        <FileTableHeader columns={columns} />
        <FileTableBody rows={rows} columns={columns} setPath={setPath}/>
      </Table>
    </TableContainer>
  )
}

export default FileTable
