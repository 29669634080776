import merge                             from 'deepmerge';
import { GET_PROCESS_DEFINITION_BY_KEY } from "queries/process";
import { decomposeRefPath }              from "utils/path";
import Report                            from "utils/report";
import Settings                          from "utils/settings-utils";
import { isUrl }                         from "utils/utils";

export const getEnabledLinks = (links) => {
  if (Array.isArray(links))
    return links.filter(link => !link.hasOwnProperty('enabled') || link.enabled)
  else return []
}

export const combineLinks = (...args) => {
  if (!Array.isArray(args) || args.some(links => links.length != args[0].length))
    throw "cannot combine link arrays with different lengths"

  if (args.length == 0)
    return []
  else
    return args[0].map((_,index) => merge.all(args.map(links => links[index])))
}

export const createOnClickLinks = (runtimeLinks, notifier) =>
  runtimeLinks.map(link => ({onClick: defaultOnClick(notifier)}))

export const createLabelLinks = (translator, context, runtimeLinks) =>{
  return runtimeLinks.map(link => ({ label: toLinkLabel(translator, context, link)}))
}

export const createTipLinks = (translator, runtimeLinks, menuItems) =>
  runtimeLinks.map(link => ({tip: toLinkTip(translator, link, menuItems)}))

export const addOnClick = (meta, setOnClick, link) => {
  if (!link.ref)
    notifier.error("A view link does not have a ref")

  const { key } = decomposeRefPath(link.ref)
  switch (toLinkType(link)) {
    case "external":
      addExternalOnClick(meta, setOnClick, link.href || key)
      break;
    case "internal":
      addInternalOnClick(meta, setOnClick, link.href || key)
      break;
    case "process":
      addProcessOnClick(meta, setOnClick, key, link.kind)
      break;
    default:
      addDefaultOnClick(meta, setOnClick)
  }
}

const defaultOnClick = ({notifier}) =>
  () => notifier.error("No link was set")

const addDefaultOnClick = (meta, setOnClick) => 
  setOnClick(defaultOnClick(meta))


const addProcessOnClick = (meta, setOnClick, processKey, kind) => {
  const {startProcess, notifier, client} = meta
  
  client.query({ query: GET_PROCESS_DEFINITION_BY_KEY, variables: { key: processKey } })
    .then(
      res => {
        const pd = res.data.processDefinitionByKey
        setOnClick(createProcessOnClick(startProcess, pd, kind))
      }, 
      error => {
        notifier.error("During the creation of a link, could not obtain process with key: " + processKey)
        console.error("No process definition found for key: %o\nerror: %o" + processKey, error)
        addDefaultOnClick(meta, setOnClick)
      })
}

const createProcessOnClick = (startProcess, pd, kind) => {
  switch (kind) {
    case 'PROCESS_OPEN':
      return (e) => {
        e.preventDefault()
        startProcess(e, pd, kind)
      }

    case 'PROCESS_FILL':
      return (e) => {
        e.preventDefault()
        startProcess(e, pd, kind)
      }

    case 'PROCESS_SUBMIT':
      return (e, valuesPromise) => {
        e.preventDefault()
        startProcess(e, pd, kind, valuesPromise)
      }

    default:
      alert("Unsupported link kind: " + JSON.stringify(kind))
  }
}

const addExternalOnClick = (meta, setOnClick, href) => {
  setOnClick((e) => {
    e.preventDefault();
    window.location = href;
  })
}

const addInternalOnClick = ({navigate}, setOnClick, href) => {
  setOnClick((e) => {
    e.preventDefault()
    navigate(href)
  })
}

const toLinkTip = (translator, runtimeLink, menuItems) => {
  switch (toLinkType(runtimeLink)) {
    case "external": 
      return translator.translate('view.drawer.link.href', {variables: {link: runtimeLink.href}})
    case "internal": 
      return toInternalTip(translator, runtimeLink, menuItems)
    case "process":  
      const { key: processKey } = decomposeRefPath(runtimeLink.ref)
      const label               = translator.toProcessTitle(processKey, runtimeLink.label)
      return translator.translate('view.drawer.link.process', {variables: {process: label}})
    default: return undefined
  }
}

const toInternalTip = (translator, link, menuItems) => {
  const href = link.href
  const info = menuItems.find(info => info.href == href)

  if (info) {
    return translator.translate('view.drawer.link.href', {variables: {link: info.label}})
  } else {
    return translator.translate('view.drawer.link.href', {variables: {link: link.href}})
  }
}

const toLinkLabel = (translator, context, link) =>{
  if (context.context == "list") {
    if (context.parent == "contextmenu")
      return translator.toDetailLink(context.props.detailKey, link.key, link.label)
    else
      return  translator.toListLink(context.props.listKey, link.key, link.label)
  } 
  else if (context.context == "detail")
    return translator.toDetailLink(context.props.detailKey, link.key, link.label)
  else
    throw "??? not implemented"
}

export const toLinkType = (link) => {
  const href = link.href
  if (href) {
    return isUrl(href) ? "external" : "internal"
  } else {
    return decomposeRefPath(link.ref)?.domain
  }
}

export const prepareLinkFormValues = (client, notifier, request, path, kind) => {
  switch (kind) {
    case "PROCESS_FILL":
      return prepareProcessFillFormValues(client, notifier, request, path, kind)

    case "PROCESS_SUBMIT":
      return getLinkFormValues(client, notifier, request, path) 

    default:
      return new Promise(() => {})
  }
}

export const prepareProcessFillFormValues = (client, notifier, request, path, kind) => {
  return getLinkFormValues(client, notifier, request, path)
    .then(values => { storeFormValuesLocally(values, kind) })
}

function getLinkFormValues(client, notifier, request, path) {
  const success = result => {
    const formValues = _.get(result, path)
    if (formValues) {
      return formValues
    } else {
      console.error("Failed to get form values from result: %o", result)
      const report = Report.from(error, { category: Report.backend })
      report.addToNotifier(notifier)
      return null
    }
  }

  const failure = error => {
    console.error("Failed to get form values: %o", error.message)
    notifier.error("Failed to get form values.")
  }

  const catchFailure = reason => {
    console.error("The frontend has an issue with retrieving form values: " + reason)
    notifier.error("Failed to get form values.")
  }

  return client
    .query(request)
    .then(success, failure)
    .catch(catchFailure)
}

export const storeFormValuesLocally = (formValues, kind) => {
  console.log("Store values for %s: %o", kind, formValues)
  Settings.session.write("FORM.VALUES", formValues)
  Settings.session.write("FORM.KIND", kind)
}
