import { Stack } from '@mui/material'
import { Center } from 'components/Center'
import Fallback from 'components/Fallback'
import Section from 'components/view/Section'

/** Render a Task. A Task has a Form in its contents. */
function Detail({ schema: inSchema }) {
  const translationBaseKey = `views.details.${inSchema.key}`
  const schema             = { ...inSchema, kind: "detail", translationBaseKey }

  return (
    <Fallback name='detail'>
      <Stack className='details-stack' sx={{ width: '100%', maxWidth: '100%', boxSizing: 'content-box' }} spacing={3}>
        {schema.sections.map((section, index) =>
          <Center maxHeight={`calc(100vh - 175px)`} maxWidth='100%' key={index}>
            <Section schema={schema} section={section} />
          </Center>
        )}
      </Stack>
    </Fallback>
  )
}

export default Detail
