import { ConfigContext } from 'contexts/ConfigContext';
import { useLocale } from 'hooks/locale';
import { useTranslator } from 'hooks/translator';
import { useContext, useEffect, useState } from 'react';
import { createMenuItems, MenuItem } from 'utils/menu';

import { useNotifier } from './notification';

export const useConfig = () => useContext(ConfigContext)

export const useDashboardMenuItems = () => {
  const { translator }    = useTranslator()
  const {menu, user}      = useConfig()
  const notifier          = useNotifier()
  const [items, setItems] = useState<MenuItem[]>([])
  const { language }      = useLocale()
 
  useEffect(() => {
    const items = createMenuItems(translator, notifier, menu)
    setItems(items)
  }, [language, user])

  return items
}

export const useGearsTheme = () => {
  return useConfig().theme
}
