import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox  }                           from "@mui/material";
import { formatChoices }                from "utils/input-utils";
import { useGearsTranslation }          from "hooks/translation";
import { useFieldInfo }                 from "hooks/field";
import { getEventValue, validateField } from "utils/validate-utils";
import { useTranslator } from "hooks/translator";
import { useFormInfo } from "hooks/form";
import { isOption } from "utils/option-utils";
import { useAutoSubmitSignal } from "hooks/autosubmit";

const InputCheckField = (props) => {
  const { augProps, info, fieldProps } = useFieldInfo()
  const {signal}                       = useAutoSubmitSignal()

  const getOption = (value) => {
    if (isOption(value))
      return value
    else
      return Array.isArray(info.options) && info.options.find(option => option.value == value)
  }

  const getCurrentSelected = () => {
    const currentValues = Array.isArray(fieldProps.value) ? fieldProps.value : []
    return currentValues.map(getOption)
  }

  function isSelected(value) {
    const current = getCurrentSelected()
    return current.some(option => option.value == value || isOption(value) && option.value == value.value)
  }

  function getValue(e) {
    const value   = getEventValue(e, null)
    const option  = getOption(value)
    const current = getCurrentSelected()

    if (option) {
      if (isSelected(option))
        return current.filter(op => op.value !== option.value)
      else
        return [...current, option].filter((x) => x)
    }
    else return fieldProps.value
  }

  function handleValidate(e, value) {
    const error = validateField("multiselect", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(e) {
    augProps.setRuntimeError(undefined)
    const value = getValue(e)
    augProps.setValue(value)
    handleValidate(e, value)
  }

  function handleBlur(e) {
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

	return (
    <>
      { !props.inMultiple ? <Box sx={{mt:2}}/> : null }
      <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} error={fieldProps.error}>
        <FormLabel component="legend">{fieldProps.label}</FormLabel>
        <FormGroup
          aria-label={fieldProps.label}
          name={fieldProps.name}
          onBlur={handleBlur}
          onFocus={fieldProps.onFocus}
          onClick={handleChange}
        >
          <CheckboxOptions onChange={handleChange} isSelectedOption={isSelected}/>
        </FormGroup>
        <FormHelperText>{fieldProps.helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

const CheckboxOptions = ({onChange: handleChange, isSelectedOption}) => {
  const { info }       = useFieldInfo()
  const { translator } = useTranslator()
  const { processDefinition: { key: processKey }} = useFormInfo()

  const options = formatChoices(translator, info.options, info.optionsKind, processKey)
  return options.map((option, index) =>
    <FormControlLabel
      onClick={handleChange}
      key={index}
      value={option.value}
      label={option.label}
      control={<Checkbox checked={isSelectedOption(option.value)}/>}
    />
  )
}

export default InputCheckField
