import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider }        from '@mui/material/styles';
import { useRoutes }            from 'react-router-dom';
import GlobalStyles             from './theme/GlobalStyles';
import { ThemeProvider }        from '@emotion/react';
import routes                   from './routes';
import { AdapterDateFns }       from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LocaleProvider           from 'contexts/LocaleContext';
import NotificationProvider     from 'contexts/NotificationContext';
import { useCurrentUser }       from 'authentication/main';
import { ErrorBoundary }        from 'react-error-boundary';
import Report                   from 'utils/report';
import { NotificationType }     from 'components/Notification';
import { useTranslations }      from 'hooks/translation';
import { useTranslation }       from 'react-i18next';
import PageLoader from 'components/PageLoader';
import i18n                     from 'utils/i18n';
import ConfigProvider           from 'contexts/ConfigContext';
import { useConfig } from 'hooks/config';

const AppContent = ({loading}) => {
  const user                       = useCurrentUser()
  const content                    = useRoutes(routes(user));
  const { ready }                  = useTranslation('translation', { useSuspense: false })
  const languageIsLoading          = !ready || !i18n.isInitialized
  const { loading: configLoading , themes} = useConfig()

  // console.log("loading=%o, user.loading=%o, languageIsLoading=%o", loading, user.loading, languageIsLoading)
  return (
    <PageLoader size='large' loading={loading || user.loading || languageIsLoading || configLoading}>
      {content}
    </PageLoader>
  )
}

const App = () => {
  const translations = useTranslations()

  const errorHandler = (error) => {
    console.error("An error occured: %o", error)
  }

  const fallback = ({error, resetErrorBoundary}) => {
    const detailReport = Report.error(Report.frontend, Report.code.frontend.Broken, {component: "main"})
    const report = Report.from(error, {type: NotificationType.ERROR, verbose: detailReport.message},{component: "main"})
    return report.toNotification({resetBoundary: resetErrorBoundary})
  }

  return (
    <ErrorBoundary FallbackComponent={fallback} onError={errorHandler}>
      <NotificationProvider>
        <StyledEngineProvider injectFirst>
          <LocaleProvider>
            { locale =>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale.dateFnsLocale}>
                <ConfigProvider locale={locale}>
                  { config =>
                    <MuiThemeProvider theme={config.theme}>
                      <ThemeProvider theme={config.theme}>
                        <GlobalStyles />
                        <AppContent loading={config.loading || translations.loading}/>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  }
                </ConfigProvider>
              </LocalizationProvider>
            }
          </LocaleProvider>
        </StyledEngineProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
};

export default App;
