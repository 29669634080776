import {useEffect, useState}    from 'react';
import { Box }                  from '@mui/material';
import { gql, useQuery }        from '@apollo/client';
import { useTranslation }       from 'react-i18next';
import DirectoryHierarchy       from 'components/explorer/DirectoryHierarchy';
import ExplorerContainer        from 'components/explorer/ExplorerContainer';
import FileExplorerOptions      from 'components/explorer/FileExplorerOptions';
import FileTable                from 'components/explorer/FileTable';
import ExplorerBanner           from 'components/explorer/ExplorerBanner';
import { handleResult, notifyOnError, useDebouncedState }    from 'utils/utils';
import { useResponsiveColumns } from 'hooks/columns';
import PageLoader from 'components/PageLoader';
import { useNotifier }          from 'hooks/notification';
import { toRegex }              from 'utils/option-utils';

const GET_DOCUMENTS = gql`
  query GetDocuments {
    documents(range: {start: 0, count: 1000}) {
      id
      type
      title
      filename
      createdAt
      creator { id, username, givenName, familyName }
    }
  }`

const dummyData = [
    {
      id: '1',
      filename: 'file1',
      title: 'huh?',
      type: 'txt',
    },
    {
      id: '2',
      filename: 'file2',
      title: 'huh2?',
      type: 'txt',
    },
    {
      id: '3',
      filename: 'file3',
      title: 'huh3?',
      type: 'txt'
    },
    {
      id: '4',
      filename: 'file4',
      title: 'huh4?',
      type: 'txt'
    },
    {
      id: '5',
      filename: 'file5',
      title: 'huh5?',
      type: 'txt'
    },
    {
      id: '6',
      filename: 'file6',
      title: 'huh6?',
      type: 'txt'
    },
  ]


const PageFile = {
  directories: [
    {
      id: 'dir1',
      name: 'dir 1',
      content: {
        directories: [
          {

            id: 'dir2',
            name: 'dir 2',
            content: {
              directories: [],
              files: ['1']
            }
          }
        ],
        files: ['2','3']
      }
    },
    {
      id: 'dir3',
      name: 'dir 3',
      content: {
        directories: [],
        files: ['4']
      }
    },
  ],
  files: [
    '5',
    '6'
  ]
}

const toPage = (pageFile, dirId) => {
  if (pageFile.id === dirId)
    return pageFile

  const dirs = pageFile.content.directories
  for (let index = 0; index < dirs.length; index++) {

    const dir = dirs[index]
    const found = toPage(dir, dirId)
    if (found)
      return found
  }

  return null
}

const toContent = (pageFile, path) => {
  const page = toPage(pageFile, path)
  const directories = page.content.directories.map(dir => { return { id: dir.id, filename: dir.name, type: 'directory' } })
  const files       = page.content.files.map(id => dummyData.find(f => f.id === id))
  return [...directories, ...files]
}

const useColumns = () => {
  const { t } = useTranslation() // NOTE: hook call inside a function. be carefull when calling this.

  const config = {
    'default': [
      {
        field: 'title',
        headerName: t('explorer.title'),
        width: 200
      }
    ],
    'sm': [
      {
        field: 'createdAt',
        headerName: t('explorer.created_at'),
        width: 100
      }
    ],
    'md': [
      {
        field: 'creator.username',
        headerName: t('explorer.creator'),
        width: 100
      }
    ],
    'lg': [
      {
        field: 'type',
        headerName: t('explorer.type'),
        width: 100
      }
    ],
    'xl': [
    ]
  }

  return useResponsiveColumns(config)
}

const Explorer = (props) => {
  const result                                = useQuery(GET_DOCUMENTS)
  const [loading, setLoading]                 = useState(true)
  const [showDirectories, setShowDirectories] = useState(false)
  const [path, setPath]                       = useState('root')
  const [filter, setFilter, setCurrentFilter] = useDebouncedState('', 500)
  const [allRows, setAllRows]                 = useState([])
  const [rows, setRows]                       = useState([])
  const columns                               = useColumns()
  const notifier                              = useNotifier()

  notifyOnError(result, notifier)

  const dummy = false // set to 'true' to create an explorer experience
  const pageFile = {
    id: 'root',
    name: 'Drive',
    content: dummy
      ? PageFile
      : {
        directories: [],
        files: []
      }
  }

  function filterRows(rows, filter) {
    if (!filter)
      return rows

    const regex  = toRegex(filter)
    const check  = (value) => regex.test(value)
    const lambda = (row) => Boolean(check(row.filename) || check(row.title) || check(row.type))
    return rows.filter(lambda);
  }

  async function loadRows(filter) {
    const rows = dummy ? toContent(pageFile, path) : allRows
    const filteredRows = filterRows(rows, filter)
    setRows(filteredRows)
    setLoading(false)
  }

  // load initial rows
  useEffect(() => {
    if (!result.loading){
      const documents = result.data?.documents ? result.data.documents : []
      setAllRows(documents)
    }
  }, [result.loading])

  // load filtered rows
  useEffect(() => {
    setLoading(true)
    loadRows(filter)
  }, [path, filter, allRows])

  return (
    <ExplorerContainer >
      <Box style={{
        display: 'flex',
        flexFlow: 'column',
        width: "100%",
        height: "100%",
        maxHeight: "100%"
      }}>
        <Box sx={{flexShrink: 0}}>
          <ExplorerBanner
            dummy={dummy}
            value={filter}
            setValue={setFilter}
            setCurrentValue={setCurrentFilter}
            setShowDirectories={setShowDirectories}
            showDirectories={showDirectories}
          />
        </Box>

        <Box style={{ flexGrow: 1, flexShrink: 1, display: 'flex', flexFlow: 'row', overflow: "auto"}}>
          {/* directory viewer */}
          <DirectoryHierarchy
            showDirectories={showDirectories}
            pageFile={pageFile}
            path={path}
            setPath={setPath}
          />

          {/*  file viewer */}
          <Box sx={{flexGrow: 1, overflow: 'auto'}}>
            { dummy ? <FileExplorerOptions/> : null }
            <PageLoader loading={loading} >
              <FileTable columns={columns} rows={rows} setPath={setPath} loading={loading}/>
            </PageLoader>
          </Box>
        </Box>
      </Box>
    </ExplorerContainer>
  )
}

export default Explorer
