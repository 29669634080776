import { T } from 'helpers/translator';
import { Notifier } from 'hooks/notification';
import { toRefMenuInfo } from 'utils/menu';
import { createDetailUrl } from 'utils/view';

export function listSectionToCrumb(translator: T, notifier: Notifier, section: any) {
  const info  = toRefMenuInfo(translator, notifier, section.fqk)
  const link  = info?.href

  return {key: section.key, label: section.label, link, type: "list"}
}

export function detailRowToCrumb(row: any, detail: string, id: string) {
  function primaryAttempt(row: any) {
    const priority = ["name", "description", "client", "identification", "value", "user", "username"]

    const reducer = (computed: boolean, name: string) =>  computed || row.hasOwnProperty(name) && row[name]
    const label   = priority.reduce(reducer, false)

    return label ? { label } : undefined
  }
  
  const crumb = primaryAttempt(row)
  const link  = createDetailUrl(detail, id) 

  return {...crumb, link, type: "detail", key: detail}
}