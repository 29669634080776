import PropTypes                from 'prop-types'
import LanguageSelector         from "components/selectors/LanguageSelector";
import ThemeSelector            from "components/selectors/ThemeSelector";
import SettingsDialog           from "components/SettingsDialog";
import { Stack } from '@mui/material';

const MainSettings = (props) => {
  return (
    <SettingsDialog {...props} >
      <Stack spacing={2} sx={{ mt: "10px" }}>
        <LanguageSelector />
        <ThemeSelector />
      </Stack>
		</SettingsDialog>
	)
}

MainSettings.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}

export default MainSettings;
