import { TableRow }           from '@mui/material';
import {
  MultipleTableRowSelectCell,
  MultipleTableBasedOnCells,
  MultipleTableFieldCells,
  MultipleTableRowActions }   from 'components/fields/multiple';
import PropTypes              from "prop-types"

const MultipleTableRow = (props) => {
  const { row, selected } = props
 
  return (
    <TableRow 
      hover
      key={row.id}
      selected={selected.includes(row.id)}
    >
      <MultipleTableRowSelectCell {...props} id={row.id} />
      <MultipleTableBasedOnCells  {...props} />
      <MultipleTableFieldCells    {...props} />
      <MultipleTableRowActions    {...props} id={row.id} />
    </TableRow>
  )
}

MultipleTableRow.propTypes = {
  row        : PropTypes.shape({ 
    id: PropTypes.number.isRequired, 
    values: PropTypes.object.isRequired
  }),
  rows       : PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default MultipleTableRow

