import { Box, Grid, Typography } from '@mui/material';
import _                                from 'lodash'
import ProcessGridItems           from 'components/process/ProcessGridItems';
import Elevate from 'components/Elevate';

const sx = {
  processGroupContainer : {
    paddingTop: '16px',
  },
  processGroup : {
    backgroundColor: theme => theme.palette.background.default,
    padding: '20px',
    position: 'relative'
  },
  processGroupLabel: {
    position: 'absolute',
    marginTop: "-31px", 
    marginLeft: 0,
    borderStyle: "solid",
    borderRadius: "15px",
    borderWidth: "1px",
    borderColor: theme => theme.palette.grey[300],
    backgroundColor: 'white', 
    color: theme => theme.palette.grey[600],
    padding: 0,
    paddingLeft: "8px",
    paddingRight: "8px",
    backgroundColor: theme => theme.palette.contrast
  }
}

const ProcessGroup = ({processDefinitions, category}) => {
  return (
    <Box sx={sx.processGroupContainer}>
      <Elevate elevation={10} sx={{...sx.processGroup}} >
        <Box sx={sx.processGroupLabel}>
          <Typography fontSize={14} padding={0} fontWeight={500} marginBottom="-2px">{category}</Typography>
        </Box>
        <Grid container spacing={3} >
          <ProcessGridItems processDefinitions={processDefinitions} />
        </Grid>
      </Elevate>
    </Box>
  )
}

export default ProcessGroup