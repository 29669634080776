import { Content, ContentType } from 'helpers/content';
import { useNotifier } from 'hooks/notification';
import React, { useEffect, useState } from 'react';
import Types from 'types/types';
import { download } from 'utils/download';

export const useContentLoader = ({id, filename, mimeType, type}: {id: string, filename: string, mimeType?: string, type?: ContentType}) => {
  const [content, setContent] = useState<Content>(new Content(id, filename, type, mimeType))
  const [loading, setLoading] = useState(false)
  const notifier              = useNotifier()

  useEffect(() => {
    if (content.id != id) {
      setContent(new Content(id, filename, type, mimeType))
    }
  }, [id])

  const loadContent = (e?: React.SyntheticEvent): Promise<Content> => {
    e?.preventDefault()
    e?.stopPropagation()
    
    if (content.hasData) {
      return Promise.resolve(content)
    } else {

      setLoading(true)
      return content
        .load(Types.null<React.SyntheticEvent>(), notifier)
        .then(
          c => {
            setContent(c)
            return Promise.resolve(c)
          }
        )
        .finally(() => setLoading(false))
    }
  }

  // cleanup
  useEffect(() => {
    return () => { if (content) { content.destructor() } }
  }, [])

  return {loading, content, loadContent}
}

export const useContentOnClick = ({id, filename, mimeType, type}: {id: string, filename: string, mimeType?: string, type?: ContentType}) => {
  const {loading, loadContent, content} = useContentLoader({id, filename, mimeType, type})

  const downloadOnClick = (e?: React.SyntheticEvent): Promise<void> => {
    return loadContent(e).then(content => download(content.url, filename))
  }

/*  */
  return { downloadOnClick, loadOnClick: loadContent, content, loadContent, loading }
}

