import ContentPreview from 'components/fields/dnd/ContentPreview';
import ContentViewer from 'components/fields/dnd/ContentViewer';
import LoadingIcon from 'components/LoadingIcon';
import { RenderValue } from 'components/render';
import LargeElevatedTable from 'components/tables/LargeElevatedTable';
import GearsTranslator from 'helpers/translator';
import { useResponsiveColumns } from 'hooks/columns';
import { useContentLoader, useContentOnClick } from 'hooks/content';
import { useNotifier } from 'hooks/notification';
import { useTranslator } from 'hooks/translator';
import { GET_DOCUMENTS } from 'queries/documents';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GearsDocument } from 'types/graphql';
import { SetState } from 'types/react';
import { getExtension } from 'utils/files';

import { Download as DownloadIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

type DocumentEvent = {
  event: KeyboardEvent | null
  document: GearsDocument | undefined
}

const DocumentTable = () => {
  const columns      = useColumns()
  const {translator} = useTranslator()
  const [de, setDe]  = useState<DocumentEvent>({event: null, document: undefined})
  const notifier     = useNotifier()
  const { t }        = useTranslator()

  useEffect(() => {
    if (de.document)
      notifier.info(t("table.loaddocument", {variables: {filename: de.document.filename}}) as string)
  },[de])

  const request = {
    variables: {},
    query:     GET_DOCUMENTS,
    path:      'data.documents',
    countPath: 'data.documentsCount',
  }

  return (
    <React.Fragment>
      <LargeElevatedTable
        // @ts-ignore
        request={request}
        columns={columns}
        toTableRow={(document: GearsDocument) => toTableRowData(translator, document, setDe)}
      />
      <DocumentViewer de={de} setDe={setDe} />
    </React.Fragment>
  )
}

type DocumentViewerProps = {
  de:    DocumentEvent
  setDe: SetState<DocumentEvent>
}

const DocumentViewer = ({de, setDe}: DocumentViewerProps)  => {
  const [open, setOpen]        = useState(false)
  const {content, loadContent} = useContentLoader({id: de.document?.id || 'none', filename: de.document?.filename || "file.pdf", type: 'document'})

  useEffect(() => {
    if (content.id != 'none')
      loadContent().then(_ => setOpen(true))
  }, [content])

  const closeDocument = (open: boolean) => {
    setOpen(open)
    if (!open) 
      setDe({document: undefined, event: null})
  }

  return <ContentViewer content={content} open={open && de.event} setOpen={closeDocument}/>
}

const useColumns = () => {
  const { t } = useTranslation() // NOTE: hook call inside a function. be carefull when calling this.

  const config = {
    'default': [
      {
        field: 'name',
        headerName: t('explorer.title'),
        width: 200
      }
    ],
    'sm': [
      {
        field: 'createdAt',
        headerName: t('explorer.created_at'),
        width: 100
      }
    ],
    'md': [
      {
        field: 'creator.username',
        headerName: t('explorer.creator'),
        width: 100
      }
    ],
    'lg': [
      {
        field: 'type',
        headerName: t('explorer.type'),
        width: 100
      }
    ],
    'xl': [
    ]
  }

  return useResponsiveColumns(config)
}

/* convert a tasks to row data structure */
function toTableRowData(translator: GearsTranslator, document: GearsDocument, setDe: SetState<DocumentEvent>) {
  return {
    id: document.id,
    onClick: (e: KeyboardEvent) => {
      setDe({event: e, document: document})
    },
    cells: {
      ...document,
      name: <DocumentTitle document={document} />,
    }
  }
}

const DocumentTitle = ({document}: {document: GearsDocument}) => {
  const extension                  = getExtension(document.filename)
  const {loading, downloadOnClick} = useContentOnClick({
    id: document.id, filename: document.filename, type: 'document'
  })

  return (
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Box style={{ marginLeft: "0px", marginRight: "10px", display: "flex", flexShrink: 0, alignItems: "center", minWidth: "20px", height: "30px", maxHeight: "30px", overflow: "hidden" }}> 
        <Box sx={{width: "20px"}}>
          <ContentPreview content={{extension: extension, type: document.type}} fallback={undefined} />
        </Box>
        <LoadingIcon icon={DownloadIcon} tooltipProps={{title: "Download attachment"}} loading={loading} onClick={downloadOnClick} />
      </Box>
      {/* @ts-ignore */}
      <RenderValue >
        {document.title}
      </RenderValue>
    </Box>
  )
 }

export default DocumentTable;
