import PropTypes from 'prop-types';
import LanguageSelector from "components/selectors/LanguageSelector";
import ThemeSelector from "components/selectors/ThemeSelector";
import SettingsDialog from "components/SettingsDialog";
import RenderSelector from 'components/selectors/RenderSelector';
import { Stack } from '@mui/material';
import DecimalSeperatorSelector from 'components/selectors/DecimalSeperatorSelector';

const UserSettings = (props) => {
  return (
    <SettingsDialog {...props} >
      <Stack spacing={2} sx={{mt: "10px"}}>
        <LanguageSelector/>
        <ThemeSelector/>
        <DecimalSeperatorSelector/>
        <RenderSelector/>
      </Stack>
		</SettingsDialog>
	)
}

UserSettings.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}

export default UserSettings;
