import {
  Box
} from '@mui/material';
import { partitionType } from 'utils/type-utils';
import { useFormInfo } from 'hooks/form';
import TaskSubmit from 'components/task/TaskSubmit';
import StepCardFields from 'components/task/standard/StepCardFields';

const StepCardContent = ({partition}) => {
  const formInfo     = useFormInfo()

  return (
    <Box sx={{padding: 0}} id='step-input-container'> 

      {/* fields */}
      { partition.fields.length
        ? <StepCardFields
            partition={partition}
          />
        : null
      }

      {/* submit button */}
      { partition.submit
        ? <Box sx={{ display: 'flex', flexFlow: 'row'}}>
          <TaskSubmit/>
            {/*<Save/>*/}
          </Box>
        : null
      }
    </Box>
  )
}


StepCardContent.propTypes = {
  partition: partitionType,
}

export default StepCardContent
