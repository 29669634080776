
import { Box, Typography } from '@mui/material';
import { PickersDay }      from '@mui/x-date-pickers/PickersDay';
 
function ISO8601_week_no(dt) {
  var tdt = new Date(dt.valueOf());
  var dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  var firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

Date.prototype.getWeek = function() {
  return ISO8601_week_no(this)
}

export const renderDateDay = (day, _value, DayComponentProps) =>
  <DateDay key={Number(day)} day={day} DayComponentProps={DayComponentProps} />

// a seperate render component for DatePicker, in order to add week numbers
const DateDay = ({day, DayComponentProps}) => {
  return (
    <Box>
      { day.getDay() == 0 ? ( 
      <Box sx={{ position: 'absolute', marginLeft:"-255px", marginTop: "8px" }}> 
        <Typography color="primary" fontWeight={500} fontSize={12} >
          {day.getWeek()}
        </Typography>
      </Box>) : null}
      <Box>
        <PickersDay {...DayComponentProps} />
      </Box>
    </Box>
  );
}

export default DateDay