import { IconButton, Tooltip }      from '@mui/material';
import { useNotifier }              from 'hooks/notification';
import { authorizedDownloadAll }    from '../../utils/download';
import { Download as DownloadIcon } from '@mui/icons-material';
import { createRemoteContentUrl }      from 'utils/message';

const DownloadFilesButton = ({attachments}) => {
  const notifier = useNotifier()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    authorizedDownloadAll(attachments.map(attachment => ({...attachment, url: createRemoteContentUrl(attachment.__id)})), notifier)
  }

  return (
    <Tooltip title={"Download all"} disableInteractive>
      <span>
        <IconButton tabIndex={-1} size="small" onClick={onClick}  >
          <DownloadIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default DownloadFilesButton
