import { TableCell }    from '@mui/material';
import _                from 'lodash';
import  InputField      from 'components/fields/InputField';
import { useFieldInfo } from 'hooks/field';
import PropTypes        from "prop-types"

const MultipleTableFieldCells = ({row}) => {
  const { info, info: {indices} } = useFieldInfo()
  const newIndices = { ...indices, [info.field.elementName]: row.id }

  return info.field.fields.map((field, index) => (
    <TableCell key={"field_" + index}  sx={{paddingLeft: "3px", paddingRight: "3px", width: 0}}>
      <InputField
        field={field}
        indices={newIndices}
      />
    </TableCell>
  ))
}

MultipleTableFieldCells.propTypes = {
  row        : PropTypes.shape({
    id: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired
  }),
}
export default MultipleTableFieldCells
