import { useRef,
    useState }                 from 'react';
import { Helmet }              from 'react-helmet';
import { useTranslation }      from 'react-i18next'
import { useNavigate }         from 'react-router-dom'
import { useMutation }         from '@apollo/client';
import {
  Box,
  Container,
  TextField,
  Typography,
  Avatar
}                              from '@mui/material';
import LockOutlinedIcon        from '@mui/icons-material/LockOutlined';
import { LoadingButton }       from '@mui/lab';
import * as Yup                from 'yup';
import { Formik }              from 'formik';
import {
  authMode,
  useAuthNavigation }          from 'authentication/main'
import Report                  from '../utils/report';
import Fallback                from 'components/Fallback';
import { useNotifier }         from 'hooks/notification';
import { useConfig }           from 'hooks/config';
import { useTranslator }       from 'hooks/translator';
import { LOGIN_MUTATION }      from 'queries/login';

const Login = (props) => {
  return (
    <Fallback name='login'>
      <LoginContents {...props} />
    </Fallback>
  )
}

const LoginContents = () => {
  const { t }       = useTranslator()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('login')} | {project}</title>
      </Helmet>
      <Box
        sx={{
          pt: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'background.default',
          height: '100%',
          width: "100%",
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              mb:1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
              <Box>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
              </Box>
              <Box sx={{marginLeft: "5px"}}>
                <Typography variant="h1" >
                  {project}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mb: 5, mt:3, width: '100%', textAlign: 'center'}}>
          {/*
            <Typography variant="h2" >
              {t('login')}
            </Typography>
          */}
          </Box>
          { authMode === 'external' ? <ExternalLogin/> : <InternalLogin /> }
        </Container>
      </Box>
    </>
  );
};

const ExternalLogin = (props) => {
  const { t }    = useTranslation()
  const authNav  = useAuthNavigation()
  const notifier = useNotifier()
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false)

  function triggerLogin() {
    setLoading(true)
    authNav.login()
      .then(
        result => {
          console.log("Login successful: %o", result)
          setLoading(false)
          navigate('/gears/processes/start', {replace: true})
        }, 
        error => {
          console.log("Login failed: %o", error)
          setLoading(false)
          const report = Report.from(error, { category: Report.backend })
          report.addToNotifier(notifier)
        }
      )
      .catch(e => {
        console.error("Login error: %o", e)
        setLoading(false)
        Report.defaults.Whoops.addToNotifier(notifier)
      })
  }

  //useEffect(() => triggerLogin(), [])
  return (
    <Box sx={{mt: '120px'}}>
      <LoadingButton
        id='external-login-button'
        loading={loading}
        color="primary"
        fullWidth
        size="large"
        onClick={triggerLogin}
        variant="contained"
      >
        {t('auth.press')}
      </LoadingButton>
    </Box>
  )
}

const InternalLogin = (props) => {
  const [ loading, setLoading ] = useState(false)
  const { t }         = useTranslation();
  const formikRef     = useRef();
  const getFormikRef  = () => formikRef.current
  const auth          = useAuthNavigation()
  const [doLogin]     = useMutation(LOGIN_MUTATION)
  const navigate      = useNavigate()
  const notifier      = useNotifier()

  function login(username, password) {
      setLoading(true)
      const formik = getFormikRef()
      doLogin({ variables: { username, password }})
        .then(
          result => {
            console.log("Login successful: %o", result)
            
            const token = result.data.login
            auth.setToken(token)

            setLoading(false)
            navigate('/', {replace: true})
          },
          error => {
            console.error("Login failed: %o", error)
            setLoading(false)

            const report = Report.from(error, { category: Report.backend })
            const loginErrorCode = "error.INPUT.2"
            if (report.code === loginErrorCode) {
              // set field errors if it was an username/password error
              const errors = {
                username: t('yup.invalid.field', {field: t('username')}),
                password: t('yup.invalid.field', {field: t('password')})
              };

              formik.setErrors(errors);
            } else {
              // otherwise show the error with the notifier
              report.addToNotifier(notifier)
            }
          }
        )
        .catch(e => {
          setLoading(false)
          console.error("Login exception: %o", e)
          Report.defaults.Whoops.addToNotifier(notifier)
        })
  }


  async function handleSubmit(event) {
    setLoading(true)
    const formik = getFormikRef();

    formik.setSubmitting(true);
    await login(formik.values.username, formik.values.password)
    formik.setSubmitting(false);

    return 0;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255, t('yup.max', {field:t('username'), length:'255'}))
          .required(t('yup.required.field', {field: t('username')})),
        password: Yup.string()
          .max(255, t('yup.max', {field:t('password'), length:'255'}))
          .required(t('yup.required.field', {field: t('password')}))
      })}
      onSubmit={handleSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label={t('username')}
            autoComplete="username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.username}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t('password')}
            margin="normal"
            name="password"
            autoComplete='current-password'
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box sx={{ py: 2 }}>
            <LoadingButton
              id='login-submit-button'
              loading={loading}
              disabled={isSubmitting}
              color="primary"
              fullWidth
              size="large"
              type='submit'
              variant="contained"
            >
              {t('login')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default Login;
