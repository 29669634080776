import TaskComponent from 'components/task/Task';
import { useConfig } from 'hooks/config';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HasIsStartForm } from 'types/graphql';

const Task = (props: HasIsStartForm) => {
  const {t}         = useTranslation()
  const { project } = useConfig()

  return (
  <>
    <Helmet>
      <title>{t('task')} | {project}</title>
    </Helmet>

    <TaskComponent {...props}/>
  </>
);
}

export default Task
