import { gql } from '@apollo/client';

export const GET_ASSIGNED_TASKS_COUNT = gql`
  query GetAssignedTasksCount($filter: String) { 
    assignedTasksCount(filter: $filter)
  }`

export const GET_ALL_TASKS_COUNT = gql`
  query GetAllTasksCount($filter: String){ 
    allTasksCount(filter: $filter)
  }`

export const GET_GROUP_TASKS_COUNT = gql`
  query GetGroupTasksCount($filter: String) { 
    groupTasksCount(filter: $filter)
  }`

export const GET_COUNTS = gql`
  query GetCounts { 
    allTasksCount
    groupTasksCount
    assignedTasksCount
    myActiveProcessInstancesCount
    myMessagesCount(read: false)
  }`

export const TRANSFER_TASK = gql`
  mutation transferTask($id: ID!, $assignee: String!) {
    transferTask(id: $id, currentAssignee: $assignee)
  }`


export const GET_TRANSFERABLE_TASKS = gql`
  query GetTransferableTasks($start: Int!, $count: Int!, $filter: String) {
    transferableTasks(range: { start: $start, count: $count }, filter: $filter) {
      id
      name
      documentation
      createdAt
      subject
      assignee { id, username }
      allowedRoles
      processDefinition { id, key, name, traits, hasStartForm }
    }
    transferableTasksCount(filter: $filter) 
  }`

export const GET_ALL_TASKS = gql`
  query GetAllTasks($start: Int!, $count: Int!, $filter: String) {
    allTasks(range: { start: $start, count: $count }, filter: $filter) {
      id
      name
      documentation
      createdAt
      subject
      assignee { id, username }
      allowedRoles
      processDefinition { id, key, name, traits, hasStartForm }
    }
    allTasksCount(filter: $filter) 
  }`

export const GET_ASSIGNED_TASKS = gql`
  query GetAssignedTasks($start: Int!, $count: Int!, $filter: String) {
    assignedTasks(range: { start: $start, count: $count }, filter: $filter) {
      id
      name
      documentation
      createdAt
      subject
      assignee { id, username }
      allowedRoles
      processDefinition { id, key, name, traits, hasStartForm }
    }
    assignedTasksCount(filter: $filter)
  }`

export const GET_GROUP_TASKS = gql`
  query GetGroupTasks($start: Int!, $count: Int!, $filter: String) {
    groupTasks(range: { start: $start, count: $count }, filter: $filter) {
      id
      name
      documentation
      createdAt
      subject
      assignee { id, username }
      allowedRoles
      processDefinition { id, key, name, traits, hasStartForm }
    }
    groupTasksCount(filter: $filter)
  }`

export const GET_TASK = gql`
  query GetTask($id: ID!) {
    task(id:$id) {
      id
      name
      documentation
      createdAt
      subject
      assignee { id, username }
      allowedRoles
      form(options: { maxChoices: 99 })
      processInstanceId
      processDefinition { id, key, name , deploymentId, traits, hasStartForm }
    }
  }`


export const CLAIM_TASK = gql`
  mutation ClaimTask($id: ID!) {
    claimTask(id:$id)
  }
`

export const SAVE_TASK = gql`
  mutation SaveTask($id: ID!, $values: JSON!, $files: [Upload!]) {
    saveTask(id:$id, values:$values, files: $files )
  }`

export const SUBMIT_TASK = gql`
  mutation SubmitTask($id: ID!, $values: JSON!, $files: [Upload!]) {
    submitTask(id: $id, values: $values, files: $files, timeoutMillis: 3000) {
      __typename
      ... on ProcessInstance {
        id,
        assignedTasks { id , processDefinition { id, key, name, traits, hasStartForm }}
      }
      ... on ProcessInstanceEnded {
        id
      }
      ... on InputErrors {
        errors {
          path, name, type, label, value, status, message
        }
      }
      ... on SubmitInProgress {
        taskId
      }
    }
  }`

export const GET_CHOICES = gql`
  query GetChoices($taskId: ID, $processDefinitionId: ID, $path: String!, $filter: String, $start: Int!, $count: Int!, $values: JSON ) { 
    choices(taskId: $taskId, processDefinitionId: $processDefinitionId, path: $path, filter: $filter, range: { start: $start, count: $count }, values: $values) { 
      value
      label
    }
  }
`

export const ABORT_TASK = gql`
  mutation AbortTask($id: ID!) {
    cancelProcess(id: $id) 
  }
`

export const CANCEL_TASK = gql`
  mutation CancelTask($deploymentId: ID, $id: ID!) {
    cancelTask(deploymentId: $deploymentId, id: $id) {
      id
      assignedTasks {
        id
        processDefinition {
          id
          key
          name
          traits
          hasStartForm
        }
      }

    }
  }
`