import { deleteSelectedRows } from 'utils/multiple-utils';
import { DeleteButton }       from 'components/fields/multiple';
import { useTranslation }     from 'react-i18next';
import PropTypes              from "prop-types"
import { useFieldInfo }       from 'hooks/field';

const DeleteRowButton = ({rows, id, setSelected, selected}) => {
  const { t } = useTranslation()
  const { augProps } = useFieldInfo()

  const handleClick = (e) => {
    const newRows     = deleteSelectedRows(rows, [id])
    const newSelected = selected
      .filter(sid => sid !== id)        // remove id
      .map(sid => sid > id ? sid-1 : sid) // adjust all ids higher than id

    augProps.setValue(newRows)
    setSelected(newSelected)
  }

  return <DeleteButton
    id={'row-' + id + '-delete-button'}
    title={t("multiple.deleterow")}
    disabled={rows.length === 1}
    disabledTitle={rows.length === 1 ? t("multiple.disabled.deletelastrow") : null }
    onClick={handleClick}
  />
}

DeleteRowButton.propTypes = {
  rows       : PropTypes.array.isRequired,
  id         : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default DeleteRowButton
