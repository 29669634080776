import { useQuery } from '@apollo/client';
import {
  Box, IconButton, ListItemButton, ListItemText, Menu,
  Slide, Typography
} from '@mui/material';
import { useConfig, useDashboardMenuItems } from 'hooks/config';
import { GET_COUNTS } from 'queries/task';
import { useEffect, useRef, useState } from 'react';
import Settings from 'utils/settings-utils';
import { env, useDebouncedState } from 'utils/utils';
import SidebarContextProvider from 'contexts/SidebarContext';
import { useSidebarItemSX } from 'hooks/sidebar';
import { useLayoutContext } from 'hooks/layout';
import { useCurrentUser } from 'authentication/main';
import { AccountMenuItems } from 'components/layout/dashboard/DashboardMenu';
import UserSettingsProvider from 'contexts/UserSettings';
import { SidebarIcon, SidebarList } from 'components/layout/sidebar/SidebarList';
import useClickAway from 'hooks/clickaway';
import {
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { getEventValue } from 'utils/validate-utils';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { toRegex } from 'utils/option-utils';


const badge_interval = env('BADGE_REFRESH_INTERVAL')

export const DashboardSidebarItems = () => {
  const countResult                               = useQuery(GET_COUNTS)
  const menu                                      = useDashboardMenuItems()
  let   counts                                    = getCounts(countResult)
  const [filter, setFilter, setCurrentFilter]     = useDebouncedState('', 500)
  const { sidebarState, props: {transitionTime} } = useLayoutContext()
  const filteredMenu                              = filterMenu(menu, filter)

  useEffect(() => {
    setCurrentFilter("")
  }, [sidebarState.expandState])

  if (countResult.loading) {
    counts = Settings.session.read('drawer.badges', counts)
  } else {
    Settings.session.write('drawer.badges', counts)
  }

  useEffect(() => {
    countResult.startPolling(badge_interval)
    console.log("Updating badges with interval: %o", badge_interval)
    return () => { countResult.stopPolling() }
  }, [countResult.startPolling, countResult.stopPolling])

  const getTransition = () => {
    if (sidebarState.expandState == "expanded")
      return `height ${transitionTime} ease, opacity ${transitionTime} ${transitionTime} ease`
    else
      return `height ${transitionTime} ${transitionTime} ease, opacity ${transitionTime} ease`
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", flexGrow: 1 }}>
      <Box sx={{
        overflow: 'hidden',
        height: sidebarState.expandState == "expanded" ? "50px" : "0px",
        opacity: sidebarState.expandState == 'expanded' ? 1 : 0,
        transition: getTransition(),
        flexShrink: 0
      }}>
        <Box sx={{ padding: "10px", paddingTop: "18px", paddingBottom: "0px" }}>
          <SidebarFilter value={filter} setValue={setFilter} setCurrentValue={setCurrentFilter} />
        </Box>
      </Box>
      <SidebarContextProvider sidebarType={"sidebar"} counts={counts} filter={filter} >
        <SidebarList items={filteredMenu} />
      </SidebarContextProvider>
    </Box>
  )
}

export const isSidebarMatchFunction = (filter) => {
  const regex   = toRegex(filter)
  const check   = (value) => regex.test(value)
  const isMatch = (item) => !!check(item.label)

  return isMatch
}

function filterMenu(menu, filter) {
  const isMatch = isSidebarMatchFunction(filter)
  const hasChildren = (item) => Array.isArray(item?.children)

  const applyFilter = (menu) => {
    return menu.map(item => {
      if (hasChildren(item) && item.children.length > 0) {
        if (isMatch(item))
          return item
        else
          return { ...item, children: applyFilter(item.children) }
      }
      else
        return item
    })
    .filter(item => isMatch(item) || (hasChildren(item) && item.children.length > 0))
  }

  if (filter && Array.isArray(menu))
    return applyFilter(menu)
  else 
    return menu
}

const SidebarFilter = ({value: filter, setValue: setFilter, setCurrentValue: setCurrentFilter }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(filter)
  const placeholder=t('filter.search', { item: t('filter.menu') })

  useEffect(() => {
    setValue(filter) 
  }, [filter])

  const handleChange = (event) => {
    const input = getEventValue(event)
    setValue(input)
    setFilter(input)
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter": // enter completion
        e.preventDefault();
        setCurrentFilter(value)
        break;

      default:
    }
  };
  
  const handleClear = (e) => {
    setValue("")
    setCurrentFilter("")
  }

  const EndAdornment = () => {
    const disabled = !value
    return (
      <InputAdornment position="end">
        <IconButton disabled={disabled} onClick={handleClear} >
          <SvgIcon
            fontSize="small"
            color="action"
          >
            <ClearIcon style={{visibility: disabled ? 'hidden' : 'visible'}} />
          </SvgIcon>
        </IconButton>
      </InputAdornment>
    )
  }


  return (
    <TextField
      fullWidth
      InputProps={{
        onKeyDown: handleKeyDown,
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
        endAdornment: <EndAdornment/>
      }}
      placeholder={placeholder}
      variant="standard"
      onChange={handleChange}
      value={value}
    />
  )
}

const SidebarSettingMenu = ({anchorEl, setAnchorEl}) => {
  const handleClose = (e) => { 
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null) 
  }

  const ref = useClickAway(handleClose)
  const { sidebarState} = useLayoutContext()

  return (
    <SidebarContextProvider sidebarType={"menu"} addLevel handleMenuClose={handleClose}>
      <UserSettingsProvider>
        <Menu
          id="sidebar-settings-menu"
          anchorEl={anchorEl}
          MenuListProps={{
            ref:ref,
            onMouseLeave:handleClose
          }}
          onClick={handleClose}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          sx={{ zIndex: 1000, marginLeft: sidebarState.expandState == "expanded" ? "5px" : "0px" }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <AccountMenuItems/>
        </Menu>
      </UserSettingsProvider>
    </SidebarContextProvider>
  )
}

export const DashboardSidebarFooter = () => {
  const { user }  = useCurrentUser()
  const ref       = useRef()

  const iconName = !user ? "NoAccounts" : user.isAdmin ? "AdminPanelSettings" : "AccountCircle"
  const item = {
    label: "Account",
    key: "settings",
    type: "item",
    icon: iconName,
    activeIcon: iconName
  }
 
  const { itemSx, textSx } = useSidebarItemSX(item)
  const { bannerState, sidebarState } = useLayoutContext()
  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setAnchorEl(ref.current)
  }

  return (
    <Slide direction="up" in={bannerState != "expanded"}>
      <ListItemButton 
        onClick={(e) => {onClick(e);}} 
        sx={{...itemSx, paddingRight: "45px", width: "100%"}} 
        ref={ref}
      >
        <SidebarSettingMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}/>  
        <SidebarIcon item={item} />
        <ListItemText disableTypography 
          primary={
            <Typography 
              sx={{
                ...textSx, 
                marginLeft: sidebarState.expandState != "expanded" ? "20px" : "0px",
                transition: `margin 0.15s ease`
              }}
            >
              {item.label}
            </Typography>
          } 
        />
      </ListItemButton>
      </Slide>
  )
}

const Watermark = () => {
  const { project } = useConfig()

  return (
    <Box sx={{width: '100%', display: 'flex', padding: "5px", paddingRight: "10px", flexDirection: 'row'}}>
      <Box sx={{flexGrow:1}}/>
      <Box sx={{color: 'gray'}}>
      <Typography fontSize={12} fontWeight='bold'>
        {project}
      </Typography>
      </Box>
    </Box>
  )
}

function getCounts(result) {
  return {
    messagesBadge : getBadge(result, "myMessagesCount"),
    processBadge  : getBadge(result, "myActiveProcessInstancesCount"),
    allBadge      : getBadge(result, "allTasksCount"),
    assignedBadge : getBadge(result, "assignedTasksCount"),
    groupBadge    : getBadge(result, "groupTasksCount")
  }
}

function getBadge(result, name) {
  const count = result?.data ? result.data[name] : 0
  const badge = count >= 0 ? count : 0
  return badge
}

