import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useApolloClient, useQuery } from '@apollo/client'
import LargeTable from 'components/table/LargeTable'
import MenuItemProvider from 'contexts/MenuContext'
import useLinks from 'hooks/link'
import { useLocale } from 'hooks/locale'
import { useNotifier } from 'hooks/notification'
import { useGearsTranslation } from 'hooks/translation'
import {
  GET_DETAIL_FORMVALUES,
  GET_DETAIL_LINKS,
  GET_DETAIL_LIST_DATA
} from 'queries/detail'
import { GET_LIST_DATA } from 'queries/list'
import { prepareLinkFormValues } from 'utils/links'
import { toListRow } from 'utils/table'
import { handleResult } from 'utils/utils'
import { useTranslator } from 'hooks/translator'
import RenderContextProvider from 'contexts/RenderContext'
import { useRenderContext } from 'hooks/render'
import { F } from 'helpers/formatter'
import Fallback from 'components/Fallback'
import { getDisplayPropsFromLabel } from 'utils/render'

/** Render a Task. A Task has a Form in its contents. */
function ListTable({ schema, section }) {
  console.log("Render list with schema: %o", schema)
  return (
    <Fallback name='list'>
      <RenderContextProvider parent="list">
        <ListComponent schema={schema} section={section}/>
      </RenderContextProvider>
    </Fallback>
  )
}

const ListComponent = ({ schema, section }) => {
  const notifier = useNotifier()

  if (section.detail) {
    const detailLinkResult = useQuery(GET_DETAIL_LINKS, { variables: { key: section.detail } })

    return handleResult(
      detailLinkResult,
      notifier,
      data => <ListWithContextMenu schema={schema} section={section} detailLinks={data.detail.links} />
    )
  }
  else
    return <ListWithContextMenu schema={schema} section={section} detailLinks={[]} />
}

const ListWithContextMenu = ({ schema, section, detailLinks }) => {
  const notifier          = useNotifier()
  const client            = useApolloClient()
  const { t, translator } = useGearsTranslation()
  const context           = useRenderContext()
  const { links }         = useLinks({...context, parent: "contextmenu"}, detailLinks)

  const handleIdClick = (id) => (e) => {
    const success = ()  => { notifier.message(t('view.menu.copy.clipboard.success', {id})) }
    const failure = err => { notifier.message(t('view.menu.copy.clipboard.failure', {error: err})) }

    navigator.clipboard.writeText(id).then(success, failure)
  }

  const createLinkOnClick = (link, index, row) => {
    const detailLink = detailLinks[index]
    const detailKey  = section.detail
    const detailId   = row.id
    const linkKey    = detailLink.key
    const linkKind   = detailLink.kind

    const variables = { detailKey, detailId, linkKey }
    const path      = "data.detail.instance.link.formValues"
    const query     = GET_DETAIL_FORMVALUES
    const request   = { query, variables }

    return (e) => {
      link.onClick(e,prepareLinkFormValues(client, notifier, request, path, linkKind))
    }
  }

  const handleListRowMenu = (row) => {
    // copy row id
    const detailKey = section?.detail
    const copyId    = row.id && {
      icon   : 'ContentCopy',
      onClick: handleIdClick(row.id),
      label  : detailKey ? t('view.menu.copy.label', {label: translator.toDetailTitle(detailKey).toLowerCase()}): t('view.menu.copy.id')
    }

    // go to detail
    const showDetail = detailKey && row.onClick && {
      icon   : 'ManageSearch',
      onClick: row.onClick,
      label  : t('view.menu.details', {label: translator.toDetailTitle(detailKey).toLowerCase()})
    }

    // go to processes
    const rowLinks = links.map((link, index) => ({...link, onClick: createLinkOnClick(link, index, row)}))
    return [copyId, showDetail, ...rowLinks].filter(x => x)
  }

  return (
    <MenuItemProvider menu={handleListRowMenu}>
      <ListContents schema={schema} section={section}/>
    </MenuItemProvider>
  )
}

const ListContents = ({ schema, section }) => {
  const {context}             = useRenderContext()
  const isDetail              = context == "detail"
  const detail                = section.detail
  const listKey               = section.key
  const navigate              = useNavigate()
  const params                = useParams()
  const detailKey             = params.detail
  const parentListId          = params.id
  const currentDetail         = isDetail ? { id: params.id, detail: params.detail } : undefined
  const [columns, setColumns] = useState([])
  const { language }          = useLocale()
  const { translator }        = useTranslator()
  const notifier              = useNotifier()

  const toListLabel = (columnKey, columnLabel) => translator.translate(
    `${schema.translationBaseKey}.labels.${columnKey}`,
    { user: true, fallback: columnLabel, format: F.toTitleCase }
  )

  useEffect( () => {
    const columns = section.fields.map(column => {
      const headerName   = toListLabel(column.key, column.label)
      const displayProps = getDisplayPropsFromLabel(headerName)
      return {
        ...column,
        headerName: toListLabel(column.key, column.label),
        field: column.key,
        cellProps: { sx: { whiteSpace: 'nowrap', verticalAlign: "top" } },
        displayProps
      }
    }
    )
    setColumns(columns)
  },[language])

  const request = {
    variables: isDetail ? { detailKey, listKey, parentListId } : { key: listKey },
    query:     isDetail ? GET_DETAIL_LIST_DATA : GET_LIST_DATA,
    path:      isDetail ? 'data.detail.instance.list.data'  : 'data.list.data',
    countPath: isDetail ? 'data.detail.instance.list.count' : 'data.list.count'
  }

  const toTableRow = (row) => toListRow({translator, notifier, row, columns, detail, section, currentDetail, navigate})

  return <LargeTable
    disableFilter={!section.searchable}
    request={request}
    columns={columns}
    toTableRow={toTableRow}
  />
}

export default ListTable
