import Elevate from 'components/Elevate'
import DetailTable  from 'components/view/DetailTable'
import RenderContextProvider from 'contexts/RenderContext'

const DetailSection = ({schema, section}) => (
  <Elevate sx={{height: 'auto', maxHeight: '100%', maxWidth: '100%', width: 'min-content'}}>
    <RenderContextProvider context="group">
      <DetailTable schema={schema} section={section}/>
    </RenderContextProvider>
  </Elevate>
)

export default DetailSection
