import { addRowAt }              from 'utils/multiple-utils';
import { useTranslation }        from 'react-i18next';
import { Tooltip, IconButton }   from '@mui/material';
import { Add as AddIcon }        from '@mui/icons-material';
import PropTypes                 from "prop-types"
import _                         from 'lodash';
import { useValues }             from 'hooks/values';
import { useFieldInfo }          from 'hooks/field';
import { setStepIconTabIndices } from 'utils/task-utils';

const AddRowButton = ({rows, id, setSelected, selected}) => {
  const { augProps, info } = useFieldInfo()
  const { createValues }   = useValues()
  const { t }              = useTranslation()

  function getNewRow() {
    const row = {...createValues(info.rpath + "[0]", false), __original_index: undefined }
    return row
  }

  const handleClick = (e) => {
    const at = id + 1
    const newRows = addRowAt(rows, getNewRow(), at)

    augProps.setValue(newRows)
    const newSelected = selected.map(id => id >= at ? id+1 : id) // adjust all ids higher than id
    setSelected(newSelected)
    setStepIconTabIndices(1000)
  }

  const title    = t('multiple.addrow')
  return (
    <Tooltip disableInteractive title={title}>
      <span>
        <IconButton tabIndex={-1} id={'row-' + id + '-add-button'} size="small" onClick={handleClick} >
          <AddIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

AddRowButton.propTypes = {
  rows       : PropTypes.array.isRequired,
  id         : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default AddRowButton
