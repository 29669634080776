import { mformat, setToStartOfNextWeek, setToStartOfWeek } from "utils/date"
import moment from 'moment-business-days'

export const windowConfig = {
  'day': {
    headers: [{ unit: 'day', format: 'MMMM Do',sticky: true }, { unit: 'hour', format: 'H:mm', sticky: true }],
    fitWidth: true,
    columnUnit: 'hour',
    columnOffset: 1,
    magnetUnit: "minute",
    magnetOffset: 15,
  },
  'week-day': {
    headers: [{ unit: 'week', format: '[week] WW', sticky: true }, {unit: 'day', format: 'dd'}  ],
    fitWidth: true,
    columnUnit: 'day',
    columnOffset: 1,
    magnetUnit: "hour",
    magnetOffset: "6"
  },
  'week': {
    headers: [{ unit: 'week', format: '[week] WW', sticky: true } ],
    fitWidth: true,
    columnUnit: 'week',
    columnOffset: 1,
    magnetUnit: "day",
    magnetOffset: 1
  },
  'week-medium': {
    headers: [{ unit: 'week', format: '[week] WW', sticky: true } ],
    fitWidth: true,
    columnUnit: 'week',
    columnOffset: 1,
    magnetUnit: "day",
    magnetOffset: 1
  },
  'week-large': {
    headers: [{ unit: 'week', format: 'WW', sticky: true } ],
    fitWidth: true,
    columnUnit: 'week',
    columnOffset: 1,
    magnetUnit: "day",
    magnetOffset: 1
  },
  'month': {
    headers: [{ unit: 'month', format: 'MMM', sticky: true } ],
    fitWidth: true,
    columnUnit: 'month',
    columnOffset: 1,
    magnetUnit: "week",
    magnetOffset: 1
  },
}

export function createWindowConfig(from, to) {
  const diff = to.diff(from, 'weeks')

  switch (true) {
    case diff > 53: return windowConfig["month"]
    case diff > 16: return windowConfig["week-large"]
    case diff > 4: return windowConfig["week-medium"]
    default: return windowConfig["week-day"]
  }
}

export function logTasks(tasks) {
  const taskstr = tasks.map(task => `${task.label}: ${mformat(task.from)} ==> ${mformat(task.to)}`).join('\n')
  console.log("tasks: \n%s", taskstr)
}

export function createConfig(resources, tasks) {
  function createRows(config, view) {
    switch (view) {
      case "resource":
        return toSvelteResourceCentricRows(config.resources, config.originalTasks)

      case "task":
        return toSvelteOrderCentricRows(config.resources, config.originalTasks)
    }
  }

  function createTasks(config, view) {

    const tasks = config.originalTasks.map(task => ({...task}))
    switch (view) {
      case "resource":
        const weekTasks = createWeekTasks(config.resources, config.originalTasks)
        return [...tasks, ...weekTasks]

      case "task":
        return tasks

      default:
        return tasks
    }
  }

  function setView(config, view) {
    config.view  = view
    config.rows  = createRows(config, view)
    config.tasks = createTasks(config, view)
  }

  function createWindowViewConfig(config) {
    return createWindowConfig(config.from, config.to)
  }

  const from   = setToStartOfWeek(moment()).subtract(1, "week")
  const to     = setToStartOfNextWeek(from.clone()).add(3, "weeks")
  const config = {
    originalTasks: tasks,
    resources,
    gantt: null,
    from,
    to
  }

  config.createWindowConfig = () => createWindowViewConfig(config)
  config.setView = (view) => setView(config, view)
  config.setView("resource")
  config.ganttGet = (attribute) => config.gantt['$$'].ctx[config.gantt['$$'].props[attribute]]

  return config
}


export function handleSvelteData({result, createRows, setData, ops, ops: {notifier, setError, setLoading}}){
  handleSvelteResult(result, (data) =>
    createRows(data.list.schema, data.list.data)
      .then(
        setData,
        error => {
          notifier.error(error)
          console.error(error)
          setError(true)
          setLoading(false)
        }
      ),
    ops
  )
}

const handleSvelteResult = (result, callback, {setError, setLoading}) => {
    if (!result.loading) {
      if (result.error) {
        console.error("GraphQL error: %o", result.error)
        setError(true)
        setLoading(true)
      } else {
        callback(result.data)
      }
    }
  }

export function toBusinessDayInfo(resource) {
    // resource stats
  const hoursPerWeek = resource.gears.capacity  // in hours
  const hoursPerDay  = hoursPerWeek/5           // per working day

  if (hoursPerDay > 24)
    window.alert("Resource '" + resource.label + "' has more than 24 working hours per day.")

  // start business day at 8 AM.
  const startAt = {hour:8,minute:0,second:0,millisecond:0}
  const end     = moment().set(startAt).add(hoursPerDay, 'hours')

  const toEnd = (aMoment) => {
    const m = aMoment.clone().set(startAt)
    if (m.isAfter(aMoment))
      m.set(m.prevBusinessDay().toObject())
    m.add(hoursPerDay, 'hours')

    if (m.isBefore(aMoment)) {
      m.set(startAt)
      m.add(hoursPerDay, 'hours')
    }

    return m
  }

  return {
    toEnd,
    startAt,
    hoursPerWeek,
    hoursPerDay
  }
}

export function calculateFirstWeekHourDemand(task, resource) {
  const sameWeek = task.to.isoWeek() == task.from.isoWeek() && task.to.year() == task.from.year()

  if (sameWeek) // assumes that the end of the task falls on a business day
    return task.gears.hours
  else {
    const {toEnd, hoursPerDay, startAt} = toBusinessDayInfo(resource)
    const millisecondsPerHour   = 3600000
    const millisecondsPerDay    = hoursPerDay * millisecondsPerHour

    const counter = task.from.clone()
    const week    = counter.isoWeek()
    var duration  = 0

    // set to start of business day if needed
    if (counter.isBefore(counter.clone().set(startAt)))
      counter.set(startAt)

    // get difference until end of business day
    const end = toEnd(counter)
    if (counter.isBusinessDay())
      duration += end.diff(counter)

    // count hours until the end of the week
    counter.nextBusinessDay()
    while (counter.isoWeek() == week) {
      duration += millisecondsPerDay
      counter.nextBusinessDay()
    }

    return duration / millisecondsPerHour
  }
}

export function calculateToDate(task, resource) {
  // task stats
  const {toEnd, hoursPerDay, startAt} = toBusinessDayInfo(resource)
  const millisecondsPerHour   = 3600000
  const millisecondsPerMinute = 60000
  const millisecondsPerSecond = 1000
  const millisecondsPerDay    = hoursPerDay * millisecondsPerHour

  const toInt                = (float) => Number(Math.trunc(float))
  const createDurationObject = (durationInHours) => {
    // make duration human readable
    var duration       = durationInHours * millisecondsPerHour
    const days         = toInt(duration / millisecondsPerDay)
    duration          -= days * millisecondsPerDay
    const hours        = toInt(duration/millisecondsPerHour)
    duration          -= hours * millisecondsPerHour
    const minutes      = toInt(duration/millisecondsPerMinute)
    duration          -= minutes * millisecondsPerMinute
    const seconds      = toInt(duration/millisecondsPerSecond)
    duration          -= seconds * millisecondsPerSecond
    const milliseconds = duration

    return {
      days,
      hours,
      minutes,
      seconds,
      milliseconds
    }
  }


  var duration = task.gears.hours * millisecondsPerHour
  const to     = task.from.clone()

  // make sure it is a business day
  if (!to.isBusinessDay()) {
    to.nextBusinessDay()
    to.set(startAt)
  }

  // set time forward if business day has not started yet
  if (to.isBefore(to.clone().set(startAt)))
    to.set(startAt)

  // use up all remaining time of current business day
  const end = toEnd(to)
  if (to.clone().add(duration, 'milliseconds').isAfter(end)) {
    const diff = end.diff(to)
    duration -= diff
    to.nextBusinessDay()
    to.set(startAt)
  }

  // add business days
  const days  = toInt(duration / millisecondsPerDay)
  duration -= days * millisecondsPerDay
  to.set(to.businessAdd(days).toObject())

  // add remainder
  to.add(duration, 'milliseconds')

  return {
    to,
    ...createDurationObject(task.gears.hours)
  }
}

export function createSvelteTasks(schema, rows, resources) {
  const updateTasks = (tasks) => {
    tasks.forEach(updateTask)
    return tasks
  }

  const updateTask = (task) => {
    const resource      = resources.find(resource => resource.id == task.gears.resourceId)
    task.label          = `Order ${task.gears.orderId}`
    task.resourceId     = `${task.gears.resourceId}-${task.gears.orderId}`  // <resource id>-<order id> is unique for a gantt row
    task.enableDragging = false
    task.from           = moment(task.from)

    const { to,
      days,
      hours,
      minutes,
      seconds
    } = calculateToDate(task, resource)

    task.to             = to
    task.gears.duration = { days, hours, minutes, seconds }
  }

  const resourceIds = new Set(resources.map(resource => resource.id))

  return toSvelteRows(schema, rows,
    [
      'order',
      'duration_hours',
      'duration_days',
      'product',
      'drawing_number',
      'client',
      'resource_id',
      'startdate'
    ],
    {
      'order'         : 'gears.orderId',
      'resource_id'   : 'gears.resourceId',
      'startdate'     : 'from',
      'duration_hours': 'gears.hours',
      'duration_days' : 'gears.days',
      'drawing_number': 'gears.drawingNumber',
      'product'       : 'gears.name',
      'client'        : 'gears.client',
    }
  )
  // only allow tasks that has a shown resource
  .then(rows => rows.filter(row => resourceIds.has(row.gears.resourceId))) // only allow tasks that has a shown resource
  .then(updateTasks)
}

export function createSvelteResources(schema, rows) {
  return toSvelteRows(schema, rows,
    [
      'name',
      'capacity',
      'show',
      'calculate'
    ],
    {
      'name': 'label',
      'capacity': 'gears.capacity',
      'show': 'gears.show',
      'calculate': 'gears.calculate'
    }
  ).then(resources => resources.filter(resource => resource.gears.show))
}

function toSvelteRows(schema, rows, selection = [], nameMap = {}) {
  const select  = new Set(selection)
  const entries = schema.fields
    .reduce((entries, field, index) => {
      if (select.has(field.key))
        entries.push([field.key, index+1])
      return entries
    }, [])

  if (entries.length != select.size){
    const msg = "Not all requires fields are present in the resource data. Missing: " +
      JSON.stringify(Array.from(select).filter(x => !entries.some(entry => entry[0] == x)))

    return Promise.reject(msg)
  } else {
    const map        = [['id', 0], ...entries.map(([key, i]) => [nameMap[key] || key, i])]
    const toResource = row => map.reduce((resource, [key, i]) => { _.set(resource, key, row[i]); return resource }, {})
    const resources  = rows.map(toResource)

    return Promise.resolve(resources)
  }
}

export function toSvelteResourceCentricRows(resources, tasks) {
  function createResourceRow(resource) {
    const resourceTasks = tasks.filter(task => task.gears.resourceId == resource.id)
    const byOrder  = _.groupBy(resourceTasks, "gears.orderId")

    const children = Object.values(byOrder)
      .flatMap(tasks => tasks[0])
      .map(task => ({
        id: `${task.gears.resourceId}-${task.gears.orderId}`,
        label: task.label
      }))

    return {
      ...resource,
      expanded: false,
      children: children.length ? children : undefined
    }
  }
  const rows = resources.map(createResourceRow)

  return [...rows.filter(row => row.children?.length), ...rows.filter(row => !row.children?.length)]
}

export function toSvelteOrderCentricRows(resources, tasks){
  function createOrderRow(orderId, tasks) {
    const task            = tasks[0]
    const taskResourceIds = new Set(tasks.map(task => task.gears.resourceId))
    const taskResources   = resources.filter(resource => taskResourceIds.has(resource.id))  // this keeps the order of the resources consistent
    const children        = taskResources.map(resource => ({...resource, id: `${resource.id}-${task.gears.orderId}`}))

    return {
      id: `order-${task.gears.orderId}`,
      label: task.label,
      expanded: false,
      children: children.length ? children : undefined
    }
  }

  const byOrder = _.groupBy(tasks, "gears.orderId")
  return Object.entries(byOrder)
    .map(([orderId, tasks]) => createOrderRow(orderId, tasks))
}


export function getResourceUsage(task){
  return parseFloat(task.gears.hours)
}

export function createWeekTasks(resources, tasks) {

  function createTasksPerWeek({resource, tasksByWeek}){
    const createConsumers = (index) => tasksByWeek[index]
      .tasks
      .map(task => ({
        remainder: getResourceUsage(task),
        state: "planned",
        task
      }))

    const hasConsumers = (year, week, index) => index < tasksByWeek.length && tasksByWeek[index].year == year && tasksByWeek[index].week == week
    const getConsumers = (year, week, index) => hasConsumers(year, week, index) ? createConsumers(index) : []
    const getWeekDate  = (index) => tasksByWeek[index].tasks[0].from.clone().startOf("isoWeek")

    const consumers = []
    const date      = getWeekDate(0)
    var index       = 0

    const inSameWeek = (d1, d2) => d1.isoWeek() == d2.isoWeek() && d1.year() == d2.year()
    const computeConsumption = (consumer, date) => {
      if (inSameWeek(consumer.task.from, date) && !inSameWeek(consumer.task.from, consumer.task.to))
        return calculateFirstWeekHourDemand(consumer.task, resource)
      else
        return consumer.remainder
    }

    const weekTasks = []
    // while there are upcomming tasks (index < tasksByWeek) or tasks are still running (consumers.length)
    while (index < tasksByWeek.length || consumers.length) {
      const week = date.isoWeek()
      const year = date.year()

      // look for new consumers
      const newConsumers = getConsumers(year, week, index)
      for (let i = 0; i < newConsumers.length; i++)
        consumers.push(newConsumers[i])

      if (hasConsumers(year, week, index))
        index++

      // compute how much resource capacity is used the consumers
      const finished = []
      var capacity   = parseFloat(resource.gears.capacity)
      while (consumers.length && capacity > 0) {
        const consumer    = consumers[0]
        consumer.state    = "running"
        const consumption = computeConsumption(consumer, date)
        capacity         -= consumption

        if (consumption > consumer.remainder)
          window.alert("Assertion failed: consumption > consumer.remainder")

        if (capacity < 0)
          consumer.remainder -= consumption + capacity
        else if (consumption < consumer.remainder) {
          consumer.remainder -= consumption
          break
        } else {
          consumer.state = "finished"
          consumer.remainder = 0
          finished.push(consumer.task)
          consumers.shift()
        }
      }

      const running = consumers.filter(consumer => consumer.state == "running").map(consumer => consumer.task)
      const planned = consumers.filter(consumer => consumer.state == "planned").map(consumer => consumer.task)

      function getWeekState(planned, capacity) {
        switch(true){
          case planned.length > 0:                   return "high" // capacity should be < 0. when started tasks are planned they require overcapacity.
          case capacity == resource.gears. capacity: return "none" // all capacity remains
          case capacity <= 0:                        return "full" // remaining capacity is zero
          default:                                   return "low"  // there is capacity left
        }
      }

      function getWeekClasses(state) {
        switch(state) {
          case "high": return ["gantt-week-high"]
          case "full": return ["gantt-week-full"]
          case "low" : return ["gantt-week-low"]
          case "none": return ["gantt-week-none"]
        }
      }

      // create the week task if needed
      const state    = getWeekState(planned, capacity)
      if (state != "none") {
        const weekTask = {
          from: date.clone(),
          to:   date.clone().add(1, "week").startOf("isoWeek"),
          label: `week ${week}`,
          id: `${resource.id}-${year}-${week}`,
          resourceId: resource.id,
          classes: getWeekClasses(state),
          gears: {
            resourceId: resource.id,
            type: "capacity",
            state,
            capacity: capacity < 0 ? 0 : capacity,
            demand: consumers.reduce((partialSum, consumer) => partialSum + consumer.remainder, 0),
            overcapacity: consumers
              .filter(consumer => consumer.state == "planned")
              .reduce((partialSum, consumer) => partialSum + consumer.remainder, 0),
            finished: finished,
            running : running,
            planned : planned
          },
          enableDragging: false
        }

        weekTasks.push(weekTask)
      }

      // increment the date
      if (consumers.length > 0) {
        date.add(1,"week")
      } else if (index < tasksByWeek.length) {
        date.set(getWeekDate(index).toObject())
      }
    }
    return weekTasks
  }

  function toWeekTasks(resourceId, tasks) {
    // get a sorted list of resource consumers per week
    function createCusmersPerWeek(tasks) {
      const tasksByWeek     = _.groupBy(tasks, (task) => `${task.from.year()}-${task.from.isoWeek()}`)
      const consumersByWeek = Object.values(tasksByWeek)
        .map(tasks => ({
          week: tasks[0].from.isoWeek(),
          year: tasks[0].from.year(),
          tasks: toSortedTasks(tasks)
        }))
      consumersByWeek.sort((w1,w2) => (w1.year - w2.year) || (w1.week - w2.week))

      return consumersByWeek
    }

    // sort tasks based on year and week
    function toSortedTasks(tasks) {
      const sTasks = [...tasks]
      const diff   = (aMoment, bMoment) => aMoment.valueOf() - bMoment.valueOf()
      sTasks.sort((t1, t2) => diff(t1.from, t2.from) || diff(t1.to, t2.to))
      return sTasks
    }

    const consumersByWeek = createCusmersPerWeek(tasks)
    const resource         = resources.find(resource => resource.id == resourceId)

    const weekTasks = createTasksPerWeek({resource, tasksByWeek: consumersByWeek})

    return weekTasks
  }


  function createWeekTasks(tasks) {
    const byResource = _.groupBy(tasks, "gears.resourceId")

    const weekTasks = Object
     .entries(byResource)
     .filter(([resourceId, tasks]) => {
       const resource = resources.find(resource => resource.id == resourceId)
       return resource.gears.calculate
     })
     .flatMap(([resourceId, tasks]) => toWeekTasks(resourceId, tasks))


    return weekTasks
  }

  return createWeekTasks(tasks)
}




/* ======================== testing functions ================================ */

function createRandomRow() {
  const rowId = Math.floor(Math.random() * 1000) + 1000
  return {
    "id": rowId,
    "label": "New row " + rowId,
  }
}


function createRandomTask(ganttRows) {
  function flattenRows(rows) {
    return rows.flatMap(row => [row, ...(Array.isArray(row.children) ? flattenRows(row.children) : []) ])
  }

  const taskId = Math.floor(Math.random() * 1000) + 1000
  const rows   = flattenRows(ganttRows)
  const rowNr  = Math.floor(Math.random() * 100) % rows.length
  const row    = rows[rowNr]

  const rand_h = (Math.random() * 10) | 0
  const rand_d = (Math.random() * 5) | 0 + 1
  const from   = time(`${6 + rand_h}:00`)
  const to     = time(`${6 + rand_h + rand_d}:00`)

  return {
    "id": taskId,
    "resourceId": row.id,
    "label": "New task " + taskId,
    from,
    to,
    "classes": "blue"
  }
}

function addRow(gantt) {
  const row = createRandomRow()
  gantt.updateRow(row)
}

function addTask(gantt, rows) {
  const task = createRandomTask(rows)
  gantt.updateTask(task)
}

