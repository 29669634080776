import ListTable from 'components/view/ListTable';
import Elevate from 'components/Elevate';

const ListPage = ({ schema, section }) => {
  return (
    <Elevate className='list-card'
      sx={{
        marginX: "auto",
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: "hidden",
        display: "flex", 
        height: "fit-content", 
      }}
    >
      <ListTable schema={schema} section={section} />
    </Elevate>
  )
}

export default ListPage
