import { TableCell, Checkbox } from '@mui/material';
import { useFieldInfo }        from 'hooks/field';
import { checkboxCellStyle }   from 'utils/theme';
import PropTypes               from "prop-types"

const MultipleTableRowSelectCell = ({setSelected, selected, id}) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  return (
    mode === "open"
    ? <TableCell key="selection" sx={checkboxCellStyle}>
        <MultipleRowCheckBox rowIndex={id} selected={selected} setSelected={setSelected} />
      </TableCell>
    : null
  )
}

export const MultipleRowCheckBox = ({selected, setSelected, rowIndex}) => {
  const handleChange = (e, value) => {
    if (value)
      setSelected([...selected, rowIndex])
    else
      setSelected(selected.filter(rowId => rowId !== rowIndex))
  }

  return <Checkbox
    tabIndex={-1}
    size="small"
    color="primary"
    checked={selected.includes(rowIndex)}
    onChange={handleChange}
    name="multiple"
  />
}

MultipleTableRowSelectCell.propTypes = {
  id         : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default MultipleTableRowSelectCell
