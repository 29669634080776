import SidebarLayout from 'components/layout/SidebarLayout';
import PermanentViewDrawer from 'components/view/drawer/PermanentViewDrawer';
import SlidingViewDrawer   from 'components/view/drawer/SlidingViewDrawer';
import { Link } from 'types/graphql';
import { ReactChildren } from 'types/react';
import ViewLinks from './drawer/DrawerLinks';
import { DashboardSidebarItems } from 'components/layout/dashboard/DashboardSidebar';
import { useLayoutContext } from 'hooks/layout';
import { useViewLinks } from 'hooks/link';
import { Box } from '@mui/material';
import { Center } from 'components/Center';
import Loader from 'components/Loader';
import { SidebarItemDataLink } from 'types/sidebar';
import { SidebarItems, SidebarList } from 'components/layout/sidebar/SidebarList';

const ViewLayout = ({children, links}: {children: ReactChildren, links: Link[]}) =>  {

  return Array.isArray(links) && links.length > 0
    ? <ViewLinkLayout children={children} links={links} />
    : children
}

const ViewLinkLayout = ({children, links}: {children: ReactChildren, links: Link[]}) => (
  <SidebarLayout
    name='view'
    withBanner={false}
    side='right'
    sidebarBody={<ViewSidebarBody links={links} />}
    sidebarProps={{
      shortcut: "BracketRight"
    }}
  >
    {children}
  </SidebarLayout>
)

const ViewSidebarBody = ({links}: {links: Link[]}) => {
  const {loading, links: resultLinks} = useViewLinks(links)

  if (loading) {
    return (
      <Box sx={{paddingTop: '30px', width: '100%', overflow: 'hidden'}}>
        <Center direction={"horizontal"}>
          <Loader size='small'/>
        </Center>
      </Box>
    )
  }
  else {
    const items = resultLinks.map(toSidebarItem)
    return <SidebarList items={items} />
  }
}

function toSidebarItem(link: any): SidebarItemDataLink {
  return {
    type: 'item',
    key: link.label,
    label: link.label,
    onClick: link.onClick,
    tip: link.tip || link.label,
    icon: link.icon,
    activeIcon: link.icon
  } 
}

export default ViewLayout

