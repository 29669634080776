import { createContext }                 from 'react';
import { useFormInfo }                   from 'hooks/form';
import merge                             from 'deepmerge'
import { createDefaultValue, toAuxPath } from 'utils/field-utils';
import { getPath, pruneEmpty }           from 'utils/utils';

export const ValuesContext = createContext();

const ValuesProvider = ({children}) => {
  const formInfo       = useFormInfo()

  function createValues(vpath = "", withSaved = true) {
    const auxPath        = toAuxPath(formInfo, vpath)
    const defaultValues  = getPath(formInfo.form.values, auxPath, {})
    const savedValues    = withSaved ? getPath(formInfo.form.saved, vpath, {}) : {}
    const prunedValues   = pruneEmpty(defaultValues)
    const prefillValues  = getPath(formInfo.prefill, auxPath, {})
    const frontendValues = createDefaultValue(formInfo, defaultValues, auxPath)

    const valueList = formInfo.kind == "PROCESS_FILL" && !vpath
      ? [defaultValues, frontendValues, prunedValues, prefillValues, savedValues]
      : [defaultValues, frontendValues, prunedValues, savedValues]

    const mergedValues = merge.all(valueList, { arrayMerge: overwriteMerge })

    return mergedValues
  }

  const values = createValues()
  console.log("Adding values to form: %o", pruneEmpty(values))

  return (
    <ValuesContext.Provider value={{values: values, createValues}}>
      {children}
    </ValuesContext.Provider>
  )
}

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

export default ValuesProvider;
