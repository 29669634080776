import { basedOnEntries } from "utils/form-utils";
import { useFieldInfo } from "hooks/field";
import { toValueType } from "utils/field-utils";
import { useFormInfo } from "hooks/form";
import RenderTableCell from "components/render/RenderTableCell";
import { useNotifier } from "hooks/notification";
import Types from "types/types";

const RenderTableRowCells = ({rowIndex, basedOn}) => {
  const fieldInfo   = useFieldInfo()
  const formInfo    = useFormInfo()
  const path        = fieldInfo?.info?.path || ""
  const basedOnInfo = (index, value) => ({ path: path + `.basedon[${index}]`, type: toValueType(value) })
  const notifier    = useNotifier()

  const createEntries = (value) => {
    if (Types.isObject(value))
      return basedOnEntries(value)

    if (!Types.isStructure(value))
      return [['key', value]]

    if (Array.isArray(value) && value.length == 2)
      return [value]

    notifier.error("Not a suitable format provided for rendering a table")
    console.error("Not a suitable format provided for rendering a table: %o", value)
    return []
  }

  return createEntries(basedOn)
    .map(([_, value], index) => (
      <RenderTableCell
        basedOn={value}
        style={{whiteSpace: 'nowrap'}}
        key={index}
        rowIndex={rowIndex}
        columnIndex={index}
      />
    )
  )
}

export default RenderTableRowCells
