import { createTheme, darken, lighten } from '@mui/material';
import globalTheme from 'theme/theme';
import { getServerUrl } from 'utils/utils';
import _ from 'lodash';
import Types from 'types/types';
import xlritTheme from '../themes/xlrit.json';
import merge from 'deepmerge';

export const toLocalizedMuiTheme = (theme, locale) => {
  return createTheme(theme, locale.muiLocale)
}

export function createDefaultTheme(locale) {
  const theme = toLocalizedMuiTheme(globalTheme, locale)

  const amendment = { 
    palette: {
      contrast: theme.palette.grey[100],
      background: {
        default: "#ffffff",
      }
    },
    components: {
      title: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: 28
      }, 
      banner : {
        backgroundColor : theme.palette.primary.main,
        color : "#ffffff",
        elevation: 15,
        height: "48px"
      },
      toolbar : {
        paddingRight: "24px",
        height: "48px !important",
        minHeight: "0 !important"
      },
      sidebar: {
        width: "256px",
        //background: "#f1f1f1"
        background: "#fbfafd"
      },
      badge : {
        color : theme.palette.secondary.main,
        backgroundColor : theme.palette.secondary.light
      },
      logo: {},
      heading : {
        color: "#203e60"
      }
    }
  }

  return merge.all([theme, amendment])
}

export function isGearsThemes(themes) {
  return themes && Types.isObject(themes) &&
    themes.hasOwnProperty('themes') &&
    Array.isArray(themes.themes)
}

export function isGearsTheme(theme) {
  return theme && Types.isObject(theme) &&
    theme.hasOwnProperty('theme') &&
    theme.hasOwnProperty('key')   &&
    theme.hasOwnProperty('label') &&
    theme.hasOwnProperty('logo')
}

function getLogoUrl(filename) {
  return getServerUrl('logos/' + filename)
}

function toMuiLogoTheme(gearsTheme) {
  const adjustSrc = (src,filename) => src ? src : filename ? getLogoUrl(filename) : undefined

  const logoSmall = gearsTheme?.logoSmall
    ? { logoSmall: {
          ...gearsTheme?.logoSmall, 
          src: adjustSrc(gearsTheme?.logoSmall?.src, gearsTheme?.logoSmall?.filename)
        }
      }
    : undefined

  const logo = { logo: {
    ...gearsTheme?.logo,
    src: adjustSrc(gearsTheme?.logo?.src, gearsTheme?.logo?.filename)
    }
  }

  if (!logo.logo.src)
    console.error("No logo url provided for theme: %o", gearsTheme?.key)

  return {
    "components": {
      ...logo,
      ...logoSmall
    }
  }
}

// a resolver for when variables are used in the theme, with the format: $variable_name
function toResolvedUserTheme(theme, variables) {
  if (Types.isObject(theme)) {
    const entries = Object.entries(theme).map(([key, value]) => [key, toResolvedUserTheme(value, variables)])
    return Object.fromEntries(entries)
  } else if (_.isString(theme)) {
    // resolve a variable
    if (theme.startsWith("$")) {
      const variable = theme.substring(1)
      if (variables.hasOwnProperty(variable))
        return variables[variable]
      else
        console.error("Could not resolve theme variable: %o", variable)
    }
  } else if (Array.isArray(theme)) {
    return theme.map(value => toResolvedUserTheme(value, variables))
  }

  return theme
}

function toHeadingTheme(colorTheme, userTheme) {
  const primary             = colorTheme.palette.primary
  const defaultHeadingColor = primary.dark
  const defaultHeadingTheme = {
    components: {
      heading: {
        color: defaultHeadingColor
      }
    }
  }

  const userHeadingTheme = {
    components: {
      heading: userTheme.components?.heading || {}
    }
  }

  const headingTheme     = merge.all([defaultHeadingTheme, userHeadingTheme])
  const headingStyle     = headingTheme.components.heading

  return {
    ...headingTheme,
    typography: {
      h1: headingStyle,
      h2: headingStyle,
      h3: headingStyle,
      h4: headingStyle,
      h5: headingStyle,
      h6: headingStyle
    }
  }
}

// This results in a Mui theme color palette, where it's type is 'primary' or 'secondary'
function toPaletteColorTheme(defaultTheme, userTheme, type) {
  const getColor = (theme) => theme?.palette?.[type]?.main || theme?.components?.[type]
  const hasColor = (theme) => Boolean(theme)

  if (hasColor(userTheme)) {
    const color        = getColor(userTheme)
    const defaultColor = {
      palette: { 
        [type]: {
          light: lighten(color, 0.7),
          main: color,
          dark: darken(color, 0.7)
        }
      }
    }
    const userColor = {
      palette: {
        [type]: userTheme?.palette?.[type] || {}
      }
    }

    return merge.all([defaultColor, userColor]) 
  } else {
    return {
      palette: {
        [type]: defaultTheme.palette[type] 
      }
    }
  }
}

// This results in the primary and secondary color palettes, in an MUI theme structure
function toColorTheme(defaultTheme, userTheme) {
  return merge.all([
    toPaletteColorTheme(defaultTheme, userTheme, 'primary'), 
    toPaletteColorTheme(defaultTheme, userTheme, 'secondary'),
  ])
}

function toMuiThemes(locale, themes) {
  const defaultTheme = createDefaultTheme(locale)

  return themes.map(gearsTheme => {
    const logo         = toMuiLogoTheme(gearsTheme)
    const variables    = Types.isObject(gearsTheme.variables) ? gearsTheme.variables : {}
    const userTheme    = toResolvedUserTheme(gearsTheme.theme, variables)
    const colorTheme   = toColorTheme(defaultTheme, userTheme)
    const headingTheme = toHeadingTheme(colorTheme, userTheme)
    const theme        = merge.all([defaultTheme, colorTheme, headingTheme, userTheme, logo])

    return {
      "key"  : gearsTheme.key,
      "label": gearsTheme.label,
      "theme": theme
    }
  })
}

export function selectThemeName(themes, themeName, gearsTheme, loading) {
  if (hasTheme(themes, themeName))
    return themeName

  if (loading)
    return 'default'

  if (isGearsThemes(gearsTheme) && gearsTheme.default)
    return gearsTheme.default

  if(isGearsTheme(gearsTheme))
    return gearsTheme.key

  if (isGearsThemes(gearsTheme) && gearsTheme.default)
    return gearsTheme.default

  return 'default'
}


export function hasTheme(themes, key) {
  return key && themes.some(theme => theme.key == key)
}

export function selectTheme(themes, key) {
  const theme = themes.find(theme => theme.key == key)
  if (!theme)
    console.error("Could not select theme with key: %o", key)

  return theme ? theme.theme : selectTheme('default').theme
}

function addTheme(themes, theme) {
  if (isGearsTheme(theme))
    themes.push(theme)
  else
    console.error("The following is not a theme: %o", theme)
}

function loadThemes() {
  //const requireContext = require.context('../themes', false, /\.json$/);
  //const themes = [];
  //requireContext.keys().forEach((key) => {
  //  const obj = requireContext(key);
  //  themes.push(obj)
  //});
  //return themes;

  return [xlritTheme]
}

const localThemes = loadThemes()

export function createCustomThemes(locale, gearsTheme, addProcessIcons) {
  var themes = []
  if (isGearsThemes(gearsTheme))
    gearsTheme.themes.forEach(theme => addTheme(themes, theme))
  else if (isGearsTheme(gearsTheme))
    themes.push(gearsTheme)

  localThemes.forEach(theme => addTheme(themes, theme))

  if (addProcessIcons) {
    themes.forEach(theme => {
      const themeProcesses = theme?.components?.processes || {}
      const globalProcesses = gearsTheme?.processes || {}
      const processes = merge.all([globalProcesses, themeProcesses])
      if (!theme.theme.components)
        theme.theme.components = {}
      
      theme.theme.components.processes = processes
    })
  }

  return toMuiThemes(locale, themes)
}

export const checkboxCellStyle = {
  width: "1%",
  padding: 0,
  paddingLeft: "6px"
}

