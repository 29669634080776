import InputSelectField              from "components/fields/InputSelectField";
import InputMultipleSelectFieldLocal from "components/fields/InputMultipleSelectFieldLocal";
import { useFieldInfo }              from "hooks/field";

const InputMultipleSelectField = (props) => {
  const { info } = useFieldInfo()

  if (info.numOptions < 100 && !info.isDepending)
    return <InputMultipleSelectFieldLocal {...props} />
  else
    return <InputSelectField {...props} selectProps={{mode: 'multiple', valueType: 'options'}} />
}

export default InputMultipleSelectField
