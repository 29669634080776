import { Box }                      from '@mui/material';
import {
  deleteSelectedRows,
  copySelectedRows }                from 'utils/multiple-utils';
import { CopyButton, DeleteButton } from 'components/fields/multiple';
import { useTranslation }           from 'react-i18next';
import { useFieldInfo }             from 'hooks/field';
import { setStepIconTabIndices }    from 'utils/task-utils';
import React from 'react';

const MultipleTableHeaderActions = ({selected, setSelected, rows}) => {
  const { t }              = useTranslation()
  const { augProps, info } = useFieldInfo()
  const mode               = info.field.mode

  const handleClickCopy = (event) => {
    const at = (selected.length ? Math.max(...selected) : 0) + 1
    augProps.setValue(copySelectedRows(rows, selected, at))
    setStepIconTabIndices(1000)
  }

  const handleClickDelete = (event) => {
    augProps.setValue(deleteSelectedRows(rows, selected))
    setSelected([])
  }

  return (
    mode === "open" && selected.length
    ? <React.Fragment> 
        <CopyButton
          id='rows-copy-button'
          title={t('multiple.copyrows')}
          onClick={handleClickCopy}
        />
        <DeleteButton
          id='rows-delete-button'
          disabled={selected.length === rows.length}
          disabledTitle={t("multiple.disabled.deleteallrows")}
          title={t('multiple.deleterows')}
          onClick={handleClickDelete}
        />
      </React.Fragment>
    : null
  )
}

export default MultipleTableHeaderActions
