import { copySelectedRows }      from 'utils/multiple-utils';
import { CopyButton }            from 'components/fields/multiple';
import { useTranslation }        from 'react-i18next';
import { useFieldInfo }          from 'hooks/field';
import PropTypes                 from "prop-types"
import { setStepIconTabIndices } from 'utils/task-utils';

const CopyRowButton = ({rows, id, selected, setSelected}) => {
  const { t }        = useTranslation()
  const { augProps } = useFieldInfo()

  const handleClick = (event) => {
    const at = id + 1
    const newRows = copySelectedRows(rows, [id], at)
    const newSelected = selected.map(id => id >= at ? id+1 : id) // adjust all ids higher than id
    augProps.setValue(newRows)
    setSelected([...newSelected, ...(selected.includes(at) ? [at] : [])])

    setStepIconTabIndices(1000)
  }

  return <CopyButton id={'row-' + id + '-copy-button'} title={t('multiple.copyrow')} onClick={handleClick} />
}

CopyRowButton.propTypes = {
  rows       : PropTypes.array.isRequired,
  id         : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default CopyRowButton
