import LambdaComponent           from 'components/LambdaComponent';
import { createContext, useRef } from 'react';

export const CustomRefContext = createContext();

const CustomRefProvider = ({children}) => {
  const ref        = useRef()
  const setCurrent = (value) => ref.current = value
  const getCurrent = () => ref.current

  return (
    <CustomRefContext.Provider value={{setCurrent, getCurrent}}>
      <LambdaComponent props={{setCurrent, getCurrent}}>
				{children}
			</LambdaComponent>
    </CustomRefContext.Provider>
  )
}

export default CustomRefProvider;
