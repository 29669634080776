import { useConfig } from 'hooks/config';
import { useLocale } from 'hooks/locale';
import { useRenderContext } from 'hooks/render';
import { Currency, GearsCurrency } from 'types/types';
import { toDisplayValue } from 'utils/render';

import { Typography } from '@mui/material';

export const RenderCurrency = ({value}: {value: GearsCurrency}): JSX.Element => {
const { props: {sx} }    = useRenderContext();
const {language, locale} = useLocale()
const {decimalSetting}   = useConfig()

  const defaultSx = { fontSize: 14, color: "text.secondary" };
  const localSx = sx || defaultSx;

  /* The print styling should be identical to that of RenderSimpleValue. You cant use it directly due to a circular dependency */
  return (
    <Typography sx={{ ...localSx, whiteSpace: "pre" }}>
      {toDisplayValue(value.__value, {
        decimalSetting: value.__decimalSeparator || decimalSetting,
        currency: value.__currency,
        type: "currency",
        language, locale
      })
      }
    </Typography>
  )
}

export function currencyFormatter(value: number, currency?: Currency, locale?: string) {
  return new Intl.NumberFormat(locale?.replace("_", "-") || 'nl-NL', {
    style: 'currency',
    currency: currency || "EUR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }
  ).format(value);
}

