import { Box, Card, Typography} from '@mui/material';

/*images*/
import ErrorIcon                from '@mui/icons-material/Error'
import WarningIcon              from '@mui/icons-material/Warning'
import CloudIcon                from '@mui/icons-material/CloudOff'
import InfoIcon                 from '@mui/icons-material/Info'
import { useTheme } from '@mui/material/styles';


// Note that this mapping include all mappings in ReportLevel
const NotificationType = Object.freeze({"ERROR":1, "WARNING":2, "INFO":3, "CLOUD":4})

/** This component visualises notifications for the user. Different error types are supported, e.b., 'error', 'warning', 'info', etc. */
function Notification(props) {
  // ---------- preconditions ----------
  if (!props.contents && !props.message)
    throw new Error("A notification requires a message.")

  if (!Object.values(NotificationType).includes(props.type))
    throw new TypeError(`An unknown level was provided: ${props.type}`)
  // -----------------------------------

  const constructMessage = (props) => {
    return (
      <Box>
        <Typography sx={{fontSize: 16}}>
          {props.message}
        </Typography>
        <Typography sx={{fontSize: 14}}>
          {props.details}
        </Typography>
      </Box>
  )}

  const toMessage = (props) => 
    props.contents !== undefined ? props.contents : constructMessage(props)

  const color = useNotificationColor(props.type)
  return (
    <Card sx={{padding: "10px", background: color.light, border: '1px solid', borderColor: color.dark, ...props.sx}} raised={true}>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <IconContainer>
          <Icon type={props.type}/>
        </IconContainer>
        <Box sx={{flexGrow: 1}}>
          {toMessage(props)}
        </Box>
      </Box>
    </Card>
  )
}

const Icon = ({type}) => {

  switch (type) {
    case NotificationType.ERROR:   return <ErrorIcon/>
    case NotificationType.INFO:    return <InfoIcon/>
    case NotificationType.WARNING: return <WarningIcon/>
    case NotificationType.CLOUD:   return <CloudIcon/>
    default: {        
      console.error("There is no notification icon for type: " + JSON.stringify(type))
      return <ErrorIcon/>
    }
  }
}

const IconContainer = ({children}) => (
  <Box sx={{height: '30px', width: '30px'}}>
    {children}
  </Box>
)
import { createTheme }          from '@mui/material';

const defaultTheme = createTheme();

const useNotificationColor = (type) => {
  const currentTheme = useTheme()
  const theme = currentTheme ? currentTheme : defaultTheme

  switch (type) {
    case NotificationType.ERROR:   return theme.palette.error
    case NotificationType.INFO:    return theme.palette.info
    case NotificationType.WARNING: return theme.palette.warning
    case NotificationType.CLOUD:   return theme.palette.warning
    default: {        
        console.error("There is no notification icon for type: " + type)
        return theme.palette.error
      }
  }
}

export { Notification as default, NotificationType }
