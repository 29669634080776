import { useConfig } from 'hooks/config';
import { useLocale } from 'hooks/locale';
import { toDisplayValue } from 'utils/render';

import { RenderSimpleValue } from './RenderSimpleValue';

const RenderPeriodObject = ({value}: {value: any}): JSX.Element => {
  const { language, locale } = useLocale()
  const { decimalSetting }   = useConfig()

  
  // @ts-ignore
  return  <RenderSimpleValue value={toDisplayValue(value.duration, {language, locale, decimalSetting})}/>
}

export default RenderPeriodObject
