import { Crumb } from 'components/breadcrumbs/Breadcrumb';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import MuiIcon from 'components/MuiIcon';
import SearchField from 'components/SearchField';
import { F } from 'helpers/formatter';
import { useConfig } from 'hooks/config';
import { ProcessGroupBy, ProcessRendering } from 'hooks/process';
import { useProcessRenderContext } from 'hooks/render';
import { useTranslator } from 'hooks/translator';
import PropTypes from 'prop-types';
import * as React from 'react';
import { SetState } from 'types/react';

import { Box, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const ProcessOptionBar = () => {
  const {filter, setFilter, setCurrentFilter, groupBy, setGroupBy, processRendering, setProcessRendering, path, setPath} = useProcessRenderContext()
  const { t } = useTranslator()
  const {addProcessIcons} = useConfig()

  return (
    <Box sx={{ display: "flex", width: "100%", padding: "15px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ width: "400px" }}>
          <SearchField
            placeholder={t('filter.search', { variables: { item: t('filter.process') } })!}
            value={filter}
            setValue={setFilter}
            setCurrentValue={setCurrentFilter}
            autoFocus={true}
          />
        </Box>
        { addProcessIcons && processRendering != 'standard' 
          ? ( <Box sx={{marginTop: "20px"}}>
               <PathViewer path={path} setPath={setPath}/>
             </Box> )
            : null
        }
      </Box>
      <Box sx={{flexGrow: 1 }} />
      <Box sx={{ height: "fit-content", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
        <GroupByToggle   value={groupBy}          setValue={setGroupBy} />
        { addProcessIcons ? <ProcessRenderToggle value={processRendering} setValue={setProcessRendering} /> : null }
      </Box>
    </Box>
  )
}

type CrumbPath = {key: string, path: string} 

export const PathViewer = ({path, setPath}: {path: string, setPath: SetState<string>}) => {
  const { translator } = useTranslator()

  const pathList = path.split('.').reduce((list, key) => {
    if (list.length == 0)
      return [{key: key, path: key}]
    else
      return [...list, {key, path: `${list[list.length - 1]}.${key}`}]
  }, [] as CrumbPath[])

  const paths: CrumbPath[] = path == "" ? pathList : [{key: "", path: ""}, ...pathList]

  const crumbs: Crumb[] = paths.map(cp => ({
    key: cp.path,
    label: cp.path == "" ? "/" : translator.toCategory(cp.key)!,
    clickable: true,
    onClick: () => { setPath(cp.path) }
  }))

  return <Breadcrumbs chipped={true} crumbs={crumbs}/>
}

const ProcessRenderToggle = ({value, setValue}: {value: ProcessRendering, setValue: SetState<ProcessRendering>}) => {
  const {t} = useTranslator()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: ProcessRendering,
  ) => {
    if (value)
      setValue(value);
  };

  const control = {
    value: value,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        <ToggleButton value="standard" key="standard">
          <Tooltip title={t('processes.options.standard')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='GridViewRounded' />
            </Box>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="tiles" key="tiles">
          <Tooltip title={t('processes.options.tiles')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='ViewModuleRounded' />
            </Box>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="table" key="table">
          <Tooltip title={t('processes.options.table')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='ViewList' />
            </Box>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

const GroupByToggle = ({value, setValue}: {value: ProcessGroupBy, setValue: SetState<ProcessGroupBy>}) => {
  const {t} = useTranslator()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: ProcessGroupBy,
  ) => {
    console.log("value: %o", value)
    if (value != null)
      setValue(value);
  };

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup size="small" exclusive={true} value={value} onChange={handleChange} >
        <ToggleButton value="none" key="none">
          <Tooltip title={t('processes.options.none')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='DensityLarge' />
            </Box>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="category" key="category">
          <Tooltip title={t('processes.options.category')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='DensityMedium' />
            </Box>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="subcategory" key="subcategory">
          <Tooltip title={t('processes.options.subcategory')}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MuiIcon name='DensitySmall' />
            </Box>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}


export default ProcessOptionBar;
