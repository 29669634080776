import { Box, Card }    from '@mui/material';
import Elevate from 'components/Elevate';
import ListSectionTitle from 'components/view/ListSectionTitle';
import ListTable        from 'components/view/ListTable';

const ListSection = ({ schema, section }) => {
  return (
    <Elevate sx={{ 
       maxWidth: '100%',
       maxHeight: '100%',
       overflow: "hidden",
       display: "flex", 
       height: "fit-content", 
     }}>
      <Box sx={{ overflow: "hidden", maxHeight: '100%', maxWidth: '100%', display: 'flex', flexFlow: 'column' }}>
        <ListSectionTitle schema={schema} section={section} />
        <Box id='list-container' sx={{display: "flex", flexGrow: 1, overflow: "auto"}}>
        <ListTable schema={schema} section={section} />
        </Box>
      </Box>
    </Elevate>
  )
}

export default ListSection
