import { useNotifier } from 'hooks/notification';
import { useEffect } from 'react';
import { getIcon, hasIcon } from 'utils/icons';

import { SxProps } from '@mui/material';

type MuiIconProps = {
  name: string,
  iconLocation?: string,
  size?: string,
  sx?: SxProps
}

const MuiIcon = ({iconLocation, name, size: iconSize, sx}: MuiIconProps) => {
  const notifier = useNotifier()
  const size = iconSize || "24px"
  const Icon = getIcon(name)

  useEffect(() => {
    if (!hasIcon(name)) {
      const locationStr = iconLocation ? " at " + iconLocation : ""
      const nameStr     = ` '${name}'`
      notifier.error("The icons library does not contain an icons with key" + nameStr + locationStr )
    }
  }, [])

  return <Icon className='gears-icon'  sx={{ objectFit: 'contain', width: 'auto', height: size, ...sx}} />

}

export default MuiIcon