import { useState }                from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import Types from 'types/types';

const ContentPreview = ({content, fallback}) => {
  const [error, setError] = useState(false)
  const extension         = content.extension
  const style             = defaultStyles.hasOwnProperty(extension) ? defaultStyles[extension] : {}
  const defaultPreview    = fallback ? fallback : <FileIcon extension={extension} {...style} />

  if (!content.hasData || error || !previewable(content))
      return defaultPreview

  switch (true) {
    case content.type.startsWith("image/") :
      return (
        <img
          src={content.url}
          style={{ maxWidth: '100%', maxHeight: '100%', width: "100%", height: "100%", objectFit: 'scale-down' }} 
          onError={() => { console.error("Unable to load preview image for file: %o", content); setError(true) }}
        />
      )

    default:
      return defaultPreview
  }
}

export function previewable(contentLike) {
  const type = Types.toContent(contentLike)?.type || ""

  switch (true){
    case type.startsWith("image/"):
      return true
    default: 
      return false
  }
}

export default ContentPreview