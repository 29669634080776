import LargeTable from 'components/table/LargeTable'
import MenuItemProvider from 'contexts/MenuContext'
import { useNotifier } from 'hooks/notification'
import { GET_MESSAGES } from 'queries/messages'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import { convertToDate } from 'utils/utils'
import { Attachment as AttachmentIcon } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { useTranslator } from 'hooks/translator'
import Fallback from 'components/Fallback'
import { useLocale } from 'hooks/locale'

/** Render a Task. A Task has a Form in its contents. */
const MessageTable = (props)=>  {
  const navigate          = useNavigate()
  const theme             = useTheme()
  const { t, translator } = useTranslator()
  const {language}        = useLocale()

  const columns = [
    {
      headerName: t("menu.process"),
      field     : "processName",
      cellProps : {sx: {whiteSpace: 'nowrap'}}
    },
    {
      headerName: t("messages.sender"),
      field     : "sender.username",
      cellProps : {sx: {whiteSpace: 'nowrap'}}
    },
    {
      headerName: t("messages.subject"),
      field     : "subject",
      cellProps : {sx: {whiteSpace: 'nowrap'}}
    },
    {
      headerName: "",
      field     : "attachment",
      cellProps : {sx: { whiteSpace: 'nowrap', width: 0, padding: 0, paddingTop:"4px"}}
    },
    {
      headerName: "",
      field     : "time",
      cellProps : {sx: { whiteSpace: 'nowrap', width: 0, paddingLeft: "10px"}, align: "right"}
    },
  ]

  const request = {
    variables: {},
    query:     GET_MESSAGES,
    path:      'data.myMessages',
    countPath: 'data.myMessagesCount',
  }

  const toTableRow = (row) => {
    const cells   = {...row,
      processName: translator.toProcessTitle(row.processKey,row.processDefinition?.name, language),  
      attachment: row.attachments.length ? <AttachmentIcon fontSize='small'/> : null,
      time: row.publishedAt ? toMessageTime(row.publishedAt) : ""
    }
    const id      = row.id
    const onClick = () => navigate(`/gears/messages/${id}`)
    const rowProps = row.readAt ? {} : { background: theme.palette.grey[200], fontWeight: 700}

    return { cells, id, onClick, rowProps: rowProps }
  }

  return (
    <Fallback name='messages'>
      <MenuItemProvider menu={handleRowMenu}>
        <LargeTable
          filterType="local"
          request={request}
          columns={columns}
          toTableRow={toTableRow}
          tableSx={{ width: "100%" }}
        />
      </MenuItemProvider>
    </Fallback>
  )
}

const handleRowMenu = (props) => {
  const {id, ...row} = props
  const notifier     = useNotifier()
  const { t }        = useTranslation()

  const handleIdClick = (e) => {
    const success = ()  => { notifier.message(t('view.menu.copy.clipboard.success', {id})) }
    const failure = err => { notifier.message(t('view.menu.copy.clipboard.failure', {error: err})) }

    navigator.clipboard.writeText(row.id).then(success, failure)
  }

  return [
    {
      icon: 'ContentCopy',
      onClick: handleIdClick,
      label: t('view.menu.copy.id')
    },
    // go to message
    {
      icon   : 'ManageSearch',
      onClick: row.onClick,
      label  : t('show', {label: t("menu.message")})
    }
  ]
}

const isToday = (date) => {
  const today = new Date()

  return today.getDate() == date.getDate()  &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
}

const toMessageTime = (dateTimeString) => {
  const date = convertToDate(dateTimeString)
  return isToday(date) ? format(date, "H:mm") : format(date, "d MMM")+ "."
}

export default MessageTable
