import { useConfig } from 'hooks/config';
import { useLocale } from 'hooks/locale';
import { useRenderContext } from 'hooks/render';
import React from 'react';
import { isHtmlString, renderHtml } from 'utils/html';
import { DisplayProps, toDisplayValue } from 'utils/render';

import { Typography } from '@mui/material';

import { ENABLE_HTML } from './RenderValue';

export const RenderSimpleValue = ({ value, displayProps}: {value: any, displayProps?: DisplayProps}): JSX.Element => {
  const { props: {sx} }    = useRenderContext();

  const {language, locale} = useLocale()
  const {decimalSetting}   = useConfig()

  if (ENABLE_HTML && isHtmlString(value))
    return <React.Fragment>{renderHtml(value)}</React.Fragment>

  const defaultSx = { fontSize: 14, color: "text.secondary" };
  const localSx = {...defaultSx, ...sx}

  /* pre-wrap interprets \n, \r, spaces and tabs. pre-line only interprets \n and \r */
  return <Typography sx={{...localSx, whiteSpace: "pre"}}>{toDisplayValue(value, {decimalSetting, language, locale, ...displayProps})}</Typography>
};

