import { Box, Divider } from '@mui/material';
import MenuBarSearch    from 'components/table/page/MenuBarSearch'
import MenuBarPaging    from 'components/table/page/MenuBarPaging'
import MenuBarReload    from 'components/table/page/MenuBarReload';

const MenuBar = ({showToolbar, disableReload, disableFilter, filter, setFilter, setCurrentFilter, pageable, toolbarFunctions, loading}) => {
  if (showToolbar) {
    return (
      <Box style={{flexShrink: 0}}>
        <Box style={{display: "flex", flexFlow: 'row nowrap', maxWidth: "100%", overflow: "auto", alignItems: "center" }}>
          <MenuBarSearch
            disableFilter={disableFilter}
            onChange={toolbarFunctions.filter}
            loading={loading.filter}
            filter={filter}
            setFilter={setFilter}
            setCurrentFilter={setCurrentFilter}
          />
          <Box style={{flexGrow:1}} />
          <MenuBarPaging
            pageable={pageable}
            toolbarFunctions={toolbarFunctions}
            loading={loading}
          />
          <MenuBarReload
            disableReload={disableReload}
            onClick={toolbarFunctions.reload}
            loading={loading.reload}
          />
          {/*<Settings/>*/}
        </Box>
        <Divider/>
      </Box>
    )
  } else return null
}

export default MenuBar
