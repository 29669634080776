import {
  getProcessName,
  getTaskCreatedAt,
  getSubject
} from 'utils/form-utils';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useResponsiveColumns } from 'hooks/columns';
import { GET_ALL_TASKS, GET_TRANSFERABLE_TASKS, TRANSFER_TASK }        from 'queries/task';
import LargeElevatedTable           from 'components/tables/LargeElevatedTable';
import { useTranslator }        from 'hooks/translator';
import CustomRefProvider from 'contexts/RefContext';
import TransferIcon from '@mui/icons-material/SwapHoriz';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip, useTheme } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNotifier } from 'hooks/notification';
import { reformQuery, removerById } from 'utils/utils';
import { bannerQueries } from 'utils/banner-utils';
import { getTaskTitle } from 'contexts/FormInfoContext';
import { openTaskForm } from 'hooks/process';

const TasksTable = ({kind}) => {
  const navigate       = useNavigate()
  const columns        = useColumns(kind)
  const request        = toRequest(kind)
  const { translator } = useTranslator()
  const notifier       = useNotifier()
  const options        = {translator, navigate, notifier, register: true}


  return (
    <CustomRefProvider>{({getCurrent}) => {
      const deleteLine    = (id) => getCurrent().deleteLine(id)
      const [transferTask] = useTransferTask(deleteLine)

      return (
        <LargeElevatedTable
          filterType="local"
          request={request}
          columns={columns}
          toTableRow={(task) => toTableData(options, task, kind, {transferTask})}
        />
      )}
    }
    </CustomRefProvider>
  )
 
}

/* convert a tasks to row data structure */
function toTableData(options, task, kind, toolkit ) {
  const {translator} = options 

  switch (kind) {
    case "transferable":
      return {
        id: task.id,
        cells: {
          'processName' :    task.processDefinition ? getProcessName(translator, task) : translator.t('unknown'),
          //'taskDescription': getTaskSimpleName(translator, task) + ": " + getTaskDescription(translator, task),
          'taskTitle':       getTaskTitle(translator, {isStartForm: false, task}), 
          'taskSubject':     getSubject(task),
          'assignee':        task.assignee?.username,
          'createdAt':       getTaskCreatedAt(task),
          'transfer':        (<TransferButton task={task} toolkit={toolkit} utils={options}/>) 
        }
      }

    case "all":
      return {
        id: task.id,
        onClick: (e) => openTaskForm(options, e, task),
        cells: {
          'processName' :    task.processDefinition ? getProcessName(translator, task) : translator.t('unknown'),
          //'taskDescription': getTaskSimpleName(translator, task) + ": " + getTaskDescription(translator, task),
          'taskTitle':       getTaskTitle(translator, {isStartForm: false, task}), 
          'taskSubject':     getSubject(task),
          'assignee':        task.assignee?.username,
          'createdAt':       getTaskCreatedAt(task)
        }
      }

    default:
      console.error("Unknown table types. cannot create proper data handler")
      return {
        id: task.id,
        cells: {}
      }
  }
}


const useColumns = (kind) => {
  const { t } = useTranslation()
 
  const processName = {
    headerName: t('process_name'),
    field: "processName",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const subject = {
    headerName: t('subject'),
    field: "taskSubject",
    cellProps: {
      width: 200,
      align: "left"
    }
  }  

  const title = {
    headerName: t('title'),
    field: "taskTitle",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const description = {
    headerName: t('description'),
    field: "taskDescription",
    cellProps: {
      width: 350,
      align: "left"
    }
  }

  const createdAt = {
    headerName: t('createdAt'),
    field: "createdAt",
    cellProps: {
      width: 100,
      align: "left",
      sx: {whiteSpace: "nowrap"}
    }
  }

  const assignee = {
    headerName: t('assignee'),
    field: "assignee",
    cellProps: {
      width: 100,
      align: "left"
    }
  }

  const allowedRoles = {
    headerName: t('allowed_roles'),
    field: "allowedRoled",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const createColumn = (kind) => {
    switch (kind) {
      case "transferable":
        return {
          xxs: [subject],
          md: [
            processName,
            subject 
          ],
          lg: [
            processName,
            subject,
            title,
          ],
          xl: [
            processName,
            subject,
            title,
            createdAt,
            assignee,
            allowedRoles
          ],
          end: [
            {
              headerName: '',
              field: "transfer",
              cellProps: {
                align: 'right',
                sx: { padding: 0, px: '8px', width: '40px' }
              }
            }
          ]
        }
      case "all":
        return {
          xxs: [subject],
          md: [
            processName,
            subject 
          ],
          lg: [
            processName,
            subject,
            title,
          ],
          xl: [
            processName,
            subject,
            title,
            createdAt,
            assignee
          ]
        }
      default:
        return {
          xxs: [subject],
          md: [
            processName,
            subject 
          ],
          lg: [
            processName,
            subject,
            title,
          ],
          xl: [
            processName,
            subject,
            title,
            createdAt,
            assignee
          ]
        }
    }
  }

  const columns = createColumn(kind)

  return useResponsiveColumns(columns, true)
}

function toRequest(kind) {
  switch (kind) {
    case "all": 
      return  {  
        variables: {},
        query:     GET_ALL_TASKS,
        path:      'data.allTasks',
        countPath: 'data.allTasksCount',
      }

    case "transferable": 
      return {
        variables: {},
        query:     GET_TRANSFERABLE_TASKS,
        path:      'data.transferableTasks',
        countPath: 'data.transferableTasksCount',
      }

    default: 
      return 
      // ???

  }
}

const TransferButton = ({task, toolkit, utils}) => {
  const [open, setOpen] = useState(false);
  const {t}             = useTranslation()

  const onClick =(e) => {
    e.preventDefault()
    e.stopPropagation()
    toolkit.transferTask(task)
  }

  return (
    <>
      <Tooltip disableInteractive title={t('table.transfer')}>
        <IconButton id={'task-' + task.id + '-transfer-button'} onClick={(e) => { setOpen(true); e.stopPropagation(); e.preventDefault();}} >
          <TransferIcon />
        </IconButton>
      </Tooltip>
      <ConfirmTransfer task={task} open={open} setOpen={setOpen} onClick={onClick}/>
    </>
  )
}

const ConfirmTransfer = ({task, onClick, open, setOpen}) => {
	const {t}         = useTranslation()
	const theme       = useTheme()
	const handleClose = (e) => { e.preventDefault(); e.stopPropagation(); setOpen(false) }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: theme.typography.h3.fontSize}}>
          {t('table.transferConfirmation')}
        </DialogTitle>
        <DialogActions>
          <Button id={'task-' + task.id + '-transfer-button'} onClick={(e) => { handleClose(e); onClick(e); }}>{t('table.transfer')}</Button>
          <Button id={'task-' + task.id + '-close-button'} onClick={handleClose} autoFocus>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
  );
}

export const useTransferTask = (deleteLine) => {
  const notifier = useNotifier()
  const {t} = useTranslation()

  const [doTransferTask] = useMutation(TRANSFER_TASK, {refetchQueries: bannerQueries, 
    // update the cache
    update: (cache, { data: { transferTask: id } }) => {
      reformQuery(cache, GET_TRANSFERABLE_TASKS, {transferableTasks : removerById(id)});
    }
  })

  const transferTask = (task) => {
    return doTransferTask({ variables: { id: task.id, assignee: task.assignee.id }})
      .then(result => {
        const success = result.data.transferTask
        if (success) {
          notifier.success(t("table.transferSuccess"))
          deleteLine(task.id)
        } else {
          notifier.error(t("table.transferFailure"))
        }
      }, error => {
        notifier.error(t("table.transferFailure"))
        console.error("Transfering task has failed: %o", error)
      })
      .catch(reason => {
        notifier.error(t("table.transferFailure"))
        console.error("the frontend has an issue transfering the task: " + reason)
      })
  }

  return [transferTask]
}

export default TasksTable;
