import { TileInputProps } from 'components/process/Tile';
import { ProcessRenderContext, ProcessRenderProps } from 'contexts/ProcessRenderContext';
import { RenderContext, RenderProps } from 'contexts/RenderContext';
import { T } from 'helpers/translator';
import { useTranslator } from 'hooks/translator';
import { useContext } from 'react';
import { toRegex } from 'utils/option-utils';
import { getPathDepth, sortByPathOrder } from 'utils/process';
import { getPath } from 'utils/utils';

import { PathOrder, ProcessPathObject, TranslatedProcessDefinition } from './process';

export const useRenderContext = (): RenderProps => useContext(RenderContext) || {props: {}}

export const useProcessRenderContext = (): ProcessRenderProps => useContext(ProcessRenderContext)

export const useTiles = (): TileInputProps[] => {
  const pathObject                     = useFilteredPathObject()
  const { translator }                 = useTranslator()
  const { processInfo: {order}, path } = useProcessRenderContext()

  const directoryEntries = sortEntries(
    Object.entries(pathObject).filter(([key, value]) => !isProcess(value)),
    path, 
    order
  ) 

  const processEntries = sortEntries(
    Object.entries(pathObject).filter(([key, value]) => isProcess(value)),
    path, 
    order
  )

  const previousTiles: TileInputProps[] = path != "" ? [{
    type: "directory",
    label: "..",
    path: previousPath(path),
  }] : []

  const dirTiles: TileInputProps[] = directoryEntries.map(([key, value]) => ({
    type: "directory",
    label: translator.toCategory(key),
    path: toPath(path, key),
  }))

  const processTiles: TileInputProps[] = processEntries
    .map(([key, value]) => {
      const process = (value as TranslatedProcessDefinition)

      return {
        type: "process",
        path: toPath(path, key),
        process: process
      }
    })

  const tiles: TileInputProps[] = [...previousTiles, ...dirTiles, ...processTiles]

  return tiles
}

const useFilteredPathObject = () => {
  const paths            = usePathObject()
  const { translator }   = useTranslator()
  const { filter, path } = useProcessRenderContext()

  if (filter == "")
    return paths
  else 
    return filterPathObject(translator, filter, paths, path)
}


const usePathObject = (): ProcessPathObject => {
  const {processInfo: {paths}, groupBy, path} = useProcessRenderContext()

  const retPaths = getPath(paths, path)! 

  switch (groupBy) {
    case 'none':
      return getPathProcesses(retPaths)

    case 'category':
      const depth = getPathDepth(path) 
      return depth > 0 ? getPathProcesses(retPaths) : retPaths

    case 'subcategory':
    default:
      return retPaths
  }
}

function isProcess (value: any) {
  return value.hasOwnProperty("__typename")
}

function previousPath(path: string) {
  const parts = path.split(".")
  if (parts.length == 1)
    return ""
  else { 
    parts.pop()
    return parts.join(".")
  }
}

function toPath(path: string, key: string) {
  return `${path == "" ? "" : path + "."}${key}`
}

function sortEntries<T>(entries: [string, T][], path: string, order: PathOrder )  {
  return sortByPathOrder(
      entries, 
      ([key, value]) => toPath(path, key),
      order
    )  
}

function getPathProcesses(paths: ProcessPathObject, path: string = ""): ProcessPathObject {
  return Object.entries(paths)
    .reduce((innerPaths, [key, value]) => {
      const currentPath = path == "" ? key : path + "." + key 
      if (isProcess(value)) 
        return {...innerPaths, [currentPath]: value}
      else 
        return {...innerPaths, ...getPathProcesses(value as ProcessPathObject, currentPath)}
    }, {} as ProcessPathObject)
}

function filterPathObject(translator: T, filter: string, paths: ProcessPathObject, path: string = ""): ProcessPathObject {
  const regex  = toRegex(filter)
  const check  = (value: any) => regex.test(value)

  return Object.entries(paths)
    .reduce((foundPaths, [key, value]) => {
      const currentPath = path == "" ? key : path + "." + key 
      
      if (isProcess(value))  {
        if (check(value.title)) {
          console.log("matched: %o with %o", filter, value.title)
          return {...foundPaths, [currentPath]: value}
        } else 
          return foundPaths
      } else {
        const label = translator.toCategory(key.split(".").pop()!)
        if (check(label))
          return {...foundPaths, [currentPath]: value, ...filterPathObject(translator, filter, value as ProcessPathObject, currentPath) }
        else 
          return {...foundPaths, ...filterPathObject(translator, filter, value as ProcessPathObject, currentPath) }

      }
    }, {} as ProcessPathObject)
}

