import { stringToColor } from 'components/layout/dashboard/Avatar';
import MuiIcon from 'components/MuiIcon';
import TileInfoButton from 'components/process/TileInfoButton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ProcessDefinition } from 'types/graphql';

import { Folder } from '@mui/icons-material';
import {
    Box, Card, CardActionArea, CardContent, darken, IconButton, lighten, SxProps, Typography,
    useTheme
} from '@mui/material';

import { ProcessCard } from './ProcessCard';

//type StartProcessFunction = (e: React.SyntheticEvent, process: ProcessDefinition) => void
type ProcessStyle = {
  icon?: string
  sx?: SxProps
}

type TileCardProps = {
  path: string
  label: string
  onClick: (e?: React.SyntheticEvent) => void
  description?: string
  icon?: string
  tileProps?: Partial<TileStyleProps>
}

export type TileStyleProps = {
  maxLines: string
  tileWidth: string
  iconSize: string
  fontSize?: string
  padding: number | string
  textMargin: string
}

const defaultTileProps: TileStyleProps = {maxLines:"2", tileWidth: "160px", iconSize: "80px", fontSize: undefined, padding: 1.5, textMargin: "12px"}

export const TileCard = ({icon, description, onClick, path, label, tileProps}: TileCardProps) => {
  const {maxLines, tileWidth, iconSize, fontSize, padding, textMargin} = {...defaultTileProps,...tileProps}

  const containerRef  = useRef<HTMLDivElement>(null);
  const titleRef      = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<string>("0px")

  useEffect(() => {
    const containerHeight = containerRef?.current?.offsetHeight || 0
    const titleHeight     = titleRef?.current?.offsetHeight || 0
    const top             = titleHeight - containerHeight

    if (top >= 0)
      setTop(`-${top}px`)
  },[icon, description, path, label])

  return (
    <Card
      elevation={0}
      sx={{
        ".process-info-button": {
          opacity: 0
        },
        ":hover": {
          boxShadow: theme => theme.shadows[10],
          ".process-title-hover": {
            opacity: 1,
            top: top
          },
          ".process-title": {
            opacity: 0
          },
          ".process-info-button": {
            opacity: 1
          }
        },
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        width: tileWidth
      }}
    >
      <CardActionArea
        id={path}
        sx={{justifyContent: 'center'}}
        onClick={onClick}
      >
      {description && description?.toLowerCase() !== "null"
              ? <Box sx={{ position: 'absolute', top: 0, right: 0 }} > <TileInfoButton description={description} title={label} /> </Box>
              : null
            }
        <CardContent sx={{ padding: padding, width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", overflow: "visible" }} >
          <Box className='tile-icon-container' sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center' }}>
            <TileIcon path={path} size={iconSize} icon={icon} label={label}/>
          </Box>
          <Box ref={containerRef} sx={{ position: 'relative', display: "flex", flexGrow: 1, justifyContent: 'center', marginTop: textMargin, width: "100%", overflow: "visible" }}>
            <Box ref={titleRef} className='process-title-hover' 
              sx={{ 
                borderRadius: "6px",
                width: "100%", 
                position: "absolute", 
                transition: "top 0.1s ease, background 0.1s ease", 
                top: "0px", 
                right: 0, 
                opacity: 0 ,
                background:  'rgb(246, 247, 248, 0.7)'
              }}
            >
              <Typography
                justifyContent="center"
                align="center"
                color="textPrimary"
                variant="h5"
                fontStyle="normal"
                fontSize={fontSize}
              >
                {label}
              </Typography>
            </Box>

            <Typography
              className='process-title'
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                'WebkitLineClamp': maxLines,
                "WebkitBoxOrient": "vertical"
              }}
              justifyContent="center"
              align="center"
              color="textPrimary"
              variant="h5"
              fontStyle="normal"
              fontSize={fontSize}
            >
              {label}
            </Typography>
            
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export const TileIcon = ({path, size, icon, label}: {path: string, size: string, label?: string, icon?: string}) => {
  const theme = useTheme()
  const defaultColor = theme.palette.grey[500]

  const processesThemeKey                      = 'components.processes'
  const processThemeKey                        = `${processesThemeKey}.${path}`
  const processStyle: ProcessStyle | undefined = _.get(theme, processThemeKey)

  const sx: React.CSSProperties = {
    color: icon == "Folder" ? defaultColor : stringToColor(path+icon), 
    ...processStyle?.sx
  } as React.CSSProperties 
  
  const {background, ...iconSx} = sx
  const pxSize                  = Number.parseInt(size)
  const iconSize                = `${pxSize * 0.9}px`

  return (
    <Box className="tile-icon" sx={{
      background,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      objectFit: 'cover',
      borderRadius: "50%",
      height: pxSize, width: pxSize,
    }}
    >
      <MuiIcon sx={iconSx} name={processStyle?.icon || icon || "PlayCircleOutlined"} size={iconSize} iconLocation={`process tile ${path}`} />
    </Box>
  )
}

export default ProcessCard;
