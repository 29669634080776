import { Helmet } from 'react-helmet';
import DetailComponent from 'components/view/Detail';
import { useTranslation } from 'react-i18next';
import { handleResult } from 'utils/utils';
import { Box, } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import NotFound from 'pages/NotFound';
import { useNotifier } from 'hooks/notification';
import { useConfig } from 'hooks/config';
import { GET_DETAIL_VIEW } from 'queries/detail';
import ViewLayout from 'components/view/ViewLayout';
import { getEnabledLinks } from 'utils/links';
import DetailBreadcrumbs from 'components/breadcrumbs/DetailBreadcrumbs';
import RenderContextProvider from 'contexts/RenderContext';
import { useTranslator } from 'hooks/translator';
import StickyBannerLayout from 'components/layout/StickyBannerLayout';


const Detail = () => {
  const {t}         = useTranslation()
  const { project } = useConfig()
  const { id: detailId, detail: detailKey } = useParams();

  return (
    <>
      <Helmet>
        <title>{t('detail')} | {project}</title>
      </Helmet>
      <RenderContextProvider context="detail" props={{detailKey, detailId}}>
        <DetailResult/>
      </RenderContextProvider>
    </>
  );
}

const DetailResult = () => {
  const { id: detailId, detail: detailKey } = useParams();

  const result   = useQuery(GET_DETAIL_VIEW, { variables: { detailKey, detailId} })
  const notifier = useNotifier()

  return handleResult(result, notifier,
    data  => <DetailContents schema={data.detail.schema} links={getEnabledLinks(data.detail.instance.links)}/>,
    error => { console.error("GraphQL error: %o", error); return <NotFound/> }
  )
}

const DetailContents = ({schema, links}) => {
  const { translator } = useTranslator()
  const label          = translator.toDetailTitle(schema.key, schema.label) 

  return (
    <ViewLayout links={links} >
      <StickyBannerLayout
        banner={<DetailBreadcrumbs label={label} /> }
      >
        <Box sx={{display: "flex", width: "100%", padding: "20px"}}>
          <DetailComponent schema={schema}/>
        </Box>
      </StickyBannerLayout>
    </ViewLayout>
  )
}

export default Detail;
