import { ListItemText }     from '@mui/material';
import ListItemIcon         from '@mui/material/ListItemIcon';
import Menu                 from '@mui/material/Menu';
import MenuItem             from '@mui/material/MenuItem';
import MuiIcon              from 'components/MuiIcon';
import { useNotifier }      from 'hooks/notification';
import { createMenuItemSx } from 'theme/menu';

const TableMenu = ({contextMenu, setContextMenu, menu}) =>  {
  const open = Boolean(contextMenu);
  const sx   = createMenuItemSx()

  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setContextMenu(null);
  };

  const anchorPosition = contextMenu !== null ? { top: contextMenu.y, left: contextMenu.x } : undefined
  return (
      <Menu
        sx={sx.menuitem}
        autoFocus={false}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        id="row-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
      >
        <TableMenuItems menu={menu} />
      </Menu>
  );
}

const TableMenuItems = ({menu}) => 
  menu.map((item, index) => <TableMenuItem key={index} item={item} />)

const TableMenuItem = ({item}) => (
  <MenuItem onClick={item.onClick}>
    <ListItemIcon>
      <MuiIcon iconLocation={`a table context menu item, labeled ${item.label}`} name={item.icon} fontSize="small" />
    </ListItemIcon>
    <ListItemText>
      {item.label}
    </ListItemText>
  </MenuItem>
)

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': { // this is the trangle
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 10,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

export default TableMenu