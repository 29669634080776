import { BrowserRouter, HashRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, from } from '@apollo/client'
import createUploadLink from "apollo-upload-client/public/createUploadLink.js"
import { createRoot } from 'react-dom/client'

import pckg                       from '../package.json'
import App                        from './App'
import { authLink, AuthProvider } from './authentication/main'
import { env }                    from './utils/utils'
import * as serviceWorker         from './utils/serviceWorker'
import i18n                       from './utils/i18n'
import { ENABLE_HTML } from 'components/render/RenderValue'

const NAME        = env('NAME')
const THEME       = env('THEME')
const SERVER      = env('SERVER')
const AUTH_MODE   = env('AUTH_MODE')
const ROUTER_MODE = env('ROUTER_MODE')

console.log(`${pckg.name} ${pckg.version} with NAME=${NAME}, ENABLE_HTML=${ENABLE_HTML}, THEME=${THEME}, SERVER=${SERVER}, AUTH_MODE=${AUTH_MODE}, ROUTER_MODE=${ROUTER_MODE}`)
console.debug('i18n', i18n)
window.document.title = NAME

// see https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
const disableCache = true
const disableCacheOpts = {
  watchQuery: { fetchPolicy: 'network-only' },
  query: { fetchPolicy: 'network-only' },
}

const mergePolicy = {
  typePolicies: {
    Query: {
      fields: {
        ProcessInstance: {
          assignedTasks: {
            merge(existing, incoming) {
              return incoming;
            }
          },
        },
        tasks: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        assignedTasks: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        groupTasks: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        myActiveProcessInstances: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    }
  }
}

const getRouter = (mode) => {
  switch (mode.toLowerCase()) {
    case 'browser': return BrowserRouter
    case 'hash':    return HashRouter
    default:        throw new Error('Invalid router mode: ' + mode)
  }
}

const uploadLink = createUploadLink({ uri: SERVER + 'graphql'})
const fromLink   = from([authLink, uploadLink])
const client     = new ApolloClient({
  link: fromLink,
  cache: new InMemoryCache(mergePolicy),
  defaultOptions: disableCache ? disableCacheOpts : {},
})

const container = document.getElementById('root')
const root = createRoot(container)
const Router = getRouter(ROUTER_MODE)

root.render(
  <ApolloProvider client={client}>
    <Router basename={env('ROUTER_BASE')}>
      {/*<Suspense fallback="Loading...">*/}
        <AuthProvider>
          <App/>
        </AuthProvider>
      {/*</Suspense>*/}
    </Router>
  </ApolloProvider>
)

serviceWorker.unregister()
