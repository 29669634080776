import MenuIcon                from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import DashboardMenu           from 'components/layout/dashboard/DashboardMenu';
import { useConfig }           from 'hooks/config';
import PropTypes               from 'prop-types';
import { BannerSlidingExpander } from 'components/layout/sidebar/Sidebar';

const DashboardBanner = ({ onMobileNavOpen, ...rest }) => {
  const theme = useTheme()

  return (
      <Toolbar sx={theme.components.toolbar} disableGutters={true}>
        <BannerSlidingExpander/>
        {/*<ProjectTitle /> */}
        <Box sx={{ flexGrow: 1 }} />
        <DashboardMenu />
      </Toolbar>
  );
};

const ProjectTitle = () => {
  const { project } = useConfig()
  const theme       = useTheme()
  const style       = theme.components.title

  return (
    <Box sx={{paddingLeft: '20px', paddingRight: "20px", display: 'flex', flexDirection: 'row'}}>
      <Box>
      <Typography {...style} >
        {project}
      </Typography>
      </Box>
    </Box>
  )
}

DashboardBanner.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardBanner;
