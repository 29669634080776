import { PanePageButton } from 'components/wm';
import { useTaskId } from 'hooks/form';
import { useNotifier } from 'hooks/notification';
import { FormikType } from 'hooks/submit';
import { SAVE_TASK } from 'queries/task';
import { MutableRefObject, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Report from 'utils/report';
import { extractFiles } from 'utils/utils';

import { useMutation } from '@apollo/client';

type FormikRef = MutableRefObject<FormikType | undefined>

const TaskSave = ({formikRef}: {formikRef: FormikRef}) => {
  const { t }                   = useTranslation()
  const [disabled, setDisabled] = useState(false)  
  const [loading, setLoading]   = useState(false)
  const [doSaveTask]            = useMutation(SAVE_TASK)
  const id                      = useTaskId()
  const notifier                = useNotifier()

  const handleSave = (e: SyntheticEvent) => {
    const formik = formikRef?.current
    const values = formik?.values
    const [rValues, files]  = extractFiles(values)
    
    console.log("handleSave: id=%o, values=%o files=%o", id, rValues, files)
    setDisabled(true)
    setLoading(true)
    doSaveTask({ variables: { id, values: rValues, files }})
      .then(
        result => {
          setLoading(false)
        },
        error  => {
          setLoading(false)
          setDisabled(false)
          const report = Report.from(error, { category: Report.backend})
          report.addToNotifier(notifier)
          console.error("Save error: %s", report.verboseMessage)
        }
      )
  }

  useEffect(() => {
    if (!disabled)
      return

    const timeout = 5000
    const timer   = setTimeout(() => {
      setDisabled(false);
    }, timeout);

    return () => {clearTimeout(timer)}
  }, [disabled]);

  return <PanePageButton title={t("save")} disabled={disabled || loading} onClick={handleSave} icon='Save' loading={loading} secondaryIcon='SaveOutlined' isSecondary={disabled} />
}

export default TaskSave
