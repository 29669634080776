import { MultipleRowCheckBox } from 'components/fields/multiple/MultipleTableRowSelectCell';
import StickyBannerLayout from 'components/layout/StickyBannerLayout';
import { RenderValue } from 'components/render';
import { useFieldInfo } from 'hooks/field';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field } from 'types/graphql';
import { SetState } from 'types/react';
import Types from 'types/types';
import { FieldRenderType } from 'utils/field-utils';
import {
    MultipleColumn, MultipleRow, MultipleRows, useMultipleColumns
} from 'utils/multiple-utils';
import { fallback } from 'utils/utils';

import { alpha, Box, Divider, SxProps, TextField } from '@mui/material';

import InputField from '../InputField';
import AddRowButton from './AddRowButton';
import CopyRowButton from './CopyRowButton';
import DeleteRowButton from './DeleteRowButton';
import MultipleTableHeaderActions from './MultipleTableHeaderActions';
import { MultipleCheckBox } from './MultipleTableHeaderSelectCell';

type WithRowIndex = {
  rowIndex: number
}

type WithRow = {
  row: MultipleRow
}

type WithRows = {
  rows: MultipleRows
}

type WithColums = {
  columns: MultipleColumn[]
}

export type SelectedRows = number[] 

type SelectRows = {
  setSelected: SetState<SelectedRows>
  selected: SelectedRows
}

interface FlexRowsProps extends SelectRows,WithRows {}

interface FlexRowProps extends SelectRows,WithRow,WithRowIndex,WithColums,WithRows {}

interface FlexRowActionProps extends SelectRows,WithRowIndex {}

interface FlexRowsActionProps extends SelectRows,WithRowIndex,WithRows {}

interface FlexRowContentProps extends WithRow,WithRowIndex,WithColums {}

const MultipleFlex = () => {
  const { fieldProps } = useFieldInfo()

  const rows = fallback(fieldProps.value, [{}])
  const [ selected, setSelected ] = useState<SelectedRows>([])

  return (
    <Box className='multiple-flex-table' sx={{display: "flex", width: "100%", maxHeight: "100%", overflow: 'auto', flexDirection: "column"}}>
      <StickyBannerLayout
        banner={<MultipleFlexHeader rows={rows} selected={selected} setSelected={setSelected} />}
        bodySx={{display: "flex", flexDirection: "column", paddingTop: "4px"}}

      >
        <MultipleFlexRows rows={rows} selected={selected} setSelected={setSelected} />
      </StickyBannerLayout>
    </Box>
  )
}

const MultipleFlexHeader = ({rows, selected, setSelected}: FlexRowsProps) => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  if (mode === "open") {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", background: theme => alpha
      (theme.palette.grey[400], 0.3)  }}>

        <MultipleCheckBox rows={rows} selected={selected} setSelected={setSelected}/>
        <MultipleTableHeaderActions
          selected={selected}
          setSelected={setSelected}
          rows={rows}
        />

      </Box>
    )
  } else return null
}

const MultipleFlexRows = ({rows, selected, setSelected}: FlexRowsProps) => {
  const columns = useMultipleColumns(rows)

  return (
    <React.Fragment>
    { rows
      .map((row, index) => 
        <MultipleFlexRow rows={rows} columns={columns} row={row} selected={selected} setSelected={setSelected} rowIndex={index}/>
      )
    }
    </React.Fragment>
  )
}

const MultipleFlexRow = ({columns, rowIndex, rows, row, selected, setSelected}: FlexRowProps): JSX.Element => {
  return (
    <Box className='multiple-flex-row' sx={{paddingY:"8px", borderBottom: "2px solid", borderColor: theme => theme.palette.grey[300], display: "flex", flexGrow: 1, flexDirection: "row", overflow: "visible"}}>
      <MultipleFlexRowPreActions rowIndex={rowIndex} selected={selected} setSelected={setSelected}/>
      <MultipleFlewRowFields columns={columns} row={row} rowIndex={rowIndex}/> 
      <MultipleFlexRowPostActions rows={rows} rowIndex={rowIndex} selected={selected} setSelected={setSelected}/>
    </Box>
  )
}

const MultipleFlewRowFields = ({columns, row, rowIndex}: FlexRowContentProps) => {
  const { info, info: {indices} } = useFieldInfo()
  const newIndices                = { ...indices, [info.field.elementName as string]: rowIndex }

  return (
    <Box className='multiple-flex-fields' sx={{display: "flex", flexDirection: "row", justifyContent: 'space-between', gap: "10px", flexGrow: 999, overflow: "hidden", flexWrap: "wrap", maxWidth: "100%"}}>
      { columns
          .map((column, index) => 
            <MultipleFlewRowField key={index} column={column} indices={newIndices} row={row} rowIndex={rowIndex} /> 
          )
      }
    </Box> 
  )
}

interface MultipleRowFieldProps extends WithRowIndex,WithRow {
  column: MultipleColumn
  indices: any
}

const MultipleFlewRowField = ({column, row, rowIndex, indices}: MultipleRowFieldProps): JSX.Element => {
  if (column.type == 'input')  {
     
    return (
      <Box className='multiple-flex-field' sx={{display: "flex", ...flexInputFieldSize(column)}} >
        <InputField
          field={column.field as Field}
          indices={indices}
        />
      </Box>
    )
  } else {
    const value = _.get(row, column.attr)
    return (
      <Box 
        sx={{ 
          background: theme => alpha(theme.palette.grey[100], 0.5), 
          height: "100%", 
          display: "flex", 
          alignItems: "center",
          paddingX: "5px"
        }} 
      >
        <RenderValue>
          {value}
        </RenderValue>
      </Box>
    )
  }

}

function flexInputFieldSize(column: MultipleColumn): SxProps {

  switch (column.renderType) {
    case "DECIMAL":
    case "INTEGER":
      return {
        flexGrow: 1,
        minWidth: "120px",
        width: "120px",
      }

    case "MULTIPLE SELECT":
      return {
        flexGrow: 4,
        minWidth: "240px",
        width: "240px",
      }

    case column.field?.type == "BOOLEAN" && "SINGLE SELECT":
      return {
        flexGrow: 1,
        minWidth: "120px",
        width: "120px",
      }

    case "SINGLE SELECT":
      return {
        flexGrow: 2,
        minWidth: "240px",
        width: "240px",
      }

    case 'DATETIME':
      return {
        flexGrow: 1,
        minWidth: "240px",
        width: "240px",
      }

    case 'TIME':
    case 'DATE':
      return {
        flexGrow: 1,
        minWidth: "180px",
        width: "180px",
      }

    case "FILE":
      return {
        flexGrow: 1,
        minWidth: "240px",
        width: "240px",
      }


    case "PERIOD":
      return {
        flexGrow: 2,
        minWidth: "240px",
        width: "240px",
      }

    case "TEXT":
      return {
        flexGrow: 1,
        minWidth: "180px",
        width: "180px",
      }


    case 'MULTIPLE':
      return {
        flexGrow: 99999,
        width: "100%", 
        minWidth: "100%"
      }

    default:
      return {
        flexGrow: 1,
        minWidth: "100px",
        width: "100px",
      }
  }
}

const MultipleFlexRowPreActions = ({selected, setSelected, rowIndex}: FlexRowActionProps): JSX.Element => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Box className='multiple-flex-pre-actions' sx={{display: "flex", height: "100%", justifyContent: "center"}}>
      { mode === "open"
        ? <MultipleRowCheckBox rowIndex={rowIndex} selected={selected} setSelected={setSelected} />
        : Types.null<JSX.Element>()
      }
    </Box>
  )
}

const MultipleFlexRowPostActions = ({selected, setSelected, rowIndex, rows}: FlexRowsActionProps): JSX.Element => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Box className='multiple-flex-post-actions' sx={{display: "flex", flexShrink: 1, flexDirection: "row", alignItems: "center"}}>
      { mode === "open"
        ?  <Box sx={{display: "flex", flexShrink: 1, flexDirection: "row", flexWrap: 'wrap'}} >
             <AddRowButton id={rowIndex} rows={rows} selected={selected} setSelected={setSelected} />
             <CopyRowButton id={rowIndex} rows={rows} selected={selected} setSelected={setSelected} />
             <DeleteRowButton id={rowIndex} rows={rows} selected={selected} setSelected={setSelected} />
           </Box>
        : Types.null<JSX.Element>()
      }
    </Box>
  )}

export default MultipleFlex
