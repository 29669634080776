import { TableRow, TableHead } from '@mui/material';
import _                       from 'lodash';
import PropTypes               from "prop-types"
import { fieldsType }          from 'utils/type-utils';

import { 
  MultipleTableHeaderSelectCell,
  MultipleTableHeaderFieldCells,
  MultipleTableHeaderActionCell } from 'components/fields/multiple';

const MultipleTableHeader = (props) => {
  return (
    <TableHead>
      <TableRow>
        <MultipleTableHeaderSelectCell {...props} key="selection" />
        <MultipleTableHeaderFieldCells {...props} />       
        <MultipleTableHeaderActionCell {...props} key="action" />
      </TableRow>
    </TableHead>
  )
}

MultipleTableHeader.propTypes = {
  props : PropTypes.shape({
    mode   : PropTypes.string.isRequired,
    fields : fieldsType,
  })
}

export default MultipleTableHeader