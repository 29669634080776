import { MultipleTable, MultipleTableBasedOn } from 'components/fields/multiple';
import MultipleFlex from 'components/fields/multiple/MultipleFlex';
import RenderContextProvider from 'contexts/RenderContext';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useRenderContext } from 'hooks/render';
import { useTranslator } from 'hooks/translator';
import { useEffect } from 'react';
import { getInputLabel } from 'utils/form-utils';
import { setStepIconTabIndices } from 'utils/task-utils';
import { nullOrEmpty } from 'utils/values';

import { Card, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';

const InputMultipleField = (): JSX.Element => {
  const { t, translator }                        = useTranslator()
  const { fieldProps, info }                     = useFieldInfo()
  const { processDefinition: { key: processKey}} = useFormInfo()
  const mode                                     = info.field.mode
  const { multipleType }                         = useRenderContext()

  useEffect(() => {
    setStepIconTabIndices()
  },[])

  if (mode === "closed" && nullOrEmpty(fieldProps.value)) {
    return (
      <FormControl fullWidth required={true} >
        <FormLabel sx={{marginBottom: "16px"}} component="legend">{getInputLabel(translator, info.field, processKey)}</FormLabel>
        <Typography>{t('form.multiple.no-lines')}</Typography>
      </FormControl>
    );
  }

  const helperText = fieldProps.error ? (fieldProps.helperText?.trim() ? fieldProps.helperText : t('yup.invalid.multiple')) : fieldProps.helperText
  return (
    <RenderContextProvider parent="multiple" >
      <FormControl fullWidth required={fieldProps.required} error={fieldProps.error} sx={{display: "flex", maxHeight: "100%", overflow: "auto"}}>
        {!info.inMultiple && multipleType != "mutable-flex"
          ? <FormLabel sx={{ marginBottom: "16px" }} component="legend">{getInputLabel(translator, info.field, processKey)}</FormLabel>
          : null
        }
        { info.inMultiple || multipleType != "mutable-flex" ? <MultipleTableBasedOn /> : null }
          <MutableMultipleInput/>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </RenderContextProvider>
  );
}

const MutableMultipleInput = () => {
  const { multipleType }  = useRenderContext()
  const {info: {indices}} = useFieldInfo()

  switch (multipleType) {
    case 'mutable-flex': 
      return <MultipleFlex/>

    case 'mutable-table':
    default:

      return (
        <Card sx={{margin: Object.keys(indices).length > 1 ? undefined : "5px"}}>
          <MultipleTable />
        </Card>
      )
  }
}

export default InputMultipleField
