import 'moment/locale/nl'; // critical for dutch moment translations 

import LambdaComponent from 'components/LambdaComponent';
import moment from 'moment';
import { createContext, useEffect, useState } from 'react';
import { SetState } from 'types/react';
import i18n from 'utils/i18n';
import { getRegionAndLanguage, toDisplayLanguage } from 'utils/language';
import { getInitialLocale, toLocaleProps } from 'utils/localization-utils';
import Settings from 'utils/settings-utils';

type LocaleConfig = {
  language: string
  locale: string
  muiKey?: string
  muiLocale?: string
  dateFnsKey?: string
  dateFnsLocale?: {
    code: string
    formatDistance: any
    formatLong: any
    formatRelative: any
    localize: object
    match: object
    options: object
  }
  setLocale: SetState<string>
}

interface LocaleConfigProps extends LocaleConfig {
  children: React.ReactNode
};

export const LocaleContext = createContext<LocaleConfig>({} as LocaleConfig);

const LocaleProvider = ({children, ...props}: LocaleConfigProps)  => {
	const [locale, setLocale] = useState(() => getInitialLocale(props.locale))
  const settings            = new Settings()
  
  const setLanguage = (locale: string) => {
    const rl = getRegionAndLanguage(locale)
    const language = rl ? rl.language : 'en'

    moment.locale(language, {
      ...dateFormats[language]
    })

    i18n.changeLanguage(language)
      .then( () => { console.log("Language: %o", toDisplayLanguage(locale)) } )
  }

  useEffect(() => {
    // store locale
    settings.setLocale(locale)

    // set language
    setLanguage(locale)
  }, [locale])

  const localeConfig: LocaleConfig = {...toLocaleProps(locale), setLocale}
  return (
    <LocaleContext.Provider value={localeConfig}>
			<LambdaComponent props={{...toLocaleProps(locale)}}>
				{children}
			</LambdaComponent>
    </LocaleContext.Provider>
  );
}

const dateFormats: any = {
  "en": {
    longDateFormat : {
      LT: "h:mm:ss A", // <----------- add :ss
      LLL: "MMMM D, YYYY LT",
    }
  },
  "nl": {
    longDateFormat : {
      LT: "H:mm:ss",
      LLL: "D MMMM YYYY LT",
    }
  }
}

export default LocaleProvider;
