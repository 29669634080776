import { Card }       from "@mui/material"
import Elevate from "components/Elevate"
import LargeTable     from "components/table/LargeTable"

const LargeElevatedTable = (props) => {
  return (
    <Elevate className='tasks-card'    
      sx={{
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        display: "flex",
        flexDirection: "column", 
        width: '100%',
      }}
    >
      <LargeTable
        {...props}
        tableSx={{width: "100%", maxHeight: "100%", ...props.tableSx}}
      />
    </Elevate>
  )
}

export default LargeElevatedTable