import { Box }                        from "@mui/material"
import Breadcrumbs                    from "components/breadcrumbs/Breadcrumbs"
import { useParams }                  from "react-router"
import Settings                       from "utils/settings-utils"
import { createDetailCrumbKey }       from "utils/view"

const DetailBreadcrumbs = ({label}) => {
  const {detail, id} = useParams()
  const variable     = createDetailCrumbKey(detail,id)
  const crumbs       = Settings.session.read(variable, [])

  if (crumbs.length > 0) {
    crumbs[crumbs.length -1].label = label
  } else {
    crumbs.push({label: label})
  }

  const Crumbs = () => crumbs.length > 0 ? <Breadcrumbs crumbs={crumbs} /> : null
  return (
    <Box sx={{padding: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Crumbs/>
    </Box>
  )
}

export default DetailBreadcrumbs
