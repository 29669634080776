import _                                from "lodash";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box }                                 from "@mui/material";
import { formatChoices }                from "utils/input-utils";
import { getEventValue, validateField } from "utils/validate-utils";
import { useFieldInfo }                 from "hooks/field";
import { isOption }                     from "utils/option-utils";
import { useTranslator } from "hooks/translator";
import { useFormInfo } from "hooks/form";
import { useAutoSubmitSignal } from "hooks/autosubmit";

const InputRadioField = () => {
  const { fieldProps, info, augProps } = useFieldInfo()
  const row                            = info.type === "RADIO BOOLEAN SELECT"
  const value                          = isOption(fieldProps.value) ? fieldProps.value.value : fieldProps.value
  const {signal}                       = useAutoSubmitSignal()


  function getValue(e) {
    const eventValue = getEventValue(e, null)
    return value === eventValue ? null : eventValue
  }

  function handleValidate(e, value) {
    const error = validateField("radio", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(e) {
    augProps.setRuntimeError(undefined)
    const value = getValue(e)
    augProps.setValue(value)
    handleValidate(e, value)
  }

  function handleBlur(e) {
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

	return (
    <>
      { !info.inMultiple ? <Box sx={{mt:2}}/> : null }
      <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} error={fieldProps.error}>
        {!info.inMultiple ? <FormLabel component="legend">{fieldProps.label}</FormLabel> : null}
        <RadioGroup
          row={row}
          name={fieldProps.name}
          value={value}
          onBlur={handleBlur}
          onFocus={fieldProps.onFocus}
        >
          <RadioOptions onChange={handleChange}/>
        </RadioGroup>
        <FormHelperText>{fieldProps.helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

const RadioOptions = ({onChange: handleChange}) => {
  const { translator } = useTranslator()
  const { info }       = useFieldInfo()
  const formInfo       = useFormInfo()
  const options        = formatChoices(translator, info.options, info.optionsKind, formInfo.processDefinition.key)

  return options.map((option, index) =>
    <FormControlLabel
      onClick={handleChange}
      key={index}
      value={option.value}
      label={option.label}
      control={<Radio />}
    />
  )
}

export default InputRadioField
