import {
  getProcessName,
  getTaskCreatedAt,
  getSubject
} from 'utils/form-utils';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useResponsiveColumns } from 'hooks/columns';
import LargeElevatedTable from 'components/tables/LargeElevatedTable';
import { GET_ASSIGNED_TASKS } from 'queries/task';
import { useTranslator } from 'hooks/translator';
import { getTaskTitle } from 'contexts/FormInfoContext';
import { openTaskForm } from 'hooks/process';
import { useNotifier } from 'hooks/notification';

const AssignedTasksTable = () => {
  const navigate       = useNavigate()
  const columns        = useColumns()
  const { translator } = useTranslator()
  const notifier       = useNotifier()
  const options        = {translator, navigate, notifier, register: true}

  const request = {
    variables: {},
    query:     GET_ASSIGNED_TASKS,
    path:      'data.assignedTasks',
    countPath: 'data.assignedTasksCount',
  }

  return <LargeElevatedTable 
    filterType="local"
    request={request}
    columns={columns}
    toTableRow={(row) => toTableRowData(options, row)}
  />
}

function toTableRowData(options, task) {
  const { translator } = options
  const t = translator.translate.bind(translator)

  return {
    id: task.id,
    onClick: (e) => openTaskForm(options, e, task), 
    cells: {
      'processName' :    task.processDefinition ? getProcessName(translator, task) : t('unknown'),
      //'taskDescription': getTaskDescription(translator, task),
      'taskTitle':       getTaskTitle(translator, {isStartForm: false, task}), 
      'taskSubject':     getSubject(task),
      'assignee':        task.assignee && task.assignee.username,
      'createdAt':       getTaskCreatedAt(task)
    }
  }
}

const useColumns = () => {
  const { t } = useTranslation()

  const processName = {
    headerName: t('process_name'),
    field: "processName",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const subject = {
    headerName: t('subject'),
    field: "taskSubject",
    cellProps: {
      width: 200,
      align: "left"
    }
  }  

  const title = {
    headerName: t('title'),
    field: "taskTitle",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const description = {
    headerName: t('description'),
    field: "taskDescription",
    cellProps: {
      width: 350,
      align: "left"
    }
  }

  const createdAt = {
    headerName: t('createdAt'),
    field: "createdAt",
    cellProps: {
      width: 100,
      align: "left",
      sx: {whiteSpace: "nowrap"}
    }
  }

  const columns = {
    xxs: [subject],
    md: [
      processName,
      subject 
    ],
    lg: [
      processName,
      subject,
      title,
      //description
    ],
    xl: [
      processName,
      subject,
      title,
     // description,
      createdAt,
    ]
  }

  return useResponsiveColumns(columns, true)
}

export default AssignedTasksTable;
