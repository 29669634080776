import { Content } from 'helpers/content';
import { useContentLoader } from 'hooks/content';
import { useEffect } from 'react';
import { SetState } from 'types/react';

export const ContentLoader = ({content, setContent}: {content: Content, setContent: (content: Content) => void}) => {
  const { loadContent } = useContentLoader({id: content.id, filename: content.path, type: content.contentType})

  useEffect(() => {
    loadContent().then(content => setContent(content))
  }, [])

  return null
}

export const ContentsLoader = ({setContents, contents}: {setContents: SetState<Content[]>, contents: Content[]}) => {
  return (
    contents.map(
      (content, index) => 
        <ContentLoader 
          key={index} 
          content={content} 
          setContent={content => setContents(loadedContents => [...loadedContents, content])}
        /> 
    )
  )
 
}