import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet';
import { GET_PROCESS_DEFINITION_BY_KEY } from 'queries/process';
import PageContainer from 'components/PageContainer';
import { useTranslation } from 'react-i18next';
import { useNotifier } from 'hooks/notification';
import CenteredLoader from 'components/CenteredLoader';
import useStartProcess from 'hooks/process';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NotFound from 'pages/NotFound';

const Process = () => {
  const { t }                  = useTranslation()
  const params                 = useParams()
  const processKey             = params.key
  const variables              = { variables: { key: processKey } }
  const {error, loading, data} = useQuery(GET_PROCESS_DEFINITION_BY_KEY, variables)
  const startProcess           = useStartProcess()
  const notifier               = useNotifier()
  const [loader, setLoader]    = useState(true)

  useEffect(() => {
    if (loading)
      return

    if (error) {
      notifier.error("Could not obtain process with key: " + processKey)
    } else {
      const pd = data.processDefinitionByKey
      if (!pd) {
        setLoader(false)
      } else {
        startProcess(null, pd)
      }
    }
  },[loading])
  
  return (
    <>
      <Helmet>
        <title>{t('menu.process')} |  </title>
      </Helmet>
      <PageContainer>
        { loader ? <CenteredLoader/> : <NotFound /> }
      </PageContainer>
    </>
  );
}

export default Process
