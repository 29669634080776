import { useTranslation }          from 'react-i18next';
import _                           from 'lodash';
import { useEffect, useState }     from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { openInNewTab }  from 'utils/utils';
import {
  Button, Dialog,
  DialogTitle, DialogContent,
  DialogActions,
  useTheme,
  Box,
  Link,
  Typography}                    from "@mui/material"
import { renderHtml }     from "utils/html"
import Types from 'types/types';

const ContentViewer = ({content: inContent, open, setOpen}) => {
  const content           = Types.toContent(inContent)
  const [error, setError] = useState(false)
  const { t }             = useTranslation()
	const theme             = useTheme()
  const [openl, setOpenl] = useState(false)

  useEffect(() => {
    if (!content.hasData || (!error && !open)){
      return;
    }
  
    const ctrlOpen = Types.isObject(open) && open.ctrlKey
    if (error || ctrlOpen){
      openInNewTab(content.url)
      setOpen(false)
    } else if (open && viewable(content)) {
      setOpenl(true)
    } else if (open) {
      openInNewTab(content.url)
      setOpen(false)
    } 
  }, [open, error])

  const onClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setOpenl(false)
    setOpen(false)
  }

  return (
	  <Dialog width="fit-content" maxWidth="unset" open={openl} onClose={onClose} >
			<DialogTitle textAlign="center" sx={{fontSize: theme.typography.h3.fontSize}}>{content?.name}</DialogTitle>
			<DialogContent  sx={{pt: 3, pb: 0}}>
        <ShowFile content={content} setError={setError} error={error}/>
      </DialogContent>
      <DialogActions sx={{padding: 0}}>
        <Button id='close-button' onClick={onClose}>{t('close')}</Button>
      </DialogActions>
		</Dialog>
	)
}

export function viewable(contentLike) {
  const type = Types.toContent(contentLike)?.type || ""

  switch (true){
    case type.startsWith("image/"):
    case type == "text/html":
    case type == "application/pdf":
      return true
    default:
      return false
  }
}

const ShowFile = ({content, error, setError}) => {
  const extension   = content.extension
  const style       = defaultStyles.hasOwnProperty(extension) ? defaultStyles[extension] : {}
  const defaultView = <FileIcon extension={extension} {...style} />

  if (error || !viewable(content))
    return defaultView

  switch (true) {
    case content.type.startsWith("image/") :
      return (
        <img
          src={content.url}
          onError={() => { console.error("Unable to load preview image for file: %o", content); setError(true) }}
        />
      )

    case content.type == "application/pdf":
      return (
        <Box sx={{ height: "calc(100vh - 150px)", maxHeight: "100%", overflow: undefined }}>
          <object onError={() => setError(true)} data={`${content.url}#view=FitH`} type="application/pdf" height={"98%"} width={"650px"} >
            <Typography>Could not load contents of file in the browser. Go <Link onClick={content.download}>to your file</Link></Typography>
          </object>
        </Box>
      )

    case content.type == "text/html":
        return renderHtml(content?.file.text())

    //case !error && (file.extension == "doc" || file.extension == "docx"):
    //  // microsoft 365 viewer:
    //  return (
    //    <iframe
    //      onError={() => setError(true)}
    //      src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.preview}`}
    //      width='100%'
    //      height='623px'
    //      frameborder='0'
    //    >
    //      This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by
    //      <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
    //    </iframe>
    //  )

    //  // google viewer:
    //  // return <iframe onError={() => setError(true)} src={`https://docs.google.com/gview?url=${file.preview}&embedded=true`}/>

    default:
      return defaultView
  }
}

export default ContentViewer
