import { getIn } from "formik";
import { isString, isEmptyValue, hasNonEmptyValue } from 'utils/utils';
import _ from 'lodash';
import { getEventValue } from "utils/validate-utils";
import { replaceIndexesWith } from "utils/field-utils";
import { F } from "helpers/formatter";
import Types from "types/types";

/* traverse 'object' recursively until you find 'key', and return its value */
export function findField(object, key) {
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      const value = findField(object[i], key);
      if (value !== undefined)
        return value
    }
  } else if (Types.isObject(object)) {
    for (var property in object) {
      if (object.hasOwnProperty(property)) {
        if (property === key && !(object['type'] && object['type'] == 'MULTIPLE')) {
          return object[property];
        } else {
          const value = findField(object[property], key)
          if (value !== undefined)
            return value
        }
      }
    }
  }

  return undefined
}

export function getPlaceholder(t, type) {
  if (type == undefined)
    return undefined

  const key = "placeholder." + type
  const res = t(key)
  if (res == key)
    return ""
  else
    return res
}

export function toInputErrorMessage(t, error) {
  if (error === undefined)
    return undefined

  if (!error?.type)
    throw "Input error has no type"

  const err = "error.input.1"
  const res = t(err, error)
  if (res == err)
    return error.message
  else
    return res
}

export function resolveChoices(choices) {
  if (Array.isArray(choices)) {
    // for now we only support a direct array of the valid options
    return choices;
  }
  throw new Error(`Unsupported kind of choices '${typeof choices}': ${JSON.stringify(choices)}`)
}

export function formatChoices(translator, choices, kind,  processKey) {
  const options = choices ? choices : []

  const createLabel = (option) => {
    // translate
    const label = translator.toValue(option.value, option.label, processKey)
    // change casing
    return F.toSentenceCase(label)
  }

  const reformat = kind == "STATIC" || kind == "undefined"
  if (reformat)
    return options.map(option => { return {...option, label : createLabel(option) } })
  else 
    return options
}

export function getFocusPathFromValues(formInfo, values) {
  return getFocusPathFromFieldValues(formInfo.form.fields, values)
}

export function getPath(value, path) {
  if (path == "")
    return value
  else 
    return _.get(value, path)
}

export function getFocusPathFromFieldValues(fields, values) {

  function getFocus(field, valuePath, options = {}) {
    // options: 
    // {empty: true, required: true}  : focus on first empty required field
    // {empty: true, required: false} : focus on first empty field
    // {empty: false}                 : focus on first field
    const createPath = (path) => path ? (valuePath ? `${valuePath}.${path}` : path) : valuePath

    // a multiple input field
    if (Types.isObject(field) && field.hasOwnProperty('fields')) {
      const fields = field.fields
      const value  = getPath(values, valuePath)
     
      // first traverse values for empty values
      if (Array.isArray(value)) {         
        for (let i = 0; i < value.length; i++) {
          const match = getFocus(fields, `${valuePath}[${i}]`, options)
          if (match)
            return match
        }
      } 

      // without values, pick the first fields that has no value
      else {
        for (let i = 0; i < fields.length; i++) {
          const match = getFocus(fields[i], createPath(`[0].${fields[i].name}`), options)
          if (match)
            return match
        }
      }
    }  

    // an input field
    else if (Types.isObject(field)) {
      const value = _.get(values, valuePath)
      if (options.empty){
        if (isEmptyValue(value) && (!options.required || !field.optional))
          return valuePath
      } else {
        return valuePath
      }
    }  

    // when dealing with a field array
    else if (Array.isArray(field)) {
      for (let i = 0; i < field.length; i++) {
         const match = getFocus(field[i], createPath(field[i].name), options)
         if (match)
          return match
      }
    }
  }

  return getFocus(fields, "", {empty: false})
}

// we add convenience functions to props to make it more easy to work with formik
export function toAugProps({formik, t, rpath, ...props}) {
  const runtimeErrorObj = getIn(formik.status, rpath)
  const yupError        = getIn(formik.errors, rpath)

  function setRuntimeError(error) {
    formik.setStatus(_.set(formik.status, rpath, error))
  }

  function toMsg(obj) {
    if (props.type === "MULTIPLE") {
      return hasNonEmptyValue(runtimeErrorObj) ||  hasNonEmptyValue(yupError) ? t('yup.invalid.multiple') : undefined
    } else {
      if (Types.isObject(obj) && obj.type)
        return toInputErrorMessage(t, runtimeErrorObj)
      else if (Types.isObject(obj) && obj.hasOwnProperty("name") && obj.hasOwnProperty("message"))
        return obj.message
      else if (obj instanceof Function)
        return toMsg(obj())
      else if (isString(obj))
        return Boolean(obj) && obj.trim() !== "" ? obj : undefined
      else
        return undefined
    }
  }

  return {
    inputProps: props.inputProps,

    setValue:   function(value) { formik.setFieldValue(rpath, value)   },
    setTouched: function(value) { formik.setFieldTouched(rpath, value) },
    setError:   function(value) { formik.setFieldError(rpath, value)   },
    isError:    function()      { return this.touched && hasNonEmptyValue(this.rawError) },
    setRuntimeError: function(value) { setRuntimeError(value) },

    // the getters below become static after passing them as a function argument.
    get value()    { return getIn(formik.values, rpath) },
    get yupError() { return toMsg(yupError) },
    get runtimeError() { return toMsg(runtimeErrorObj) },
    get rawError()     { return this && (this.runtimeError || this.yupError) },
    get error()        { return this.rawError },
    get touched()      { return getIn(formik.touched, rpath) },

    handleBlur: function(e) {
      formik.handleBlur(e)
    },
    handleFocus: function(e) {
      formik.setFieldTouched(rpath, true)
    },
    handleChange:    function(e) {
      setRuntimeError(undefined)
      try {
        const value = getEventValue(e, e)
        formik.setFieldValue(rpath, value)
      } catch (error) {
        console.error("formik handle error: %o", error)
      }
    },
  }
}

