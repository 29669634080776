
import { forwardRef }            from 'react'
import { Table, TableContainer } from '@mui/material'
import TableHeader               from 'components/table/TableHeader'
import TableRows                 from 'components/table/TableRows'
import RenderContextProvider from 'contexts/RenderContext'

const LoadingTable = forwardRef(({columns, rows, loading, containerSx, tableSx}, ref) => (
  <RenderContextProvider parent="table">
    <TableContainer ref={ref} className='large-table-container'
      sx={{
        height: "fit-content",
        maxHeight: "100%",
        maxWidth: '100%',

        ...containerSx,
      }}
    >
      <Table
        id="loading-table"
        size="small" stickyHeader
        sx={{
          width: '800px',
          overflow:"auto",
          minWidth: "0px",
          maxHeight: "100%",
          maxWidth: "100%",
          ...tableSx
        }}
      >
        <TableHeader columns={columns}/>
        <TableRows columns={columns} rows={rows} loading={loading}/>
      </Table>
    </TableContainer>
  </RenderContextProvider>
))

export default LoadingTable
