import { useCurrentUser } from 'authentication/main';
import LambdaComponent from 'components/LambdaComponent';
import merge from 'deepmerge';
import Translator from 'helpers/translator';
import { useLocalState } from 'hooks/state';
import { GET_CONFIG } from 'queries/config';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingUser, User } from 'types/graphql';
import { SetState } from 'types/react';
import { toUserMenu } from 'utils/config';
import i18n from 'utils/i18n';
import { getDecimalLocale } from 'utils/render';
import { createCustomThemes, selectTheme, selectThemeName } from 'utils/theme';

import { useQuery } from '@apollo/client';

export type TaskRendering = "window" | "standard"

export type DecimalSeparator = "dot" | "comma"
export type DecimalSeparatorSetting = DecimalSeparator | "locale"

export type GearsConfig = {
  loading: boolean
  settings: any
  menu: any
  themes: any[]
  theme: any
  themeName: string
  setThemeName: SetState<string>
  project: string | undefined
  user: LoadingUser
  translator: Translator
  taskRendering: TaskRendering
  setTaskRendering: SetState<TaskRendering>
  decimalSetting: DecimalSeparatorSetting,
  setDecimalSetting: SetState<DecimalSeparatorSetting>
  decimalSeparator: DecimalSeparator
  addProcessIcons: boolean
}

export const ConfigContext = createContext<GearsConfig>({} as GearsConfig);

type Locale = {
  locale: string
  language: string
  muiKey: string
  muiLocale: object
  dateFnsKey: string
  dateFnsLocale: any
}

const ConfigProvider = ({children, locale}: {children: React.ReactNode, locale: Locale}) => {
  const { t }                  = useTranslation()
  const translator             = new Translator(t, i18n)
  const user                   = useCurrentUser()
  const {loading, data, error} = useQuery(GET_CONFIG)
  const projectKey             = data?.config?.projectName || "GEARS"
  const projectPrefix          = projectKey == "GEARS" ? "" : `${projectKey}.`
  const project                = translator.toProjectName(projectKey)
  const themePath              = projectPrefix + "THEME"
  const defaultTheme           = data?.config?.theme?.default || 'default'
  const defaultSettings        = { addDefaultTheme: false }
  const settings               = merge.all([defaultSettings, data?.config?.theme?.settings || {}])
  const gearsTheme             = data?.config?.theme
  const addProcessIcons        = typeof gearsTheme?.settings?.addProcessIcons == 'boolean' ? gearsTheme?.settings?.addProcessIcons : Boolean(gearsTheme?.processes)

  const [themeName, setThemeName]           = useLocalState(themePath, defaultTheme)
  const [taskRendering, setTaskRendering]   = useLocalState<TaskRendering>("RENDERING", "standard")
  const [decimalSetting, setDecimalSetting] = useLocalState<DecimalSeparatorSetting>("DECIMAL_SEPARATOR", "locale")
  const decimalSeparator                    = determineDecimalSeparator(decimalSetting, locale.locale)

  if (error)
    console.log("Error while fetching config data: %o", error)

  const menu   = toUserMenu(data?.config?.menu,user)
  const themes = createCustomThemes(locale, gearsTheme, addProcessIcons)
  const name   = selectThemeName(themes, themeName, gearsTheme, loading)
  const theme  = selectTheme(themes, name)
  const config: GearsConfig = {
    addProcessIcons,
    loading, settings, menu, themes, project, user, translator, theme,
    themeName: name,
    setThemeName,
    taskRendering,
    setTaskRendering,
    decimalSetting,
    setDecimalSetting,
    decimalSeparator
  }

  return (
    <ConfigContext.Provider value={config}>
			<LambdaComponent props={config}>
				{children}
			</LambdaComponent>
    </ConfigContext.Provider>
  );
}

function determineDecimalSeparator(decimalSetting: DecimalSeparatorSetting, locale: string): DecimalSeparator {
  switch (decimalSetting) {
    case "locale": 
      const decimalLocale = getDecimalLocale({decimalSetting, locale})
      switch (decimalLocale?.replace("_", "-")) {
        case "en-EN": 
        case "en-US": return "dot"
        case "nl-NL": 
        default: return "comma"
      }
    default: return decimalSetting
  }
}

export default ConfigProvider;
