import { useConfig }                     from "hooks/config";
import Translator, { TranslateFunction } from "helpers/translator";

export const useTranslator = () => {
  const { translator }: { translator: Translator} = useConfig()
  const t : TranslateFunction = translator.translate.bind(translator)

  return { 
    translator, 
    t,
  }
}
