import { useApolloClient } from '@apollo/client'
import { useNotifier } from 'hooks/notification'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Report from 'utils/report'
import { useDebouncedState } from 'utils/utils'
import merge from 'deepmerge'
import { Box, Typography } from '@mui/material'
import PageStats from 'components/table/page/PageStats'
import MenuBar from 'components/table/page/MenuBar'
import LoadingTable from 'components/table/LoadingTable'
import { useCustomRef } from 'hooks/customref'
import { toRegex } from 'utils/option-utils'
import { useTranslator } from 'hooks/translator'

const LargeTable = ({
  // rendering table data
  columns = [],
  filterColumns = [],
  request = ({
    variables: {},
    query:     undefined,
    path:      'data',
    countPath: 'data.count',
  }),
  toTableRow = (row) => ({cells: row}),

  // styling
  containerSx = {},
  tableSx     = {},

  // configurations
  pageSize: givenPageSize = 100,
  filterType = "remote",
  disableFilter,
  disablePaging,
  disableReload,
  disableToolbar
}) => {
  const pageSize                              = disablePaging && 999999 || givenPageSize
  const client                                = useApolloClient()
  const [page, setPage]                       = useState({pageNum: 0, pageSize, filter: ""})
  const [data, setData]                       = useState({count: 0, page: {...page, pageNum: -1}, rows: []})
  const notifier                              = useNotifier()
  const [pageable, setPageable]               = useState(false)
  const [filter, setFilter, setCurrentFilter] = useDebouncedState('', 500)
  const tableRef                              = useRef(null)
  const requestRef                            = useRef(null)
  const setRequest                            = (request) => requestRef.current = request
  const cancelRequest                         = ()        => requestRef.current?.cancel()
  const [loading, setLoading]                 = useState({
    reload: false,
    main: true,
    first: false,
    previous: false,
    next: false,
    last: false,
    filter: false
  })

  const activeFilterColumns = filterColumns.length == 0 ? columns.filter(column => column.headerName).map(column => column.field) : filterColumns
  const toolbarFunctions    = createToolbarFunctions(data, loading, setLoading, setPage, cancelRequest)
  const showToolbar         = !disableToolbar &&  ((!disablePaging && pageable) || !disableFilter)

  // add functions to be visible from outside of this component
  const customRef = useCustomRef()
  customRef?.setCurrent({
    deleteLine: (id) => setData(data => ({...data, rows: data.rows.filter(row => row.id != id)}))
  })

  useEffect(() => {
    if (filterType != 'remote' && filterType != 'local')
      notifier.error("Configuration error: The filter type of the paging table can only be 'remote' or 'local', not " + JSON.stringify(filterType) + ".")
  }, [])

  // perform a request when the request info changes
  useEffect(() => {
    return loadPage({request, setRequest, cancelRequest, client, filterColumns: activeFilterColumns, notifier, page, setLoading, loading, toTableRow, setData, data, filterType})
  },[page])

  // active pagination buttons
  useEffect( () => {
    const hasCount = Boolean(data.count >= 0)
    const enablePaging = data.page.pageNum > 0 ||
      (hasCount && data.count > data.page.pageSize) ||
      (!hasCount && data.pageCount == data.page.pageSize)

    if (!pageable && enablePaging && !disablePaging)
      setPageable(enablePaging)

    // scroll to top
    tableRef.current.scrollTo({top: 0, left: 0})
  }, [data])

  // instigate a request info change when the filter changes
  useEffect( () => {
    setLoading(prev => ({...toFalse(prev), filter: true}))
    setPage({...data.page, pageNum: 0, filter})
  }, [filter])

  return (
    <Box id="large-table" sx={{ display: "flex", maxHeight: '100%', maxWidth: '100%', flexFlow: 'column' }}>
      <MenuBar
        showToolbar={showToolbar}

        disableFilter={disableFilter}
        filter={filter}
        setFilter={setFilter}
        setCurrentFilter={setCurrentFilter}

        pageable={pageable}
        toolbarFunctions={toolbarFunctions}

        disableReload={disableReload}

        loading={loading}
      />

      <Box id="loading-table-container" sx={{width: "100%", flexShrink: 1, maxWidth: "100%", display: "flex", flexGrow: 1, overflow: 'hidden', display: "flex"}}>
          <LoadingTable ref={tableRef} columns={columns} rows={data.rows} loading={loading.main} containerSx={containerSx} tableSx={tableSx} />
      </Box>
      <PageStats pageable={pageable} data={data} />
      <EmptyTableMessage disable={Object.values(loading).some(x => x) || data.count > 0 || data.rows?.length > 0}/>
    </Box>
  )
}

const EmptyTableMessage = ({message, disable}) => {
  const {t} = useTranslator()
  
  if (!disable) {
    const msg = message ? message : t('table.empty')
    return (
      <Box sx={{ display: "flex", marginX: "auto", marginY: "20px" }}>
        <Typography variant='h5'>
          {msg}
        </Typography>
      </Box>
    )
  } else return null
}

function filterRows(rows, filterColumns, filter) {
  if (!filter)
    return rows

  const regex     = toRegex(filter)
  const check     = (value) => Boolean(regex.test(value))
  const filterRow = (row)   => filterColumns
    .some(column   => check(_.get(row.cells, column)))

  return rows.filter(filterRow)
}

function loadPage(props) {
  
  const {request, setRequest, cancelRequest, client, notifier, filterColumns, page, setLoading, loading, toTableRow, setData, data, filterType} = props

  const toPageRequest = pageRequestCreator(request)
  const toTableRows   = (dataRow) => dataRow.map(row => toTableRow(row))
  const toTableData   = (result) => _.get(result, request.path)
  const toTableCount  = (result) => _.get(result, request.countPath)

  cancelRequest()

  // create cancellation boilerplate
  var cancelled = false
  const cancel = () => {
    if (!cancelled) {
      console.log("Cancel table data with: %o", request.variables)
      cancelled = true
    }
  }
  setRequest({cancel})

  const pageChanged    = data.page.pageNum != page.pageNum || data.page.pageSize != page.pageSize
  const performRequest = filterType == "remote" || (filterType == "local" && pageChanged)

  if (performRequest || loading.reload) {
    const newRequest = toPageRequest(page.pageNum, page.pageSize, filterType == "local" ? null : page.filter)
    console.log("Get table data with: %o", newRequest.variables);

    (async () => {
      // issue the page request
      client.query(newRequest)
        .then(
          result => {
            if (cancelled) return;

            console.log("handle table result: data=%o", result)
            const unfiltered = toTableRows(toTableData(result))
            const count      = toTableCount(result)
            const rows       = filterType == "local" ? filterRows(unfiltered, filterColumns, page.filter) : unfiltered

            if (cancelled) return;

            setData({count, pageCount: unfiltered.length, rows, page, unfiltered})
          },
          error => {
            if (cancelled) return;

            console.error("failed to get table data: %o", error.message)
            const report = Report.from(error, { category: Report.backend })
            report.addToNotifier(notifier)
          }
        )
        .catch(reason => {
          if (cancelled) return;

          const message = "A Frontend Issue occured while processing the table data: " + reason
          notifier.error(message)
          console.error(message)
        })
        .finally(() => {
          if (cancelled) return;

          setToFalse(setLoading)
          cancelled = true
        })
      }
    )()
  } else {
    setData({...data, page: {...page}, rows: filterRows(data.unfiltered, filterColumns, page.filter)})
    setToFalse(setLoading)
    cancelled = true
  }

  return () => { cancel() }
}

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
const mergeOptions   = { arrayMerge: overwriteMerge }

const isTrue     = (loading) => Object.values(loading).some((v) => v === true)
const setToFalse = (setLoading) => setLoading(toFalse)
const toFalse    = (obj) => Object.keys(obj)
  .reduce((accumulator, key) => {
    return {...accumulator, [key]: false};
  }, {});

const createToolbarFunctions = (data, loading, setLoading, setPage, cancelRequest) => {
  const enabled  = data.page.pageNum >= 0
  const hasCount = data?.count >= 0
  const lastPage = Math.floor(Number(data.count) / Number(data.page.pageSize)) + (Number(data.count) % Number(data.page.pageSize) == 0 ? -1 : 0)

  return (
  {
    reload: data.page.pageNum >= 0
      ? () => {
        console.log("Reloaded page: %o", data.page)
        setLoading(prev => ({...toFalse(prev), reload: true}))
        setPage({...data.page})
      }
      : undefined,
    filter: () => {
      if (isTrue(loading))
        setToFalse(setLoading)
      cancelRequest()
    },

    first: enabled && data.page.pageNum > 0
      ? () => {
          setLoading(prev => ({...toFalse(prev), first: true}))
          setPage({...data.page, pageNum: 0})
        }
      : undefined,

    last: enabled && hasCount && lastPage > data.page.pageNum
      ? () => {
          setLoading(prev => ({...toFalse(prev), last: true}))
          setPage({...data.page, pageNum: lastPage})
        }
      : undefined,

    previous: enabled && data.page.pageNum > 0
      ? () => {
          setLoading(prev => ({...toFalse(prev), previous: true}))
          setPage({...data.page, pageNum: data.page.pageNum - 1})
        }
      : undefined,

    next: enabled && (
        (hasCount  && data.count > (data.rows.length + data.page.pageNum * data.page.pageSize) ||
        (!hasCount && data.rows.length % data.page.pageSize == 0))
      )
      ? () => {
          setLoading(prev => ({...toFalse(prev), next: true}))
          setPage({...data.page, pageNum: data.page.pageNum + 1})
        }
      : undefined,
  }
)}

const pageRequestCreator = (request) => (pageNum, pageSize, filter) => merge.all([
    request,
    {
      variables: {
        filter: filter?.length == 0 ? null : filter,
        start: pageNum * pageSize,
        count: pageSize
      }
    }
  ],
  mergeOptions)

export default LargeTable;
