import React from "react"
import { t } from "./i18n";
import Notification, {NotificationType}  from '../components/Notification'
import {  assertType, assertTypes } from "./utils";
import merge                                    from 'deepmerge'
import { ApolloError } from "@apollo/client";
import Types from "types/types";

// TODO rename Report to Problem
const ReportCategory = Object.freeze({"FRONTEND":1, "BACKEND":2, "INPUT":3, "USER": 4})
const ReportLevel    = Object.freeze({"ERROR"   :1, "WARNING":2, "INFO" :3})
const ReportStatus   = Object.freeze({
  frontend : {
    Whoops        : 1,
    NotSupported  : 2,
    Broken        : 3,
    Loading       : 4,
    NotAuthorized : 5,
    Apollo        : 6
  },
  backend : {
    Error   : 1,
    Offline : 2
  },
  input : {
    Error         : 1,
    loginError    : 2
  },
})


/** A report is used to store an issue. A status code is stored, issue level, and more.
 * A Report can be used to create a Notification, i.e., a visualisation of the issue that
 * is presented to the user.
 *
 */
class Report {

  /**
  * @param {ReportLevel}    level    - the report level (ERROR, WARNING or INFO)
  * @param {ReportCategory} category - the report category (FRONTEND, BACKEND, USER)
  * @param {number}         status   - the status code (a number, e.g. 2 means operation not supported)
  * @param {object}         options  - additional information used for translation (an object containing e.g. a component name)
  * @param {string}         verbose  - additional html or string content (additional text that is displayed)
  */
  constructor(level, category , status, options = {}, verbose) {
    // ---------- preconditions ----------
    if (!Object.values(ReportCategory).includes(category))
      throw new Error(`Unknown category was provided: ${category}`)

    if (!Object.values(ReportLevel).includes(level))
      throw new Error(`Unknow level was provided: ${level}`)

    if (!assertType(options, "object!", "Data provided is not an object: " + options))

    // -----------------------------------

    this.options      = options
    this.level        = level
    this.status       = status
    this.verbose      = verbose
    this.category     = category
    this.categoryName = Object
      .entries(ReportCategory)
      .find((v => v[1] == this.category))[0]
  }

  static error(category, status, options = {}, verbose)   { return new Report(ReportLevel.ERROR,   category, status, options, verbose) }
  static warning(category, status, options = {}, verbose) { return new Report(ReportLevel.WARNING, category, status, options, verbose) }
  static info(category, status, options = {}, verbose)    { return new Report(ReportLevel.INFO,    category, status, options, verbose) }

  static get user()     { return ReportCategory.USER }
  static get frontend() { return ReportCategory.FRONTEND }
  static get backend()  { return ReportCategory.BACKEND }
  static get input()    { return ReportCategory.INPUT }

  static get code() { return ReportStatus }

  static isReport(obj) {
    return obj !== null && obj instanceof Report
  }

  static isJsError(error) {
    return !error && (
      error.name === "RangeError"     || // a number is outside an allowable range of values
      error.name === "ReferenceError" || // variable is undefined
      error.name === "SyntaxError"    || // syntax error occurs during parsing/compile time
      error.name === "TypeError"      || //  occurs when an operation is performed on a wrong data type
      error.name === "URIError"       || // indicates that one of the global URI handling functions was used in a way that is incompatible with its definition.
      error.name === "EvalError"      || // identify errors when using the global eval() function.
      error.name === "InternalError"  || // This error occurs internally in the JS engine, especially when it has too much data to handle and the stack grows way over its critical limit."
      error.name === "AggregateError"    // an instance representing several errors wrapped in a single error when multiple errors need to be reported by an operation
    )
  }

  static toSpecErrors(error) {
    if (error instanceof ApolloError) {
      const graphQLErrors = error.graphQLErrors || []
      const errors        = graphQLErrors.filter(error => error?.extensions?.classification == "FROM_SPEC")

      return errors
    }
    else return []
  }

  /** create a suitable report given an error. */
  static from(error, args = {}, options = {}) {
    assertTypes([args], ["object!"])
    function toReport(error, args) {
      // create report

      const r = Report.defaults.Whoops.with(args)

      // prioritize spec errors
      if (error instanceof ApolloError) {
        const specErrors = Report.toSpecErrors(error)
        if (specErrors.length > 0) {
          const msg = specErrors[0].message
          return Report.error(Report.user, null, {}, msg);
        } 
      }
      
      if (error instanceof Error) {
        // some reports need to be converted
        if (args.category == Report.backend) {
          if (error.message.match(/.*Invalid.*username.*password.*/i))
            return Report.error(Report.input, Report.code.input.loginError)
          else if (error.message.match(/failed to fetch.*/i))
            return Report.error(Report.backend, Report.code.backend.Offline)
          else if (error.message.match(/Internal Server Error.*/i))
            return Report.error(Report.backend, Report.code.backend.Error)
          else if (error.message.match(/exception while fetching.*/i))
            return Report.error(Report.frontend, Report.code.frontend.Apollo, {}, error.message.replace(/exception while fetching(.*?):[\ ]*/i, ""))
          else
            return Report.error(Report.backend, Report.code.backend.Error, {}, error.message);
        }

        return new Report(r.level, r.category, r.status, r.options, error.message)
      } else if (_.isString(error)) {
        return new Report(r.level, r.category, r.status, r.options, error)
      } else
        throw new Error("Cannot create report from provided data.")
    }

    // set defaults
    const defaultArgs = { category: Report.frontend }
    const nargs       = merge.all([defaultArgs, args, {options}])
    const report      = toReport(error, nargs)

    console.debug("From issue %o created %o", error.message, report.verboseMessage)
    return report
  }

  static equals(a,b) {
    if (Report.isReport(a) && Report.isReport(b))
      return a.status === b.status && a.category === b.category && a.level === b.level
    else
      return false
  }

  static helpers(getter, setter) {
    if (!Array.isArray(getter()))
      throw new Error("Error helper requires an array")

    function toUniqueReports(current, error) {
      const errors        = Array.isArray(error) ? error : [ error ]
      const candidates    = errors
        .filter(error => Report.isReport(error) && !current.some(curr => Report.equals(curr, error)))

      if (candidates.length !== errors.length)
        console.warn("Not all errors are added to notifications: only %o out of %o", candidates, errors)

      return candidates
    }

    return {
      reports: {
        set    : function(report) { setter(toUniqueReports([], report)) },
        get    : function() { return getter() },
        clear  : function() { setter([]) },
        add    : function(report) { setter(prev => [...prev, ...toUniqueReports(prev, report)]) },
        remove : function(filter) { setter(prev => prev.filter(e => !filter(e))) }
      }
    }
  }

  addToNotifier(notifier, overrideOptions = {}) {
    const options = {
      type: this.notificationType,
      message: this.message,
      details: this.verbose,
      ...Types.asObject(overrideOptions)
    }

    switch (options.type) {
      case NotificationType.INFO:
        notifier.info(options.message)
        break
      case NotificationType.WARNING:
        notifier.warning(options.message)
        break
      case NotificationType.ERROR:
        notifier.error(options.message)
        break
      case NotificationType.CLOUD:
      default:
        notifier.message(options.message)
        break
    }
  }

  with(options = {}) {
    const ops = Types.isObject(options?.options) ? merge.all([this.options, options.options]) : this.options
    return new Report(
      options?.level || this.level,
      options?.category || this.category,
      options?.status || this.status,
      ops,
      options?.verbose == undefined ? this.verbose : options.verbose
    )
  }

  toNotification(options = {}, sx = {}) {
    const ops = Types.asObject(options)

    const notificationDefaults = {
      type: this.notificationType,
      message: this.message,
      details: this.verbose
    }

    return <Notification {...{...notificationDefaults, ...ops}} sx={sx} />
  }

  get notificationType() { return this.category === ReportCategory.BACKEND ? NotificationType.CLOUD : this.level }
  get code()             { return "error." + this.categoryName + "." + this.status }
  get verboseMessage()   { return this.message?.replace(/\.+$/, "") + (this.verbose ? ": " + this.verbose : "") }
  get message()          {

    if (this.category == Report.user) {
      return this.verbose
    } else {

      // first convert component name to allow translation
      const ops = this.options.component ? {...this.options, component: t('component.' + this.options.component) } : this.options

      // then translate the error
      const translation = t(this.code, ops)
      if (this.status === undefined)
        return "An unspecified error occured." // this option is for when translations have not been loaded.
      else if (translation == this.code)
        return "You are not authorized to view this page." // this option is for when translations have not been loaded.
      else
        return translation
    }
  }
  get details()          { return this.verbose } // TODO: this is not yet used

  static defaults = {
    Whoops:         Report.error(ReportCategory.FRONTEND, Report.code.frontend.Whoops),
    NotImplemented: Report.warning(ReportCategory.FRONTEND, Report.code.frontend.NotSupported),
    NotAuthorized:  Report.error(ReportCategory.FRONTEND, Report.code.frontend.NotAuthorized),
  }
}

export default Report
