import ProcessTableView from 'components/process/ProcessTableView';
import { useProcessRenderContext } from 'hooks/render';

import { Box } from '@mui/material';

import ProcessGrid from './ProcessGrid';
import ProcessTileView from './ProcessTileView';

const ProcessRenderer = () => {
  const { processRendering } = useProcessRenderContext()
  
  switch (processRendering) {
    case 'tiles':
      return <ProcessTileView /> 

    case 'table':
      return <ProcessTableView />

    case 'standard': 
    default:
      return (
        <Box sx={{ pt: 3, flexGrow: 1, maxHeight: "100%", overflow: "auto", padding: "5px", paddingBottom: "10px" }}>
          <ProcessGrid />
        </Box>
      )
  }
}

export default ProcessRenderer