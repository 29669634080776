import { TableBody }        from '@mui/material';
import { MultipleTableRow } from 'components/fields/multiple';

const MultipleTableBody = (props) => {
  return (
    <TableBody>
      { props.rows.map((row, index) => <MultipleTableRow {...props} key={index} rowIndex={index} row={{id: index, values: row}}/>) } 
    </TableBody>
  )
}

export default MultipleTableBody