import { Helmet }               from 'react-helmet';
import PageContainer            from 'components/PageContainer';
import { useTranslation }       from 'react-i18next';
import AssignedTasksTable       from 'components/tables/AssignedTasksTable';
import { useConfig }            from 'hooks/config';

const AssignedTasks = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.tasks.assigned')} | {project}</title>
      </Helmet>
      <PageContainer>
        <AssignedTasksTable/> 
      </PageContainer>
    </>
  );
}

export default AssignedTasks;
