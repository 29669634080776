import { useQuery }                        from '@apollo/client'
import { Helmet }                          from 'react-helmet';
import { Box }                             from '@mui/material';
import Filter                              from 'components/Filter';
import PageContainer                       from 'components/PageContainer';
import { useTranslation }                  from 'react-i18next';
import { useNotifier }                     from 'hooks/notification';
import DeploymentTable                     from 'components/tables/DeploymentsTable';
import { handleResult, useDebouncedState } from 'utils/utils';
import { GET_DEPLOYMENTS }                 from 'queries/deployments';
import { useConfig }                       from 'hooks/config';

const LoadDeploymentsTable = ({results, filter}) => {
  const notifier = useNotifier()
  return handleResult(results, notifier, data => <DeploymentTable filter={filter} deployments={data.deployments}/>)
}

const Deployments = () => {
  const { t }       = useTranslation()
  const { project } = useConfig()

  return (
    <>
      <Helmet>
        <title>{t('menu.deployments')} | {project}</title>
      </Helmet>
      <PageContainer>
        <DeploymentTable />
      </PageContainer>
    </>
  );
}

export default Deployments
