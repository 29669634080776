import LambdaComponent from 'components/LambdaComponent';
import { useFormikContext } from 'formik';
import { useConfig } from 'hooks/config';
import { useFormInfo } from 'hooks/form';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldInfo } from 'types/field';
import { Field } from 'types/graphql';
import { getFieldAux, toFieldProps, toFormFieldInfo } from 'utils/field-utils';
import { toAugProps } from 'utils/input-utils';
import { renderPath } from 'utils/utils';

/*
example output of useFieldInfo

{
  "info": {
      "rpath": "attrTx1",
      "isDepending": false,
      "type": "TEXT",
      "inMultiple": false,
      "required": true,
      "path": "attrTx1",
      "xpath": "attrTx1",
      "field": {
          "path": "attrTx1",
          "name": "attrTx1",
          "baseName": "attr_tx1",
          "parentName": "EA1",
          "type": "TEXT",
          "label": "Attr tx1",
          "optional": false,
          "labeled": false,
          "dependants": [],
          "traits": []
      }
  },
  "augProps": {
      "inputProps": {
          "variant": "outlined",
          "size": "medium",
          "margin": "normal",
          "label": "Attr tx1"
      },
      "value": null
  },
  "fieldProps": {
      "id": "attrTx1",
      "name": "attrTx1",
      "value": "",
      "required": true,
      "error": false,
      "helperText": " ",
      "placeholder": "Voer tekst in...",
      "variant": "outlined",
      "size": "medium",
      "margin": "normal",
      "label": "Attr tx1"
  },
  "indices": {}
} 
*/

interface FieldContext {
  info: FieldInfo
  augProps: any
  fieldProps: any
}

export type Indices = { [key: string]: number}

interface FieldInfoProviderProps {
  indices: Indices
  field: Field
  inputProps?: any
  children: React.ReactNode
}


export const FieldInfoContext = createContext<FieldContext>({} as FieldContext);

const FieldInfoProvider = ({children, field, indices, inputProps}: FieldInfoProviderProps) => {
  const {taskRendering} = useConfig()
  const { t }           = useTranslation()
  const formik          = useFormikContext()
  const formInfo        = useFormInfo()
  const info            = toFormFieldInfo(formInfo, field, indices, taskRendering)
  const augProps        = toAugProps({type: field.type, rpath: info.rpath, formik, t, inputProps})
  const fieldProps      = toFieldProps({info, augProps, t})

  // add options
  switch (info.type) {
    case info.field.type == "BOOLEAN" && "SINGLE SELECT": 
    case info.field.type == "BOOLEAN" && "SINGLE SELECT SUBMIT": 
    case "RADIO BOOLEAN SELECT":
      info.numOptions = 2
      info.options = [
        {
          label: t('values.true'),
          value: "true"
        },
        {
          label: t('values.false'),
          value: "false"
        }
      ]
      break

    default:
      break
  }

  const fieldInfo = {info, augProps, fieldProps}
  return (
    <FieldInfoContext.Provider value={fieldInfo}>
      {children}
    </FieldInfoContext.Provider>
  )
}

export default FieldInfoProvider;
