import { fallback }     from 'utils/utils'
import {
  Table,
  TableContainer }      from '@mui/material';
import { useState }     from 'react';
import {
  MultipleTableHeader,
  MultipleTableBody,
}                       from 'components/fields/multiple';
import { useFieldInfo } from 'hooks/field';

const MultipleTable = () => {
  const { fieldProps } = useFieldInfo()

  const rows = fallback(fieldProps.value, [{}])
  const [ selected, setSelected ] = useState([])

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 120px)', minWidth: '100%' , overflow: 'auto'}}>
      <Table size="small" id={fieldProps.id} stickyHeader >
        <MultipleTableHeader rows={rows} selected={selected} setSelected={setSelected}/>
        <MultipleTableBody   rows={rows} selected={selected} setSelected={setSelected}/>
      </Table>
    </TableContainer>
  )
}

export default MultipleTable
