import Loader from 'components/Loader';
import {
  Box,
  TableCell,
  TableRow as MuiTableRow
} from '@mui/material';

const LoadingTableRow = ({columns}) => (
  <MuiTableRow>
    <TableCell colSpan={columns.length} style={{textAlign: "center"}}><Box sx={{display: 'flex', alignItems: 'center', justifyContent:'center'}}><Loader size='micro'/></Box></TableCell>
  </MuiTableRow>
)

export default LoadingTableRow
